import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./PubMedSearchIndex.css";


class PubMedRightDocumentData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
        }
    }
    componentWillReceiveProps(nextProps) {

    }


    render() {
        if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
            return (<></>)
        }
        else if (this.props.customWidth > 1000) {
            return (<div className="ms-0 lg:ms-4 grow">
                <div className="box-white mb-4">
                    Enter a <strong>search query</strong> or <strong>add filters</strong> to get started
                </div>
            </div>)
        }
    }

}
export default withRouter(PubMedRightDocumentData);