import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./PubMedSearchIndex.css";


class PubMedRightGenAIAnswer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
        }
    }
    componentWillReceiveProps(nextProps) {

    }


    render() {
        if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
            return (<></>)
        }
        else if (this.props.customWidth > 1000) {
            return (<div className="pubmed-main-right-answer box mb-4">
                <h2 className="semibold text-base m-0">Answer (based on top 5 papers)</h2>
                <div data-testid="synthesis-text">
                    <p>The research involves data from various sources including demographic information of participants
                        <span>[1]</span>,
                        internet usage and analysis for decision making
                        <span>[2]</span>,
                        a Digital Data Divide Database estimating technological capacity
                        <span>[3]</span>,
                        PGA Tour Results for 2018-2021 with related data
                        <span>[4]</span>,
                        and data on egg survival, hatchling survival, head capsule width, pupation/eclosion rate, and adult heat survival
                        <span>[5]</span>.
                    </p>
                    {/* <div class="absolute top-[5px] right-[15px]">
                        <button class="group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap font-normal overflow-hidden tap-highlight-transparent data-[pressed=true]:scale-[0.97] outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 text-tiny gap-2 rounded-small px-0 !gap-0 transition-transform-colors-opacity motion-reduce:transition-none text-secondary min-w-8 w-8 h-8 z-10 aria-expanded:scale-[0.97] aria-expanded:opacity-70 subpixel-antialiased opacity-0 group-hover:opacity-70 transition-opacity duration-300 data-[hover=true]:opacity-100 data-[focus=true]:opacity-100 bg-secondary-300 data-[hover=true]:bg-secondary-400" type="button" aria-label="open LLM action dropdown" data-slot="trigger" id="react-aria5731504829-«r1as»" aria-haspopup="true" aria-expanded="false">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis-vertical" className="svg-inline--fa fa-ellipsis-vertical fa-lg " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                <path fill="currentColor" d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"></path>
                            </svg>
                        </button>
                    </div> */}
                </div>
            </div>)
        }
    }

}
export default withRouter(PubMedRightGenAIAnswer);