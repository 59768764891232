import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./PubMedSearchIndex.css";
import {
    IMSLogo,
    pubMedIcon,
    pubMedNIH
} from '../../assets/index';
import { Link } from 'react-router-dom';
class PubMedSearchAskQuestionIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchKeyword: '',
            customWidth: props.customWidth,
            customHeight: props.customHeight,
        }
    }

    handleChangeInSearch = (event) => {
        this.setState({
            searchKeyword: event.target.value,
        })
    }

    handleKeyDown = async (event) => {
        if (['Enter'].includes(event.key) && !event.shiftKey && this.state.searchKeyword && this.state.searchKeyword.trim()) {
            event.preventDefault();
            event.returnValue = false;
            await this.handleOnClickSearchIcon();
        }
    }
    buildUrl = (searchKeyword) => {
        searchKeyword = encodeURIComponent(searchKeyword);
        let url = `/PubMedSearch?query=${searchKeyword}&filters=&q=`;
        return url;
    };
    handleOnClickSearchIcon = () => {
        const newUrl = this.buildUrl(this.state.searchKeyword);
        this.props.handleChangeInSearch(this.state.searchKeyword, newUrl);
        this.props.history.push(newUrl);
    }
    render() {
        if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
            return (<></>)
        }
        else if (this.props.customWidth > 1000) {
            return (<div className="pubmed-main-ask-question-wrapper">
                <div className="headerWithBannerWrapper">
                    <div className="pubmed-main-ask-question-headerWithBanner">
                        <div className="pubmed-main-ask-question-headerWithBanner-left">
                            <Link to="/">
                                <span><img src={IMSLogo} alt="" style={{ paddingTop: "1.5%", marginBottom: "0.5%" }} /></span>
                            </Link>
                        </div>
                        <div className="pubmed-main-ask-question-headerWithBanner-right">
                            <div className="pubmed-main-ask-question-headerWithBanner-pubmedicon-wrapper">
                                <img
                                    src={pubMedIcon}
                                    alt=""
                                    className="global-feature-pubmedicon"
                                />
                            </div>
                            <div className="pubmed-main-ask-question-headerWithBanner-pubmednih-wrapper">
                                <img src={pubMedNIH} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="pubmed-main-search-input-wrapper">
                        <div className="pubmed-main-input-box-wrapper">
                            <input name="searchBar" placeholder="Ask your question..." className="pubmed-main-input-search-box" title="What are you looking for?" value={this.state.searchKeyword} onChange={this.handleChangeInSearch} onKeyDown={this.handleKeyDown} autoComplete="off" />
                            <span className="pubmed-main-icon-wrapper">
                                <span className="pubmed-main-search-icon-wrapper-webView" onClick={this.handleOnClickSearchIcon}>
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="magnifying-glass" className="svg-inline--fa fa-magnifying-glass" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ fontSize: "1.7rem" }}>
                                        <path fill="currentColor" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path>
                                    </svg>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>)
        }
    }

}
export default withRouter(PubMedSearchAskQuestionIndex);