import { Component } from "react";
import { Modal } from "react-bootstrap";
import ApiServices from "../../utils/apiServices";
import { imsLogo } from "../../assets";
import Button from "../../components/Button";
import MobileViewButton from "../../components/Button/MobileViewButton";
import '../Pages/Registration.css';

class ReviewModal extends Component {
    region;
    function;
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            selectedCountry: [],
            selectedRegion: [],
            gdprAcceptance: '',
            profession: '',
            //availableAreas:[],
            products: [],
            fullContent: '',
            preferredContent: '',
            documentPreference: [],
            selectedClasses: [],
            selectedAreas: [],
            areaId: [],
            classId: [],
            productId: [],
            reviewStatus: this.props.reviewStatus,
            wwid: JSON.parse(localStorage.getItem('wwid')),
            email: JSON.parse(localStorage.getItem('username')).toLowerCase(),
            jnjDepartment: JSON.parse(localStorage.getItem('jnjDepartment')),
            userdisplayName: JSON.parse(localStorage.getItem('userdisplayName')),
            history: this.props.history,
            emailBodyForRegister: 'Thank you for registering to use iMedical Search (iMS). Your request will be processed within 24 hours. Once approved you will have access to content relevant to your role; in the meantime you can begin using iMS but your search experience will be limited to publicly available materials.',
            emailBodyForUpdateRegistration: 'Thank you for updating your preferences for  iMedical search (iMS). While your request is being processed, you will have access information approved for you with your original preferences, Please notify the iMS team​  if you have any questions.',
            emailBodyForApproved: `Thank you for registering or updating your  iMedical Search (iMS) preferences. Your request/update has been approved and you now have access to content relevant to your role. If you have any questions regarding iMS please use the Contact Us link <a href="${window.origin}/Contact-Us">(${window.origin}/Contact-Us)</a> on the iMS homepage.`,
            emailSubjectForApproved: 'iMedical Search: thank you for registering/updating your preferences',
            russianuser: JSON.parse(localStorage.getItem('russianuser')),
        }
    }
    emailModule = async (status, mailUpdate) => {
        //console.log(mailUpdate);
        const service = new ApiServices();
        if (status === 'Registered') {
            if (mailUpdate === "Approved") {
                let reqBody = {
                    'to': `${this.state.email}`,
                    'subject': `${this.state.emailSubjectForApproved}`,
                    'username': `${this.state.userdisplayName}`,
                    'mailbody': `${this.state.emailBodyForApproved}`
                }
                service.sendEmail(reqBody)
            }
            else {
                let reqBody = {
                    'to': `${this.state.email}`,
                    'subject': 'iMedical ​Search: thank you for updating your preferences',
                    'username': `${this.state.userdisplayName}`,
                    'mailbody': `${this.state.emailBodyForUpdateRegistration}`
                }
                service.sendEmail(reqBody)
            }
        }
        else {
            let req = {
                'to': `${this.state.email}`,
                'subject': 'iMedical Search: thank you for registering',
                'username': `${this.state.userdisplayName}`,
                'mailbody': `${this.state.emailBodyForRegister}`
            }
            service.sendEmail(req)
        }
    }
    componentDidMount() {
        if (localStorage.getItem('region') && localStorage.getItem('function')) {
            this.region = localStorage.getItem('region').split(';');
            this.function = JSON.parse(localStorage.getItem('function'));
        }
        if (JSON.parse(localStorage.getItem('wwid')) === 0) {
            this.setState({
                wwid: this.props.wwid
            })
        }
        //console.log(this.props.gdprAcceptance)
        var region = "";
        var country = "";
        var gdprAcceptance = "";
        var selectedAreas = "";
        var selectedClasses = "";
        var products = "";
        var contentFullPreference = "";
        var contentPreference = "";
        var documentPreference = "";
        /*Setting the Region array to string */
        if (this.props.selectedRegion.length > 0) {
            region = this.props.selectedRegion.map((item, index) => {
                if (index === this.props.selectedRegion.length - 1) {
                    return (item + " ")
                }
                else {
                    return (item + "; ")
                }

            })
        }
        else {
            region = "-"
        }
        /*Setting the Country array to string */
        if (this.props.selectedCountry.length > 0) {
            country = this.props.selectedCountry.map((item, index) => {
                if (index === this.props.selectedCountry.length - 1) {
                    return (item + " ")
                }
                else {
                    return (item + "; ")
                }
            })
        }
        else {
            country = "-"
        }
        if (this.props.gdprAcceptance === "Yes") {
            gdprAcceptance = "Enabled"
        }
        else {
            gdprAcceptance = "Disabled"
        }
        /*Setting the Selected therapeutic array to string */
        if (this.props.selectedAreas.length > 0) {
            selectedAreas = this.props.selectedAreas.map((item, index) => {
                if (index === this.props.selectedAreas.length - 1) {
                    return (item + " ")
                }
                else {
                    return (item + "; ")
                }
            })
        }
        else {
            selectedAreas = "-"
        }
        /*Setting the Selected product classes array to string */
        if (this.props.selectedClasses.length > 0) {
            selectedClasses = this.props.selectedClasses.map((item, index) => {
                if (index === this.props.selectedClasses.length - 1) {
                    return (item + " ")
                }
                else {
                    return (item + "; ")
                }

            })
        }
        else {
            selectedClasses = "-"
        }
        /*Setting the Selected product array to string */
        if (this.props.products.length > 0) {
            products = this.props.products.map((item, index) => {
                if (index === this.props.products.length - 1) {
                    return (item + " ")
                }
                else {
                    return (item + "; ")
                }
            })
        }
        else {
            products = "-"
        }
        /*Setting the Selected document types array to string */
        if (this.props.documentPreference.length > 0) {
            documentPreference = this.props.documentPreference.map((item, index) => {
                if (index === this.props.documentPreference.length - 1) {
                    return (item + " ")
                }
                else {
                    return (item + "; ")
                }
            })
        }
        else {
            documentPreference = "-"
        }
        if (this.props.contentPreference === "Yes") {
            contentFullPreference = "Yes";
            contentPreference = "No";
        }
        else {
            contentFullPreference = "No";
            contentPreference = "Yes";
        }
        this.setState({
            showModal: this.props.showModal,
            selectedCountry: country,
            selectedRegion: region,
            gdprAcceptance: gdprAcceptance,
            profession: this.props.profession,
            products: products,
            fullContent: contentFullPreference,
            preferredContent: contentPreference,
            documentPreference: documentPreference,
            selectedAreas: selectedAreas,
            selectedClasses: selectedClasses,
            areaId: this.props.areaId,
            classId: this.props.classId,
            productId: this.props.productId
        })
        //this.generateArrayString()
        //console.log(this.state);
    }
    generateArrayString = (array) => {
        let arrStr = ''
        //console.log(array)
        array.map((item, index) => {
            if (index === array.length - 1) {
                arrStr += item
            }
            else {
                arrStr += item + ";"
            }
            return null
        })
        //console.log(arrStr)
        return arrStr
    }
    handleClose = () => {
        this.setState({
            showModal: false
        })
    }
    handleBack = () => {
        //this.handleClose();
        this.setState({
            showModal: false
        })
        this.props.onBackFrmReview(this.props.selectedRegion, this.props.selectedCountry, this.props.gdprAcceptance, this.props.profession, this.props.products, this.props.contentPreference, this.props.documentPreference, this.props.selectedAreas, this.props.selectedClasses, this.props.history, this.props.areaId, this.props.classId, this.props.productId);
    }
    handleConfirm = async () => {
        let status = 'Pending', userStatus, mailUpdate = 'Pending';
        const service = new ApiServices();
        let arrOne = this.region;
        let arrTwo = this.props.selectedRegion;
        if (this.region) {
            let result =
                arrOne.length === arrTwo.length &&
                arrOne.every(function (element) {
                    return arrTwo.includes(element);
                });
            userStatus = 'Registered';
            if (!result || this.function !== this.props.profession) {
                status = 'Pending'
                mailUpdate = 'Pending'
            }
            else {
                status = this.state.reviewStatus
                mailUpdate = 'Approved'
            }
        }
        else {
            status = 'Pending'
            userStatus = 'Unregistered';
        }
        let region = this.generateArrayString(this.props.selectedRegion)
        let countries = this.generateArrayString(this.props.selectedCountry)
        let selectedAreas = this.generateArrayString(this.props.selectedAreas)
        let selectedClasses = this.generateArrayString(this.props.selectedClasses)
        let products = this.generateArrayString(this.props.products)
        let documentPreference = this.generateArrayString(this.props.documentPreference)
        let _jnjDepartment = this.state.jnjDepartment;
        if (_jnjDepartment) {
            _jnjDepartment = _jnjDepartment.replace(/'/g, "''");
        }
        var query = `call "iMS".phm_ims_addEditPreference(${this.state.wwid},'${this.state.email.toLowerCase()}','${region}','${countries}','${this.props.gdprAcceptance}','${this.props.profession}','${selectedAreas}','${selectedClasses.replace(/'/g, "''")}','${products}','${this.props.contentPreference}','${documentPreference}','${this.state.email.toLowerCase()}','${this.state.email.toLowerCase()}','${_jnjDepartment}','${this.state.userdisplayName.replace(/'/g, "''")}','${status}','${this.state.areaId}','${this.state.classId}','${this.state.productId}')`;
        // console.log(query);
        var res = await service.updateDbService(query);
        if (res) {
            this.emailModule(userStatus, mailUpdate);
            localStorage.setItem('userExists', JSON.stringify(true));
            /* localStorage.setItem('region',region);
            localStorage.setItem('function',JSON.stringify(this.props.profession)); */
            localStorage.setItem('userproducts', products);
            localStorage.setItem('doctypepref', documentPreference);
            localStorage.setItem('contentpreference', JSON.stringify(this.props.contentPreference));
            var response = await service.getUserDetails(this.state.wwid);
            if (response.accessgroup) {
                localStorage.setItem('accessgroup', JSON.stringify(response.accessgroup));
            } else {
                localStorage.setItem('accessgroup', JSON.stringify(''));
            }
            // if (this.props.gdprAcceptance === "No") {
            //     localStorage.setItem('wwid', JSON.stringify(0));
            // }
            // else {
            //     localStorage.setItem('wwid', JSON.stringify(this.state.wwid));
            // }
            localStorage.setItem('wwid', JSON.stringify(this.state.wwid));
            this.props.history.push({ pathname: "/recommended" });
        }
        //console.log(query);
    }
    render() {
        //console.log(this.props);
        if (this.props.customWidth < 600) {
            return (
                <div>
                    <div>
                        <p>
                            <br />
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            <br />
                        </p>

                        <p>
                            <br />
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            <br />
                        </p>
                    </div>
                    {this.state.showModal ?
                        <Modal
                            show={this.state.showModal}
                            size="lg md sm xs"
                            centered
                            aria-labelledby="contained-modal-title-vcenter"
                            dialogClassName="review-custom-modal-wrapper ReviewModal-mobile"
                            bsClass="my-modal"
                            scrollable={true}
                            className="customModal-tablet"
                        >
                            <div className="left-border">
                                <Modal.Header style={{ padding: "1% 5%", textAlign: "center", border: "none", marginTop: "15px", marginBottom: "5px" }}>
                                    <Modal.Title id="contained-modal-title-vcenter" style={{ width: "100%" }}>
                                        <img src={imsLogo} alt="ims-logo" />
                                        <br />
                                        <span style={{ fontSize: "18px" }}>
                                            Review your details at a glance, before you confirm.
                                        </span>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body scrollable={true} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                    <div style={{ padding: "0 3%", /* overflowY: "auto", height: "68vh" */ }}>
                                        <div className="modal-review-user-details">
                                            <b> {this.state.userdisplayName}</b>
                                            <span style={{ float: "right" }}>
                                                WWID:
                                                <b>&nbsp;{this.state.wwid}</b>
                                            </span>
                                        </div>
                                        <div className="modal-review-preference-details">
                                            <div className="details-review">
                                                <div className="review-wrap">
                                                    <div className="review-row-odd">
                                                        <span className="review-title-span">Region(s): </span>
                                                        <span className="review-value-span">{this.state.selectedRegion}</span>
                                                    </div>
                                                    <div className="review-row-even">
                                                        <span className="review-title-span">Function/Role: </span>
                                                        <span className="review-value-span">{this.state.profession}</span>
                                                    </div>
                                                    <div className="review-row-odd">
                                                        <span className="review-title-span">Therapeutic Area(s): </span>
                                                        <span className="review-value-span">{this.state.selectedAreas}</span>
                                                    </div>
                                                    <div className="review-row-even">
                                                        <span className="review-title-span">Drug Class(es): </span>
                                                        <span className="review-value-span">{this.state.selectedClasses}</span>
                                                    </div>
                                                    <div className="review-row-odd">
                                                        <span className="review-title-span">Product(s): </span>
                                                        <span className="review-value-span">{this.state.products}</span>
                                                    </div>
                                                    <div className="review-row-even">
                                                        <span className="review-title-span">Document Type(s): </span>
                                                        <span className="review-value-span">{this.state.documentPreference}</span>
                                                    </div>
                                                    {(this.state.russianuser) && <div className="review-row-odd">
                                                        <span className="review-title-span">Activity tracking: </span>
                                                        <span className="review-value-span">{this.state.gdprAcceptance}</span>
                                                    </div>}
                                                    <div className="review-row-even">
                                                        <span className="review-title-span">
                                                            Results based on all products but boosted on my selected products:
                                                        </span>
                                                        <span className="review-value-span">{this.state.fullContent}</span>
                                                        <br />
                                                        <span className="review-title-span">
                                                            Results based only on my selected products:
                                                        </span>
                                                        <span className="review-value-span">
                                                            {this.state.preferredContent}
                                                        </span>
                                                    </div>
                                                </div><br />
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer style={{ padding: "1% 4%", border: "none" }}>
                                    <div style={{ width: "100%" }} >
                                        <div className="gsf-popup-button-wrapper-mobile" >
                                            <div className="welcomeNxtBtn-mobile">
                                                <MobileViewButton text='Confirm' customColor='#2EB000' action={this.handleConfirm} className="continueButton" />
                                            </div>
                                            <div className="welcomeNxtBtn-mobile">
                                                <MobileViewButton text='Go back and modify details' customColor='#FF8000' action={this.handleBack} />
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Footer>
                            </div>
                        </Modal> : null}
                </div>
            )
        }
        else if (this.props.customWidth < 1000) {
            return (
                <div>
                    <div>
                        <p>
                            <br />
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            <br />
                        </p>

                        <p>
                            <br />
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            <br />
                        </p>
                    </div>
                    {this.state.showModal ?
                        <Modal
                            show={this.state.showModal}
                            size="lg md sm xs"
                            centered
                            aria-labelledby="contained-modal-title-vcenter"
                            dialogClassName="review-custom-modal-wrapper"
                            bsClass="my-modal"
                            scrollable={true}
                            className="customModal-tablet"
                        >
                            <div className="left-border">
                                <Modal.Header style={{ padding: "1% 5%", textAlign: "center", border: "none" }}>
                                    <Modal.Title id="contained-modal-title-vcenter" style={{ width: "100%" }}>
                                        <img src={imsLogo} alt="ims-logo" /><br />
                                        <span style={{ fontSize: "18px" }}>
                                            Review your details at a glance, before you confirm.
                                        </span>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body scrollable={true} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                    <div style={{ padding: "0 3%", /* overflowY: "auto", height: "68vh" */ }}>
                                        <div className="modal-review-user-details">
                                            <b> {this.state.userdisplayName}</b>
                                            <span style={{ float: "right" }}>
                                                WWID:
                                                <b>&nbsp;{this.state.wwid}</b>
                                            </span>
                                        </div>
                                        <div className="modal-review-preference-details">
                                            <div className="details-review">
                                                <div className="review-wrap">
                                                    <div className="review-row-odd">
                                                        <span className="review-title-span">Region(s): </span>
                                                        <span className="review-value-span">{this.state.selectedRegion}</span>
                                                    </div>
                                                    <div className="review-row-even">
                                                        <span className="review-title-span">Function/Role: </span>
                                                        <span className="review-value-span">{this.state.profession}</span>
                                                    </div>
                                                    <div className="review-row-odd">
                                                        <span className="review-title-span">Therapeutic Area(s): </span>
                                                        <span className="review-value-span">{this.state.selectedAreas}</span>
                                                    </div>
                                                    <div className="review-row-even">
                                                        <span className="review-title-span">Drug Class(es): </span>
                                                        <span className="review-value-span">{this.state.selectedClasses}</span>
                                                    </div>
                                                    <div className="review-row-odd">
                                                        <span className="review-title-span">Product(s): </span>
                                                        <span className="review-value-span">{this.state.products}</span>
                                                    </div>
                                                    <div className="review-row-even">
                                                        <span className="review-title-span">Document Type(s): </span>
                                                        <span className="review-value-span">{this.state.documentPreference}</span>
                                                    </div>
                                                    {(this.state.russianuser) && <div className="review-row-odd">
                                                        <span className="review-title-span">Activity tracking: </span>
                                                        <span className="review-value-span">{this.state.gdprAcceptance}</span>
                                                    </div>}
                                                    <div className="review-row-even">
                                                        <span className="review-title-span">
                                                            Results based on all products but boosted on my selected products:
                                                        </span>
                                                        <span className="review-value-span">{this.state.fullContent}</span>
                                                        <br />
                                                        <span className="review-title-span">
                                                            Results based only on my selected products:
                                                        </span>
                                                        <span className="review-value-span">
                                                            {this.state.preferredContent}
                                                        </span>
                                                    </div>
                                                </div><br />
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer style={{ padding: "1% 4%", border: "none" }}>
                                    <div style={{ width: "100%" }} >
                                        <div className="buttonContainer" style={{ padding: "0px 0px 30px 0px" }}>
                                            <div className="welcomeNxtBtn">
                                                <Button text='Confirm' customColor='#2EB000' action={this.handleConfirm} className="continueButton" />
                                            </div>
                                            <div className="welcomeNxtBtn">
                                                <Button text='Go back and modify details' customColor='#FF8000' action={this.handleBack} />
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Footer>
                            </div>
                        </Modal> : null}
                </div>
            )
        }
        else {
            return (
                <div>
                    <div>
                        <p>
                            <br />
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            <br />
                        </p>

                        <p>
                            <br />
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            <br />
                        </p>
                        <p>
                            <br />
                        </p>
                    </div>
                    {this.state.showModal ?
                        <Modal
                            show={this.state.showModal}
                            size="lg md sm xs"
                            centered
                            aria-labelledby="contained-modal-title-vcenter"
                            dialogClassName="review-custom-modal-wrapper"
                            bsClass="my-modal"
                            scrollable={true}
                        >
                            <div className="left-border">
                                <Modal.Header style={{ padding: "1% 5%", textAlign: "center", border: "none" }}>
                                    <Modal.Title id="contained-modal-title-vcenter" style={{ width: "100%" }}>
                                        <img src={imsLogo} alt="ims-logo" /><br />
                                        <span style={{ color: "#0074B0", fontSize: "18px" }}>
                                            Review your details at a glance, before you confirm.
                                        </span>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body scrollable={true}>
                                    <div style={{ padding: "0 3%", overflowY: "auto", height: "68vh" }}>
                                        <div className="modal-review-user-details">
                                            <b> {this.state.userdisplayName}</b>
                                            <span style={{ float: "right" }}>
                                                WWID:
                                                <b>&nbsp;{this.state.wwid}</b>
                                            </span>
                                        </div>
                                        <div className="modal-review-preference-details">
                                            <div className="details-review">
                                                <div className="review-wrap">
                                                    <div className="review-row-odd">
                                                        <span className="review-title-span">Region(s): </span>
                                                        <span className="review-value-span">{this.state.selectedRegion}</span>
                                                    </div>
                                                    <div className="review-row-even">
                                                        <span className="review-title-span">Function/Role: </span>
                                                        <span className="review-value-span">{this.state.profession}</span>
                                                    </div>
                                                    <div className="review-row-odd">
                                                        <span className="review-title-span">Therapeutic Area(s): </span>
                                                        <span className="review-value-span">{this.state.selectedAreas}</span>
                                                    </div>
                                                    <div className="review-row-even">
                                                        <span className="review-title-span">Drug Class(es): </span>
                                                        <span className="review-value-span">{this.state.selectedClasses}</span>
                                                    </div>
                                                    <div className="review-row-odd">
                                                        <span className="review-title-span">Product(s): </span>
                                                        <span className="review-value-span">{this.state.products}</span>
                                                    </div>
                                                    <div className="review-row-even">
                                                        <span className="review-title-span">Document Type(s): </span>
                                                        <span className="review-value-span">{this.state.documentPreference}</span>
                                                    </div>
                                                    {(this.state.russianuser) && <div className="review-row-odd">
                                                        <span className="review-title-span">Activity tracking: </span>
                                                        <span className="review-value-span">{this.state.gdprAcceptance}</span>
                                                    </div>}
                                                    <div className="review-row-even">
                                                        <span className="review-title-span">
                                                            Results based on all products but boosted on my selected products:
                                                        </span>
                                                        <span className="review-value-span">{this.state.fullContent}</span>
                                                        <br />
                                                        <span className="review-title-span">
                                                            Results based only on my selected products:
                                                        </span>
                                                        <span className="review-value-span">
                                                            {this.state.preferredContent}
                                                        </span>
                                                    </div>
                                                </div><br />
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer style={{ padding: "1% 4%", border: "none" }}>
                                    <div style={{ width: "100%" }} >
                                        <div className="buttonContainer" style={{ padding: "0px 0px 30px 0px" }}>
                                            <div className="welcomeNxtBtn">
                                                <Button text='Confirm' customColor='#2EB000' action={this.handleConfirm} className="continueButton" />
                                            </div>
                                            <div className="welcomeNxtBtn">
                                                <Button text='Go back and modify details' customColor='#FF8000' action={this.handleBack} />
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Footer>
                            </div>
                        </Modal> : null}
                </div>
            )
        }
    }
}

export default ReviewModal;