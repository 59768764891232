import axios from "axios";
import { LocationOrigin, ExcludeWWIDForAnalyticalLogs } from "../utils/utilityServices";
import { UserGeoData } from "../auth/Authorization";
import { isEmptyObject } from 'jquery'
import Services from "../utils/services";
export default class trackingService {
  indexTrackingData(
    userName,
    userWWID,
    userEmail,
    userFunction,
    userRegion,
    userProducts,
    userDocTypePref,
    userSession,
    userDeviceType,
    userDeviceOs,
    userBrowserName,
    userBrowserVersion,
    userDepartment,
    companyname,
    eventAction,
    eventlabel,
    eventSource,
    searchKeyword,
    searchFilters,
    docTitle,
    docVersion,
    docNumber,
    docID,
    docUrl,
    docProducts,
    docType,
    docInteractionType,
    csPath,
    csProduct,
    contentSource,
    pageNumber,
    pageSize,
    doclastmodified,
    orderedby = '',
    orderedfor = '',
    assistantinfo = {},
    authoringinfo = {},
    genaianswering2info = {},
  ) {
    userName = userName || '""';
    userWWID = userWWID || '""';
    userEmail = userEmail || '""';
    userFunction = userFunction || '""';
    userRegion = userRegion || "";
    userProducts = userProducts || "";
    userDocTypePref = userDocTypePref || "";
    userSession = userSession || '""';
    userDeviceType = userDeviceType || '""';
    userDeviceOs = userDeviceOs || '""';
    userBrowserName = userBrowserName || '""';
    userBrowserVersion = userBrowserVersion || '""';
    companyname = companyname || '""';
    eventAction = eventAction || "";
    eventlabel = eventlabel || "";
    eventSource = eventSource || "";
    searchKeyword = searchKeyword || "";
    searchFilters = searchFilters || "";
    docTitle = docTitle || "";
    docVersion = docVersion || "";
    docNumber = docNumber || "";
    docID = docID || "";
    docUrl = docUrl || "";
    docProducts = docProducts || "";
    docType = docType || "";
    docInteractionType = docInteractionType || "";
    csPath = csPath || "";
    csProduct = csProduct || "";
    contentSource = contentSource || "";
    pageNumber = pageNumber || "";
    pageSize = pageSize || "";
    userDepartment = userDepartment || '""';
    doclastmodified = doclastmodified || "1900-01-01T00:00:00Z";
    let currentCountry = 'united states';
    if (UserGeoData && UserGeoData.country_name) {
      currentCountry = UserGeoData.country_name.toLowerCase();
    }
    // orderedby=orderedby || '""';
    // orderedfor=orderedfor || '""';
    let _userWWID = JSON.parse(userWWID);
    if (_userWWID) {
      _userWWID = parseInt(_userWWID);
    }
    if (docTitle) {
      docTitle = docTitle.toString().replace(/\n/g, " ");
      docTitle = docTitle.toString().replace(/"/g, '\\"');
    }
    if (docUrl) {
      docUrl = docUrl.toString().replace(/"/g, '\\"');
    }
    if (userWWID === "0") {
      userWWID = '"Anonymous"';
      userName = '"Anonymous"';
      userEmail = '"Anonymous"';
    }
    /*let isDatatracking = localStorage.getItem('datatracking')
    if (isDatatracking && JSON.parse(isDatatracking) === "No") {
      userWWID = '"Anonymous"';
      userName = '"Anonymous"';
      userEmail = '"Anonymous"';
    }*/

    var currentdate = new Date();
    var curMonth = currentdate.getUTCMonth() + 1;
    if (curMonth < 10) {
      curMonth = "0" + curMonth;
    }
    var curDate = currentdate.getUTCDate();
    if (curDate < 10) {
      curDate = "0" + curDate;
    }
    var curHour = currentdate.getUTCHours();
    if (curHour < 10) {
      curHour = "0" + curHour;
    }
    var curMin = currentdate.getUTCMinutes();
    if (curMin < 10) {
      curMin = "0" + curMin;
    }
    var curSec = currentdate.getUTCSeconds();
    if (curSec < 10) {
      curSec = "0" + curSec;
    }
    var curMil = currentdate.getUTCMilliseconds();
    if (curMil < 10) {
      curMil = "00" + curMil;
    }
    else if (curMil < 100) {
      curMil = "0" + curMil;
    }
    var datetime =
      currentdate.getFullYear() +
      "-" +
      curMonth +
      "-" +
      curDate +
      "T" +
      curHour +
      ":" +
      curMin +
      ":" +
      curSec +
      "." +
      curMil +
      "Z";
    var analyticsJson =
      '{"userinfo": {"name":' +
      userName +
      ',"wwid":' +
      userWWID +
      ',"email":' +
      userEmail +
      ',"function":' +
      userFunction +
      ',"region":"' +
      userRegion +
      '","userproducts":"' +
      userProducts +
      '","doctypepref":"' +
      userDocTypePref +
      '","session":' +
      userSession +
      ',"devicetype":' +
      userDeviceType +
      ',"deviceos":' +
      userDeviceOs +
      ',"browserName":' +
      userBrowserName +
      ',"browserVersion":' +
      userBrowserVersion +
      ',"department":' +
      userDepartment +
      ', "companyname":' +
      companyname +
      ', "currentCountry":"' +
      currentCountry +
      '"},"eventinfo":{"eventcategory":"' +
      window.location.host +
      '","eventaction": "' +
      eventAction +
      '","eventlabel": "' +
      eventlabel +
      '","eventsource": "' +
      eventSource +
      '","datehour": "' +
      datetime +
      '"},"searchinfo":{"keyword": "' +
      searchKeyword +
      '","filters": "' +
      searchFilters +
      '"},"documentinfo":{"title": "' +
      docTitle +
      '","version": "' +
      docVersion +
      '","docnumber": "' +
      docNumber +
      '","docid": "' +
      docID +
      '","docurl": "' +
      docUrl +
      '","docproducts": "' +
      docProducts +
      '","doctype": "' +
      docType +
      '","interactiontype": "' +
      docInteractionType +
      '","cspath": "' +
      csPath +
      '","csproduct": "' +
      csProduct +
      '","contentsource": "' +
      contentSource +
      '","pagenumber": "' +
      pageNumber +
      '","pagesize": "' +
      pageSize +
      '","lastmodified": "' +
      doclastmodified +
      '","orderedby": "' +
      orderedby +
      '","orderedfor": "' +
      orderedfor +
      //'"}}';
      '"}' + this.getAssistantinfoPayload(assistantinfo) +
      this.getAuthoringinfoPayload(authoringinfo) + this.getGenAiAnswering2infoPayload(genaianswering2info) + '}';
    if (LocationOrigin !== 'https://ims.jnj.com' || !ExcludeWWIDForAnalyticalLogs.includes(_userWWID)) {
      var url =
        LocationOrigin + process.env.REACT_APP_IMS_ANALYTICS_API_URL + "iud";
      //url = 'https://predev-ims.jnj.com' + process.env.REACT_APP_IMS_ANALYTICS_API_URL + "iud";
      //var url = configDetails.IMS_ANALYTICS_API_URL + 'iud'
      let service = new Services();
      axios.post(url, analyticsJson, {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
      }).then((response) => {
        let _username = localStorage.getItem("username");
        if (eventAction === "User Entry Point") {
          if (response.status === 200) {
            service.logServiceForLoginIssus(`Called '${process.env.REACT_APP_IMS_ANALYTICS_API_URL}iud' API successfully for User: ${_username.toLowerCase()}`);
          } else {
            service.logServiceForLoginIssus(`Error to call '${process.env.REACT_APP_IMS_ANALYTICS_API_URL}iud' API for User: ${_username.toLowerCase()}, response=${JSON.stringify(response)}`);
          }
        }
      }).catch((error) => {
        if (eventAction === "User Entry Point") {
          let _username = localStorage.getItem("username");
          service.logServiceForLoginIssus(`Error to call '${process.env.REACT_APP_IMS_ANALYTICS_API_URL}iud' API for User: ${_username.toLowerCase()}, error=${JSON.stringify(error)}`);
        }
        console.log(error);
      });
    }
  }
  getAssistantinfoPayload = (assistantinfo) => {
    return isEmptyObject(assistantinfo) ? '' : `,"assistantinfo":${JSON.stringify(assistantinfo)}`;
  }
  getAuthoringinfoPayload = (authoringinfo) => {
    return isEmptyObject(authoringinfo) ? '' : `,"authoringinfo":${JSON.stringify(authoringinfo)}`;
  }
  getGenAiAnswering2infoPayload = (genaianswering2info) => {
    return isEmptyObject(genaianswering2info) ? '' : `,"genaianswering2info":${JSON.stringify(genaianswering2info)}`;
  }
  getMostPopularDocument = async (startDate, endDate) => {
    startDate = this.formatDate(startDate);
    endDate = this.formatDate(endDate);
    var url =
      LocationOrigin +
      process.env.REACT_APP_IMS_ANALYTICS_API_URL +
      "mpd?startdate=" +
      startDate +
      "&enddate=" +
      endDate;
    let res;
    await axios
      .get(url)
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  };
  getDocumentTypes = async (startDate, endDate) => {
    var url =
      LocationOrigin +
      process.env.REACT_APP_IMS_ANALYTICS_API_URL +
      "dt";
    // var url = "https://stage-ims.jnj.com/api/analytics/dt";
    let res;
    await axios
      .get(url)
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  };
  getMostActiveDepartment = async (startDate, endDate) => {
    startDate = this.formatDate(startDate);
    endDate = this.formatDate(endDate);
    var url =
      LocationOrigin +
      process.env.REACT_APP_IMS_ANALYTICS_API_URL +
      "gad?startdate=" +
      startDate +
      "&enddate=" +
      endDate;
    let res;
    await axios
      .get(url)
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  };
  getMostPopularDocumentDetails = async (startDate, endDate, doctype) => {
    startDate = this.formatDate(startDate);
    endDate = this.formatDate(endDate);
    var url =
      LocationOrigin +
      process.env.REACT_APP_IMS_ANALYTICS_API_URL +
      "mpdd?startdate=" +
      startDate +
      "&enddate=" +
      endDate +
      "&doctype=" +
      doctype;
    let res;
    await axios
      .get(url)
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  };
  getTrackingData = async (rptType, startDate, endDate) => {
    startDate = this.formatDate(startDate);
    endDate = this.formatDate(endDate);
    var url;
    if (rptType === "gnds") {
      url =
        LocationOrigin +
        process.env.REACT_APP_IMS_ANALYTICS_API_URL +
        "gnds?startdate=" +
        startDate +
        "&enddate=" +
        endDate;
      //url = configDetails.IMS_ANALYTICS_API_URL + 'gnds?startdate=' + startDate + '&enddate=' + endDate;
    } else if (rptType === "iu") {
      url =
        LocationOrigin +
        process.env.REACT_APP_IMS_API_URL +
        "?dbquery=select wwid, regions, profession, email, username, department, lastlogindate from \"iMS\".phm_ims_registrationdetails where lastlogindate <= TO_TIMESTAMP('" +
        endDate +
        "', 'YYYY-MM-DD HH24:MI:SS')";
      //url = configDetails.IMS_API_URL + "?dbquery=select wwid, regions, profession, email, username, department, lastlogindate from \"iMS\".phm_ims_registrationdetails where TO_TIMESTAMP(lastlogindate, 'DD/MM/YYYY HH24:MI:SS') < TO_TIMESTAMP('"+ endDate +"', 'YYYY-MM-DD HH24:MI:SS')";
    } else {
      url =
        LocationOrigin +
        process.env.REACT_APP_IMS_ANALYTICS_API_URL +
        "gtd?rpttype=" +
        rptType +
        "&startdate=" +
        startDate +
        "&enddate=" +
        endDate;
      //url = configDetails.IMS_ANALYTICS_API_URL + 'gtd?rpttype=' + rptType + '&startdate=' + startDate + '&enddate=' + endDate;
    }
    let res;
    await axios
      .get(url)
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  };
  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  getActiveUser = async () => {
    var url =
      LocationOrigin + process.env.REACT_APP_IMS_ANALYTICS_API_URL + "gau";
    let res;
    await axios
      .get(url)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  };
  getDidYouMean = async (searchKeyword) => {
    var url =
      LocationOrigin +
      process.env.REACT_APP_IMS_QP_DIDYOUMEAN +
      "?query=" +
      searchKeyword;
    let res;
    await axios
      .get(url)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  };
  getAuthoringUsage = async (startDate, endDate) => {
    startDate = this.formatDate(startDate);
    endDate = this.formatDate(endDate);
    var url =
      LocationOrigin +
      process.env.REACT_APP_IMS_ANALYTICS_API_URL +
      "gauu?startdate=" +
      startDate +
      "&enddate=" +
      endDate;
    let res;
    await axios
      .get(url)
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  };
  getAnsweringUsage = async (startDate, endDate) => {
    startDate = this.formatDate(startDate);
    endDate = this.formatDate(endDate);
    var url =
      LocationOrigin +
      process.env.REACT_APP_IMS_ANALYTICS_API_URL +
      "ganu?startdate=" +
      startDate +
      "&enddate=" +
      endDate;
    let res;
    await axios
      .get(url)
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  };
  getAnswering2Usage = async (startDate, endDate) => {
    startDate = this.formatDate(startDate);
    endDate = this.formatDate(endDate);
    var url =
      LocationOrigin +
      process.env.REACT_APP_IMS_ANALYTICS_API_URL +
      "gan2u?startdate=" +
      startDate +
      "&enddate=" +
      endDate;
    let res;
    await axios
      .get(url)
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  };
  getAnswering2DaysWiseUsage = async (startDate, endDate) => {
    startDate = this.formatDate(startDate);
    endDate = this.formatDate(endDate);
    var url =
      LocationOrigin +
      process.env.REACT_APP_IMS_ANALYTICS_API_URL +
      "gan2dwu?startdate=" +
      startDate +
      "&enddate=" +
      endDate;
    let res;
    await axios
      .get(url)
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  };
  getAnswering2LikeUnlike = async (startDate, endDate) => {
    startDate = this.formatDate(startDate);
    endDate = this.formatDate(endDate);
    var url =
      LocationOrigin +
      process.env.REACT_APP_IMS_ANALYTICS_API_URL +
      "gan2lu?startdate=" +
      startDate +
      "&enddate=" +
      endDate;
    let res;
    await axios
      .get(url)
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  };
  getAnswering2AnswerFeedback = async (startDate, endDate) => {
    startDate = this.formatDate(startDate);
    endDate = this.formatDate(endDate);
    var url =
      LocationOrigin +
      process.env.REACT_APP_IMS_ANALYTICS_API_URL +
      "gan2af?startdate=" +
      startDate +
      "&enddate=" +
      endDate;
    let res;
    await axios
      .get(url)
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  };
  getAnswering2AnswerQnA = async (startDate, endDate) => {
    startDate = this.formatDate(startDate);
    endDate = this.formatDate(endDate);
    var url =
      LocationOrigin +
      process.env.REACT_APP_IMS_ANALYTICS_API_URL +
      "gan2QnA?startdate=" +
      startDate +
      "&enddate=" +
      endDate;
    let res;
    await axios
      .get(url)
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  };
}
