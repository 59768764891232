import { Component } from "react";
import Header from "../../components/header/HeaderWithBanner";
import Footer from "../../components/footer/Footer"
import ApiServices from "../../utils/apiServices";
import { imsLoader, userRole } from "../../assets";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import Services from "../../utils/services";
import Button from "../../components/Button";
import NavigationMenu from "../../components/Navigation/NavigationMenu";
import MobileViewButton from "../../components/Button/MobileViewButton";

class RegisterRole extends Component {
    userExists;
    russianUser;
    constructor(props) {
        super(props);
        this.state = {
            wwid: JSON.parse(localStorage.getItem('wwid')),
            email: JSON.parse(localStorage.getItem('username')).toLowerCase(),
            jnjDepartment: '',
            userdisplayName: '',
            userExists: false,
            selectedCountry: 'Russia',
            selectedRegion: 'EMEA - MEWA, Israel & CIS',
            gdprAcceptance: 'No',
            profession: '',
            contentPreference: 'Yes',
            reviewStatus: 'Pending',
            displayError: false,
            emailBodyForRegister: 'Thank you for registering to use iMedical Search (iMS). Your request will be processed within 24 hours. Once approved you will have access to content relevant to your role; in the meantime you can begin using iMS but your search experience will be limited to publicly available materials.',
            emailBodyForUpdateRegistration: 'Thank you for updating your preferences for  iMedical search (iMS). While your request is being processed, you will have access information approved for you with your original preferences, Please notify the iMS team​  if you have any questions.',
            history: this.props.history,
            width: window.innerWidth,
            height: window.innerHeight
        }
    }
    async componentDidMount() {
        let name = localStorage.getItem('userdisplayName').replaceAll('"', '');
        /* let lastName = name.split(' ').pop();
        let firstName = name.substring(0,name.lastIndexOf(' ')+1); */
        document.title = `${name} - User Details - iMedical Search`;
        this.userExists = localStorage.getItem('userExists');
        this.russianUser = localStorage.getItem('russianuser')
        //var mail = JSON.parse(localStorage.getItem('username')).toLowerCase();
        let wwid = JSON.parse(localStorage.getItem('wwid'));
        this.setState({
            jnjDepartment: localStorage.getItem('jnjDepartment'),
            userdisplayName: localStorage.getItem('userdisplayName')
        })
        const services = new ApiServices();
        let login = new Services();
        login.logService(`Registration page successfully mounted for ${wwid}`, 'info');
        if (this.props.location.state) {
            if (this.props.location.state.fromDisclaimer) {
                this.setState({
                    userExists: true,
                    username: this.userid
                })
                this.russianUser = 'true';
            }
        }
        if (this.russianUser === 'true') {
            var response = await services.getRegistrationDetails(wwid);
            if (response) {
                //console.log(response)
                var countries
                if (response.countries) {
                    countries = response.countries.split(';')
                }
                else {
                    countries = this.state.selectedCountry
                }
                this.setState({
                    selectedRegion: response.regions.split(';'),
                    selectedCountry: countries,
                    profession: response.profession,
                    gdprAcceptance: response.datatracking,
                    contentPreference: response.contentprefernce,
                    reviewStatus: response.status,
                    wwid: response.wwid,
                    jnjDepartment: response.jnjDepartment,
                    userdisplayName: response.username
                })
                /* localStorage.setItem('region',response.regions);
                localStorage.setItem('function',JSON.stringify(response.profession)); */
                localStorage.setItem('userproducts', null);
                localStorage.setItem('doctypepref', null);
                localStorage.setItem('contentpreference', JSON.stringify(response.contentprefernce));
                localStorage.setItem('russianuser', JSON.stringify(response.russianuser));
                var _response = await services.getUserDetails(wwid);
                if (_response.accessgroup) {
                    localStorage.setItem('accessgroup', JSON.stringify(_response.accessgroup));
                } else {
                    localStorage.setItem('accessgroup', JSON.stringify(''));
                }
            }
            //console.log(response);
            if (this.props.location.state) {
                if (this.props.location.state.fromDisclaimer) {
                    this.setState({
                        userExists: true,
                        username: this.userid
                    })
                }
            }
            else {
                if (this.userExists) {
                    this.setState({
                        userExists: this.userExists
                    })
                }
                else {
                    console.log('Access Denied');
                    this.props.history.push('/');
                }
            }
            window.addEventListener('resize', this.updateDimensions);
        }
        else {
            this.props.history.push('/registration')
        }

    }
    handleOnChange = (e) => {
        this.setState({
            profession: e.target.value,
            displayError: false
        })
    }
    emailModule = async (status) => {
        //console.log(status);
        const service = new ApiServices();
        if (status === 'Registered') {
            let reqBody = {
                'to': `${this.state.email}`,
                'subject': 'iMedical ​Search: thank you for updating your preferences',
                'username': `${this.state.userdisplayName}`,
                'mailbody': `${this.state.emailBodyForUpdateRegistration}`
            }
            service.sendEmail(reqBody)
        }
        else {
            let req = {
                'to': `${this.state.email}`,
                'subject': 'iMedical Search: thank you for registering',
                'username': `${this.state.userdisplayName}`,
                'mailbody': `${this.state.emailBodyForRegister}`
            }
            service.sendEmail(req)
        }
    }
    handleSubmit = async () => {
        //console.log(this.state);
        let res, userStatus, role;
        const service = new ApiServices();
        role = localStorage.getItem('function');
        if (role) {
            userStatus = 'Registered'
        }
        else {
            userStatus = 'Unregistered'
        }
        var query = `call "iMS".phm_ims_addeditrussianpreference(${this.state.wwid},'${this.state.email.toLowerCase()}','${this.state.selectedRegion}','${this.state.selectedCountry}','${this.state.gdprAcceptance}','${this.state.profession}','${this.state.contentPreference}','${this.state.email.toLowerCase()}','${this.state.email.toLowerCase()}','${this.state.jnjDepartment}','${this.state.userdisplayName.replace(/'/g, "''")}','${this.state.reviewStatus}')`;
        //console.log(query);
        if (this.state.profession) {
            res = await service.updateDbService(query);
            if (res) {
                this.emailModule(userStatus);
                localStorage.setItem('userExists', JSON.stringify(true));
                localStorage.setItem('region', this.state.selectedRegion);
                localStorage.setItem('function', JSON.stringify(this.state.profession));
                localStorage.setItem('userproducts', this.state.products);
                localStorage.setItem('doctypepref', this.state.documentPreference);
                localStorage.setItem('contentpreference', JSON.stringify(this.state.contentPreference));
                localStorage.setItem('russianuser', JSON.stringify(true));
                this.props.history.push({ pathname: "/recommended" });
            }
        }
        else {
            this.setState({
                displayError: true
            })
        }
    }
    componentWillUnmount() {
        let login = new Services();
        window.removeEventListener('resize', this.updateDimensions);
        let userid = JSON.parse(localStorage.getItem('username'));
        login.logService(`Registration page successfully unmounted for ${userid.toLowerCase()}`, 'info');
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight })
    }
    render() {
        return (
            <div className="registrationOuterWrapper">
                <ErrorBoundary>
                    {this.state.userExists ?
                        <>
                            <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                            {this.state.width >= 600 && <div className="header-wrapper">
                                <Header />
                            </div>}
                            <div className="registrationWrapper">
                                {/* <span style={{float:"left",padding:"2% 2%",width:"13%"}}>
                        <img className="registrationLogo" src={modLogo} alt="iMedical Search"/> 
                    </span> */}
                                <div className="registrationInnerWrapper">
                                    <div className="welcomePageTitle">
                                    </div>
                                    <div className="functionSelectContent">
                                        <div className="functionTitle" style={{ fontSize: "20px", color: "#333" }}><img src={userRole} alt="userRole" /><strong className="russian-user-heading">My Role is:</strong></div>
                                        <div style={{ padding: "2% 0 2% 0" }}>
                                            <p>
                                                <input type="radio" className="" name="profMedical" value="Medical" onChange={this.handleOnChange} checked={this.state.profession === 'Medical'} class="k-radio" />
                                                <span className="" style={{ margin: "0px 1px 0px 10px", verticalAlign: "middle" }}><strong>Medical/Scientific</strong></span><br />
                                                <span className="functionTabChoiceTxt">Please select if you have a medical or scientific role (e.g., Scientific and Medical Affairs, Regulatory, etc.).</span><br />

                                                <input type="radio" className="" name="profCommercial" value="Commercial" onChange={this.handleOnChange} checked={this.state.profession === 'Commercial'} class="k-radio" />
                                                <span className="" style={{ margin: "0px 1px 0px 10px", verticalAlign: "middle" }}><strong>Commercial</strong></span><br />
                                                <span className="functionTabChoiceTxt">Please select if your role is commercial (e.g., Marketing, Sales, etc.).</span><br />

                                                {/* <input type="radio" className="" name="profR&D" value="R&D" onChange={this.handleOnChange} checked={this.state.profession === 'R&D'} class="k-radio" />
                                                <span className="" style={{ margin: "0px 1px 0px 10px", verticalAlign: "middle" }}><strong>R&D</strong></span><br />
                                                <span className="functionTabChoiceTxt">Please select if you are from R&D.</span> */}
                                            </p>
                                            {this.state.displayError ? <div >
                                                <p className="errorLabel">Please select a role to continue.</p>
                                            </div> : null}
                                        </div>
                                        {
                                            this.state.width >= 600 ?
                                                <div className="buttonContainer">
                                                    <div className="welcomeNxtBtn">
                                                        {/* <input type="submit" value="Submit" onClick={this.handleSubmit}></input> */}
                                                        <Button text='Submit' customColor='#2EB000' action={this.handleSubmit} />
                                                    </div>
                                                    <div className="welcomeNxtBtn">
                                                        {/* <input type="submit" value="Cancel" onClick={()=>{this.props.history.push('/')}}></input> */}
                                                        <Button text="Cancel" customColor='#0074B0' action={() => { this.props.history.push('/') }} />
                                                    </div>
                                                </div>
                                                :
                                                <div className="buttonContainer">
                                                    <div className="welcomeNxtBtn-mobile">
                                                        {/* <input type="submit" value="Submit" onClick={this.handleSubmit}></input> */}
                                                        <MobileViewButton text='Submit' customColor='#2EB000' action={this.handleSubmit} />
                                                    </div>
                                                    <div className="welcomeNxtBtn-mobile">
                                                        {/* <input type="submit" value="Cancel" onClick={()=>{this.props.history.push('/')}}></input> */}
                                                        <MobileViewButton text="Cancel" customColor='#0074B0' action={() => { this.props.history.push('/') }} />
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div style={{ margin: "0% 1%" }}>
                                <Footer customWidth={this.state.width} customHeight={this.state.height} />
                            </div></>
                        : <div style={{ textAlign: "center", padding: "10%" }}>
                            <img src={imsLoader} alt="Loading Data" style={{ width: "20%" }} />
                        </div>}
                </ErrorBoundary>
            </div>
        )
    }
}
export default RegisterRole;