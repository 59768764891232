/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/HeaderWithBanner';
import Welcome from '../RegistrationTabs/WelcomeScreen';
import LocationSelection from '../RegistrationTabs/LocationSelection';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import Functions from '../RegistrationTabs/Functions';
import GDPRAcceptance from '../RegistrationTabs/GDPRAcceptance';
import ProductPreference from '../RegistrationTabs/ProductPreference';
import DocumentTypes from '../RegistrationTabs/DocumentTypes';
import ReviewModal from '../RegistrationTabs/ReviewModal';
import ApiServices from '../../utils/apiServices';
import { imsLoader, lineBetweenUnvisitedTabs, lineBetweenVisitedTabs, ActionsArrowIcon } from '../../assets';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import Services from '../../utils/services';
import NavigationMenu from '../../components/Navigation/NavigationMenu';
import './Registration.css'
import AlertMessagePopUpModal from '../../components/PopUp/AlertMessagePopUpModal';
class Registration extends Component {
  userExists;
  russianUser;
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      userExists: false,
      tabDisabled: false,
      isWelcome: true,
      wlcVisited: false,
      islocation: false,
      locVisited: false,
      isfunction: false,
      functionVisited: false,
      isProduct: false,
      productVisited: false,
      isDocument: false,
      documentVisited: false,
      isReview: false,
      isReviewVisited: false,
      isGDPRrequired: false,
      selectedTab: '0',
      setSelectedTab: '0',
      selectedRegion: [],
      selectedCountry: [],
      gdprAcceptance: 'Yes',
      profession: '',
      products: [],
      selectedClasses: [],
      selectedAreas: [],
      contentPreference: 'Yes',
      documentPreference: [],
      areaId: [],
      classId: [],
      productId: [],
      reviewStatus: '',
      wwid: 0,
      showModal: false,
      history: this.props.history,
      width: window.innerWidth,
      height: window.innerHeight,
      showRegDropdown: false,
      alertMessageModelColor: "",
      alertMessageContent: "",
      alertMessageShowModal: false,
    };
  }
  async componentDidMount() {
    let name = localStorage.getItem('userdisplayName');
    if (name) {
      name = name.replaceAll('"', '');
    }
    /* let lastName = name.split(' ').pop();
    let firstName = name.substring(0,name.lastIndexOf(' ')+1); */
    document.title = `${name} - User Details - iMedical Search`;
    this.userExists = localStorage.getItem('userExists');
    this.russianUser = localStorage.getItem('russianuser');
    //var mail = JSON.parse(localStorage.getItem('username')).toLowerCase();
    var wwid = JSON.parse(localStorage.getItem('wwid')).toLowerCase();
    const services = new ApiServices();
    let login = new Services();
    login.logService(`Registration page successfully mounted for ${wwid}`, 'info');
    //console.log(this.russianUser)
    if (this.russianUser === 'true') {
      //console.log(this.russianUser)
      this.props.history.push('/register-role')
    }
    else {
      if (this.props.location.state) {
        if (this.props.location.state.fromDisclaimer) {
          this.setState({
            userExists: true,
            username: this.userid
          })
        }
      }
      else {
        if (this.userExists) {
          var response = await services.getRegistrationDetails(wwid);
          var res = await services.getAllTAreas()
          if (response) {
            //console.log(response)
            var countries, taAreas, taIDs, drugClasses, classIds, prds, prdIds, docTypes, funcRole
            if (response.countries) {
              countries = response.countries.split(';')
            }
            else {
              countries = this.state.selectedCountry
            }
            if (response.therapeutic_areas) {
              taAreas = response.therapeutic_areas.split(';')
            }
            else {
              taAreas = this.state.selectedAreas
            }
            if (response.taids) {
              taIDs = response.taids.split(',')
            }
            else {
              taIDs = this.state.areaId
            }
            if (response.drug_classes) {
              drugClasses = response.drug_classes.split(';')
            }
            else {
              drugClasses = this.state.selectedClasses
            }
            if (response.classids) {
              classIds = response.classids.split(',')
            }
            else {
              classIds = this.state.classId
            }
            if (response.products) {
              prds = response.products.split(';')
            }
            else {
              prds = this.state.products
            }
            if (response.productids) {
              prdIds = response.productids.split(',')
            }
            else {
              prdIds = this.state.productId
            }
            if (response.documenttypes) {
              docTypes = response.documenttypes.split(';')
            }
            else {
              docTypes = this.state.documentPreference
            }
            if (response.status === "Rejected") {
              funcRole = JSON.parse(localStorage.getItem('function'))
            }
            else {
              funcRole = response.profession
            }
            this.setState({
              selectedRegion: response.regions.split(';'),
              selectedCountry: countries,
              profession: funcRole,
              gdprAcceptance: response.datatracking,
              selectedAreas: taAreas,
              areaId: taIDs,
              selectedClasses: drugClasses,
              classId: classIds,
              productId: prdIds,
              products: prds,
              contentPreference: response.contentprefernce,
              documentPreference: docTypes,
              reviewStatus: response.status,
              wwid: response.wwid
            }, () => {
            })
            localStorage.setItem('region', response.regions);
            localStorage.setItem('userproducts', response.products);
            localStorage.setItem('doctypepref', response.documenttypes);
            localStorage.setItem('function', JSON.stringify(response.profession));
            localStorage.setItem('russianuser', JSON.stringify(response.russianuser));
            localStorage.setItem('contentpreference', JSON.stringify(response.contentprefernce));
            var _response = await services.getUserDetails(wwid);
            if (_response.accessgroup) {
              localStorage.setItem('accessgroup', JSON.stringify(_response.accessgroup));
            } else {
              localStorage.setItem('accessgroup', JSON.stringify(''));
            }
          }
          //console.log(this.state.profession)
          this.setState({
            userExists: this.userExists
          }, () => {
            if (this.props.location.query) {
              if (this.props.location.query.toProducts) {
                this.setState({
                  selectedTab: 3
                }, this.handleFuncNxt(this.state.selectedRegion, this.state.selectedCountry, this.state.gdprAcceptance, this.state.profession, this.state.products, this.state.contentPreference, this.state.documentPreference, this.state.selectedAreas, this.state.selectedClasses, this.state.history, this.state.areaId, this.state.classId, this.state.productId))
              }
              //console.log(this.props.location.query.toProducts)
              else {
                this.handleWlcContinue(this.state.selectedRegion, this.state.selectedCountry, this.state.history, this.state.gdprAcceptance, this.state.profession, this.state.products, this.state.contentPreference, this.state.documentPreference, this.state.selectedAreas, this.state.selectedClasses, this.state.areaId, this.state.classId, this.state.productId)
              }
            }
            else {
              this.handleWlcContinue(this.state.selectedRegion, this.state.selectedCountry, this.state.history, this.state.gdprAcceptance, this.state.profession, this.state.products, this.state.contentPreference, this.state.documentPreference, this.state.selectedAreas, this.state.selectedClasses, this.state.areaId, this.state.classId, this.state.productId)
            }
          })
          let newtaIDs = [], newtaAreas = [];
          for (let index = 0; index < res.length; index++) {
            const element = res[index];
            if (/* this.findArrindex(taIDs, `${element.taid}`) !== -1 || */ this.findArrindex(classIds, `${element.taid}T`) !== -1 || this.findArrindex(prdIds, `${element.taid}T`) !== -1) {
              newtaIDs.push(element.taid.toString())
              newtaAreas.push(element.taname)
            }
          }
          this.setState({
            selectedAreas: newtaAreas,
            areaId: newtaIDs,
          })
          window.addEventListener('resize', this.updateDimensions);
        }
        else {
          console.log('Access Denied');
          this.props.history.push('/');
        }
      }
    }

    //console.log(this.props.history);
  }
  findArrindex = (array, substring) => array.findIndex(element => {
    if (element.includes(substring)) {
      return true;
    }
  });
  handleWlcContinue = (selectedRegion, selectedCountry, history, gdprAcceptance, profession, products, contentPreference, documentPreference, selectedAreas, selectedClasses, areaId, classId, productId) => {
    this.setState({
      selectedRegion: selectedRegion,
      selectedCountry: selectedCountry,
      gdprAcceptance: gdprAcceptance,
      profession: profession,
      products: products,
      selectedAreas: selectedAreas,
      selectedClasses: selectedClasses,
      contentPreference: contentPreference,
      documentPreference: documentPreference,
      areaId: areaId,
      classId: classId,
      productId: productId,
      isWelcome: false,
      wlcVisited: true,
      islocation: true,
      locVisited: false,
      isfunction: false,
      functionVisited: false,
      isProduct: false,
      productVisited: false,
      isDocument: false,
      documentVisited: false,
      isReview: false,
      isReviewVisited: false,
      isGDPRrequired: false,
      history: history,
      selectedTab: (this.state.selectedTab + 1) % 6
    })
  }
  handleLocPrevious = (selectedRegion, selectedCountry) => {
    this.setState({
      selectedRegion: selectedRegion,
      selectedCountry: selectedCountry,
      gdprAcceptance: 'Yes',
      profession: '',
      products: [],
      contentPreference: 'Yes',
      documentPreference: [],
      areaId: [],
      classId: [],
      productId: [],
      isWelcome: false,
      wlcVisited: true,
      islocation: true,
      locVisited: false,
      isfunction: false,
      functionVisited: false,
      isProduct: false,
      productVisited: false,
      isDocument: false,
      documentVisited: false,
      isReview: false,
      isReviewVisited: false,
      isGDPRrequired: false
      //selectedTab:(this.state.selectedTab+6-1)%6
    })
  }
  handleLocNxt = (selectedRegion, selectedCountry, gdprAcceptance, profession, products, contentPreference, documentPreference, selectedAreas, selectedClasses, history, areaId, classId, productId) => {
    this.setState({
      selectedRegion: selectedRegion,
      selectedCountry: selectedCountry,
      gdprAcceptance: gdprAcceptance,
      profession: profession,
      products: products,
      selectedAreas: selectedAreas,
      selectedClasses: selectedClasses,
      contentPreference: contentPreference,
      documentPreference: documentPreference,
      areaId: areaId,
      classId: classId,
      productId: productId,
      isWelcome: false,
      wlcVisited: true,
      islocation: false,
      locVisited: true,
      isfunction: true,
      functionVisited: false,
      isProduct: false,
      productVisited: false,
      isDocument: false,
      documentVisited: false,
      isReview: false,
      isReviewVisited: false,
      isGDPRrequired: false,
      history: history,
      selectedTab: (this.state.selectedTab + 1) % 6
    })
  }
  handleRegionSelect = (selectedRegion, selectedCountry, gdprAcceptance, profession, products, contentPreference, documentPreference, selectedAreas, selectedClasses, history, areaId, classId, productId) => {
    this.setState({
      selectedRegion: selectedRegion,
      selectedCountry: selectedCountry,
      gdprAcceptance: gdprAcceptance,
      profession: profession,
      products: products,
      selectedAreas: selectedAreas,
      selectedClasses: selectedClasses,
      contentPreference: contentPreference,
      documentPreference: documentPreference,
      areaId: areaId,
      classId: classId,
      productId: productId,
      isWelcome: false,
      wlcVisited: true,
      islocation: false,
      locVisited: true,
      isfunction: true,
      functionVisited: false,
      isProduct: false,
      productVisited: false,
      isDocument: false,
      documentVisited: false,
      isReview: false,
      isReviewVisited: false,
      history: history,
      selectedTab: (this.state.selectedTab + 1) % 6
      //isGDPRrequired: true
    })
  }
  handleGDPRNxt = (selectedRegion, selectedCountry, gdprAcceptance, profession, products, contentPreference, documentPreference, selectedAreas, selectedClasses, history, areaId, classId, productId) => {
    this.setState({
      selectedRegion: selectedRegion,
      selectedCountry: selectedCountry,
      gdprAcceptance: gdprAcceptance,
      profession: profession,
      products: products,
      selectedAreas: selectedAreas,
      selectedClasses: selectedClasses,
      contentPreference: contentPreference,
      documentPreference: documentPreference,
      areaId: areaId,
      classId: classId,
      productId: productId,
      isWelcome: false,
      wlcVisited: true,
      islocation: false,
      locVisited: true,
      isfunction: true,
      functionVisited: false,
      isGDPRrequired: false,
      isProduct: false,
      productVisited: false,
      isDocument: false,
      documentVisited: false,
      isReview: false,
      isReviewVisited: false,
      history: history,
      selectedTab: (this.state.selectedTab + 1) % 6
    })
  }
  handleGDPRPrev = (selectedRegion, selectedCountry, gdprAcceptance, profession, products, contentPreference, documentPreference, selectedAreas, selectedClasses, history, areaId, classId, productId) => {
    this.setState({
      selectedRegion: selectedRegion,
      selectedCountry: selectedCountry,
      gdprAcceptance: gdprAcceptance,
      profession: profession,
      products: products,
      selectedAreas: selectedAreas,
      selectedClasses: selectedClasses,
      contentPreference: contentPreference,
      documentPreference: documentPreference,
      areaId: areaId,
      classId: classId,
      productId: productId,
      isWelcome: this.state.width < 600 ? false : true,
      wlcVisited: true,
      islocation: true,
      locVisited: false,
      isfunction: false,
      functionVisited: false,
      isProduct: false,
      productVisited: false,
      isDocument: false,
      documentVisited: false,
      isReview: false,
      isReviewVisited: false,
      isGDPRrequired: false,
      history: history,
    })
  }
  handleFuncPrevious = (selectedRegion, selectedCountry, gdprAcceptance, profession, products, contentPreference, documentPreference, selectedAreas, selectedClasses, history, areaId, classId, productId) => {
    this.setState({
      selectedRegion: selectedRegion,
      selectedCountry: selectedCountry,
      gdprAcceptance: gdprAcceptance,
      profession: profession,
      products: products,
      selectedAreas: selectedAreas,
      selectedClasses: selectedClasses,
      contentPreference: contentPreference,
      documentPreference: documentPreference,
      areaId: areaId,
      classId: classId,
      productId: productId,
      isWelcome: false,
      wlcVisited: true,
      islocation: true,
      locVisited: false,
      isfunction: false,
      functionVisited: false,
      isProduct: false,
      productVisited: false,
      isDocument: false,
      documentVisited: false,
      isReview: false,
      isReviewVisited: false,
      isGDPRrequired: selectedRegion.includes('EMEA') || selectedRegion.includes('EMEA - MEWA, Israel & CIS') ? true : false,
      history: history,
      selectedTab: (this.state.selectedTab + 6 - 1) % 6
    });
  }
  handleFuncNxt = (selectedRegion, selectedCountry, gdprAcceptance, profession, products, contentPreference, documentPreference, selectedAreas, selectedClasses, history, areaId, classId, productId) => {
    this.setState({
      selectedRegion: selectedRegion,
      selectedCountry: selectedCountry,
      gdprAcceptance: gdprAcceptance,
      profession: profession,
      products: products,
      selectedAreas: selectedAreas,
      selectedClasses: selectedClasses,
      contentPreference: contentPreference,
      documentPreference: documentPreference,
      areaId: areaId,
      classId: classId,
      productId: productId,
      isWelcome: false,
      wlcVisited: true,
      islocation: false,
      locVisited: true,
      isfunction: false,
      functionVisited: true,
      isProduct: true,
      productVisited: false,
      isDocument: false,
      documentVisited: false,
      isReview: false,
      isReviewVisited: false,
      isGDPRrequired: false,
      history: history,
      selectedTab: (this.state.selectedTab + 1) % 6
    });
  }
  handleProductPrevious = (selectedRegion, selectedCountry, gdprAcceptance, profession, products, contentPreference, documentPreference, selectedAreas, selectedClasses, history, areaId, classId, productId) => {
    this.setState({
      selectedRegion: selectedRegion,
      selectedCountry: selectedCountry,
      gdprAcceptance: gdprAcceptance,
      profession: profession,
      products: products,
      selectedAreas: selectedAreas,
      selectedClasses: selectedClasses,
      contentPreference: contentPreference,
      documentPreference: documentPreference,
      areaId: areaId,
      classId: classId,
      productId: productId,
      isWelcome: false,
      wlcVisited: true,
      islocation: false,
      locVisited: true,
      isfunction: true,
      functionVisited: false,
      isProduct: false,
      productVisited: false,
      isDocument: false,
      documentVisited: false,
      isReview: false,
      isReviewVisited: false,
      isGDPRrequired: false,
      history: history,
      selectedTab: (this.state.selectedTab + 6 - 1) % 6
    });
  }
  handleProductNxt = (selectedRegion, selectedCountry, gdprAcceptance, profession, products, contentPreference, documentPreference, selectedAreas, selectedClasses, history, areaId, classId, productId) => {
    this.setState({
      selectedRegion: selectedRegion,
      selectedCountry: selectedCountry,
      gdprAcceptance: gdprAcceptance,
      profession: profession,
      products: products,
      selectedAreas: selectedAreas,
      selectedClasses: selectedClasses,
      contentPreference: contentPreference,
      documentPreference: documentPreference,
      areaId: areaId,
      classId: classId,
      productId: productId,
      isWelcome: false,
      wlcVisited: true,
      islocation: false,
      locVisited: true,
      isfunction: false,
      functionVisited: true,
      isProduct: false,
      productVisited: true,
      isDocument: true,
      documentVisited: false,
      isReview: false,
      isReviewVisited: false,
      isGDPRrequired: false,
      history: history,
      selectedTab: (this.state.selectedTab + 1) % 6
    });
  }
  handleDocPrevious = (selectedRegion, selectedCountry, gdprAcceptance, profession, products, contentPreference, documentPreference, selectedAreas, selectedClasses, history, areaId, classId, productId) => {
    this.setState({
      selectedRegion: selectedRegion,
      selectedCountry: selectedCountry,
      gdprAcceptance: gdprAcceptance,
      profession: profession,
      products: products,
      selectedAreas: selectedAreas,
      selectedClasses: selectedClasses,
      contentPreference: contentPreference,
      documentPreference: documentPreference,
      areaId: areaId,
      classId: classId,
      productId: productId,
      isWelcome: false,
      wlcVisited: true,
      islocation: false,
      locVisited: true,
      isfunction: false,
      functionVisited: true,
      isProduct: true,
      productVisited: false,
      isDocument: false,
      documentVisited: false,
      isReview: false,
      isReviewVisited: false,
      isGDPRrequired: false,
      history: history,
      selectedTab: (this.state.selectedTab + 6 - 1) % 6
    });
  }
  handleDocNxt = (selectedRegion, selectedCountry, gdprAcceptance, profession, products, contentPreference, documentPreference, selectedAreas, selectedClasses, showModal, history, areaId, classId, productId) => {
    this.setState({
      selectedRegion: selectedRegion,
      selectedCountry: selectedCountry,
      gdprAcceptance: gdprAcceptance,
      profession: profession,
      products: products,
      selectedAreas: selectedAreas,
      selectedClasses: selectedClasses,
      contentPreference: contentPreference,
      documentPreference: documentPreference,
      areaId: areaId,
      classId: classId,
      productId: productId,
      showModal: showModal,
      isWelcome: false,
      wlcVisited: true,
      islocation: false,
      locVisited: true,
      isfunction: false,
      functionVisited: true,
      isProduct: false,
      productVisited: true,
      isDocument: false,
      documentVisited: true,
      isReview: true,
      isReviewVisited: false,
      isGDPRrequired: false,
      history: history,
      selectedTab: (this.state.selectedTab + 1) % 6
    });
  }
  handleBckFromReview = (selectedRegion, selectedCountry, gdprAcceptance, profession, products, contentPreference, documentPreference, selectedAreas, selectedClasses, history, areaId, classId, productId) => {
    this.setState({
      selectedRegion: selectedRegion,
      selectedCountry: selectedCountry,
      gdprAcceptance: gdprAcceptance,
      profession: profession,
      products: products,
      selectedAreas: selectedAreas,
      selectedClasses: selectedClasses,
      contentPreference: contentPreference,
      documentPreference: documentPreference,
      areaId: areaId,
      classId: classId,
      productId: productId,
      isWelcome: false,
      wlcVisited: true,
      islocation: false,
      locVisited: true,
      isfunction: false,
      functionVisited: true,
      isProduct: false,
      productVisited: true,
      isDocument: true,
      documentVisited: false,
      isReview: false,
      isReviewVisited: false,
      isGDPRrequired: false,
      history: history,
      selectedTab: (this.state.selectedTab + 6 - 1) % 6
    })
  }
  handleOnClick = (evt) => {
    var tabId = evt.target.id
    var activeTabId = document.querySelector(`[aria-selected="true"]`).id
    var diff = activeTabId.split('-')[0] - tabId.split('-')[0]
    if (diff > 0) {
      //console.log('Tab movement backwards')
      if (diff === 1) {
        //console.log(tabId)
        if (tabId === '1-tab') {
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: true,
            locVisited: false,
            isfunction: false,
            functionVisited: false,
            isProduct: false,
            productVisited: false,
            isDocument: false,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false, selectedTab: 1
          })
        }
        if (tabId === '2-tab') {
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: true,
            functionVisited: false,
            isProduct: false,
            productVisited: false,
            isDocument: false,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 2
          })
        }
        if (tabId === '3-tab') {
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: true,
            productVisited: false,
            isDocument: false,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 3
          })
        }
        if (tabId === '4-tab') {
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: false,
            productVisited: true,
            isDocument: true,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 4
          })
        }
        if (tabId === '5-tab') {
          this.setState({
            showModal: true,
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: false,
            productVisited: true,
            isDocument: false,
            documentVisited: true,
            isReview: true,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 5
          })
        }
      }
      if (diff === 2) {
        //console.log(tabId);
        if (tabId === '1-tab') {
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: true,
            locVisited: false,
            isfunction: false,
            functionVisited: false,
            isProduct: false,
            productVisited: false,
            isDocument: false,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 1
          })
        }
        if (tabId === '2-tab') {
          //console.log('I am in function tab');
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: true,
            functionVisited: false,
            isProduct: false,
            productVisited: false,
            isDocument: false,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 2
          })
        }
        if (tabId === '3-tab') {
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: true,
            productVisited: false,
            isDocument: false,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 3
          })
        }
        if (tabId === '4-tab') {
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: false,
            productVisited: true,
            isDocument: true,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 4
          })
        }
        if (tabId === '5-tab') {
          this.setState({
            showModal: true,
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: false,
            productVisited: true,
            isDocument: false,
            documentVisited: true,
            isReview: true,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 5
          })
        }
      }
      if (diff === 3) {
        //console.log(tabId);
        if (tabId === '1-tab') {
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: true,
            locVisited: false,
            isfunction: false,
            functionVisited: false,
            isProduct: false,
            productVisited: false,
            isDocument: false,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 1
          })
        }
        if (tabId === '2-tab') {
          //console.log('I am in function tab');
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: true,
            functionVisited: false,
            isProduct: false,
            productVisited: false,
            isDocument: false,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 2
          })
        }
        if (tabId === '3-tab') {
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: true,
            productVisited: false,
            isDocument: false,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 3
          })
        }
        if (tabId === '4-tab') {
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: false,
            productVisited: true,
            isDocument: true,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 4
          })
        }
        if (tabId === '5-tab') {
          this.setState({
            showModal: true,
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: false,
            productVisited: true,
            isDocument: false,
            documentVisited: true,
            isReview: true,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 5
          })
        }
      }
      if (diff === 4) {
        //console.log(tabId);
        if (tabId === '1-tab') {
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: true,
            locVisited: false,
            isfunction: false,
            functionVisited: false,
            isProduct: false,
            productVisited: false,
            isDocument: false,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 1
          })
        }
        if (tabId === '2-tab') {
          //console.log('I am in function tab');
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: true,
            functionVisited: false,
            isProduct: false,
            productVisited: false,
            isDocument: false,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 2
          })
        }
        if (tabId === '3-tab') {
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: true,
            productVisited: false,
            isDocument: false,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 3
          })
        }
        if (tabId === '4-tab') {
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: false,
            productVisited: true,
            isDocument: true,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 4
          })
        }
        if (tabId === '5-tab') {
          this.setState({
            showModal: true,
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: false,
            productVisited: true,
            isDocument: false,
            documentVisited: true,
            isReview: true,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 5
          })
        }
      }
    }
    else if (diff < 0) {
      //console.log('Tab movement forward')
      if (diff === -1) {
        if (tabId === '1-tab') {
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: true,
            locVisited: false,
            isfunction: false,
            functionVisited: false,
            isProduct: false,
            productVisited: false,
            isDocument: false,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 1
          })
        }
        if (tabId === '2-tab') {
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: true,
            functionVisited: false,
            isProduct: false,
            productVisited: false,
            isDocument: false,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 2
          })
        }
        if (tabId === '3-tab') {
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: true,
            productVisited: false,
            isDocument: false,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 3
          })
        }
        if (tabId === '4-tab') {
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: false,
            productVisited: true,
            isDocument: true,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 4
          })
        }
        if (tabId === '5-tab') {
          //console.log('From Document to Review')
          this.setState({
            showModal: true,
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: false,
            productVisited: true,
            isDocument: false,
            documentVisited: true,
            isReview: true,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 5
          })
        }
      }
      if (diff === -2) {
        //console.log(tabId);
        if (tabId === '2-tab') {
          //console.log('I am in function tab');
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: true,
            functionVisited: false,
            isProduct: false,
            productVisited: false,
            isDocument: false,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 2
          })
        }
        if (tabId === '3-tab') {
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: true,
            productVisited: false,
            isDocument: false,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 3
          })
        }
        if (tabId === '4-tab') {
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: false,
            productVisited: true,
            isDocument: true,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 4
          })
        }
        if (tabId === '5-tab') {
          this.setState({
            showModal: true,
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: false,
            productVisited: true,
            isDocument: false,
            documentVisited: true,
            isReview: true,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 5
          })
        }
      }
      if (diff === -3) {
        //console.log('Need to jump from welcome to products')
        if (tabId === '3-tab') {
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: true,
            productVisited: false,
            isDocument: false,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 3
          })
        }
        if (tabId === '4-tab') {
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: false,
            productVisited: true,
            isDocument: true,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 4
          })
        }
        if (tabId === '5-tab') {
          this.setState({
            showModal: true,
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: false,
            productVisited: true,
            isDocument: false,
            documentVisited: true,
            isReview: true,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 5
          })
        }
      }
      if (diff === -4) {
        if (tabId === '4-tab') {
          this.setState({
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: false,
            productVisited: true,
            isDocument: true,
            documentVisited: false,
            isReview: false,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 4
          })
        }
        if (tabId === '5-tab') {
          //console.log('From Location to Review')
          this.setState({
            showModal: true,
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: false,
            productVisited: true,
            isDocument: false,
            documentVisited: true,
            isReview: true,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 5
          })
        }
      }
      if (diff === -5) {
        if (tabId === '5-tab') {
          this.setState({
            showModal: true,
            isWelcome: false,
            wlcVisited: true,
            islocation: false,
            locVisited: true,
            isfunction: false,
            functionVisited: true,
            isProduct: false,
            productVisited: true,
            isDocument: false,
            documentVisited: true,
            isReview: true,
            isReviewVisited: false,
            isGDPRrequired: false,
            selectedTab: 5
          })
        }
      }
    }
    else {
      if (tabId === '1-tab') {
        this.setState({
          isWelcome: false,
          wlcVisited: true,
          islocation: true,
          locVisited: false,
          isfunction: false,
          functionVisited: false,
          isProduct: false,
          productVisited: false,
          isDocument: false,
          documentVisited: false,
          isReview: false,
          isReviewVisited: false,
          isGDPRrequired: false,
          selectedTab: 1
        })
      }
    }
  }
  handleChangeInRegion = (regions, country) => {
    //console.log(regions,country)
    this.setState({
      selectedRegion: regions,
      selectedCountry: country
    })
  }
  handleChangeGdpr = (gdpr) => {
    this.setState({
      gdprAcceptance: gdpr
    })
  }
  handleChangeInFunc = (profession) => {
    this.setState({
      profession: profession
    })
  }
  handleChangeInProduct = (product, productId, classes, classId, area, areaId, contentPreference) => {
    this.setState({
      products: product,
      productId: productId,
      selectedClasses: classes,
      classId: classId,
      selectedAreas: area,
      areaId: areaId,
      contentPreference: contentPreference
    })
  }
  handleChangeInDocs = (docs) => {
    this.setState({
      documentPreference: docs
    })
  }
  errorAlertMessage = (message) => {
    this.setState({
      alertMessageContent: message,
      alertMessageModelColor: "#de1327",
      alertMessageShowModal: true
    })
  }
  closeAlertMessage = () => {
    this.setState({
      alertMessageContent: "",
      alertMessageModelColor: "",
      alertMessageShowModal: false
    })
  }
  componentDidUpdate() {
    if (this.state.selectedTab > 1) {
      if (this.state.selectedRegion.length <= 0) {
        document.getElementById(`${this.state.selectedTab}`).setAttribute("aria-expanded", "false");
        document.getElementById(`${this.state.selectedTab}-tab`).setAttribute("aria-selected", "false");
        document.getElementById(`1`).setAttribute("aria-expanded", "true");
        document.getElementById(`1-tab`).setAttribute("aria-selected", "true");
        this.errorAlertMessage('Please select your Region(s) to proceed.');
        this.setState({
          isWelcome: false,
          wlcVisited: true,
          islocation: true,
          locVisited: false,
          isfunction: false,
          functionVisited: false,
          isProduct: false,
          productVisited: false,
          isDocument: false,
          documentVisited: false,
          isReview: false,
          isReviewVisited: false,
          isGDPRrequired: false,
          selectedTab: 1
        })
      }
    }
    if (this.state.selectedTab > 2) {
      if (this.state.profession === '' || this.state.profession === null) {
        document.getElementById(`${this.state.selectedTab}`).setAttribute("aria-expanded", "false");
        document.getElementById(`${this.state.selectedTab}-tab`).setAttribute("aria-selected", "false");
        document.getElementById(`2`).setAttribute("aria-expanded", "true");
        document.getElementById(`2-tab`).setAttribute("aria-selected", "true");
        this.errorAlertMessage('Please select your Role to proceed.');
        this.setState({
          isWelcome: false,
          wlcVisited: true,
          islocation: false,
          locVisited: true,
          isfunction: true,
          functionVisited: false,
          isProduct: false,
          productVisited: false,
          isDocument: false,
          documentVisited: false,
          isReview: false,
          isReviewVisited: false,
          isGDPRrequired: false,
          selectedTab: 2
        })
      }
    }
    if (this.state.selectedTab !== 3) {
      if (this.state.products.length > 30) {
        document.getElementById(`${this.state.selectedTab}`).setAttribute("aria-expanded", "false");
        document.getElementById(`${this.state.selectedTab}-tab`).setAttribute("aria-selected", "false");
        document.getElementById(`3`).setAttribute("aria-expanded", "true");
        document.getElementById(`3-tab`).setAttribute("aria-selected", "true");
        this.errorAlertMessage(`You have selected ${this.state.products.length} products, Please limit your product selection within 30`);
        this.setState({
          isWelcome: false,
          wlcVisited: true,
          islocation: false,
          locVisited: true,
          isfunction: false,
          functionVisited: true,
          isProduct: true,
          productVisited: false,
          isDocument: false,
          documentVisited: false,
          isReview: false,
          isReviewVisited: false,
          isGDPRrequired: false,
          selectedTab: 3
        })
      }
    }
  }
  componentWillUnmount() {
    let login = new Services();
    window.removeEventListener('resize', this.updateDimensions);
    let userid = JSON.parse(localStorage.getItem('username'));
    login.logService(`Registration page successfully unmounted for ${userid.toLowerCase()}`, 'info');
  }
  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }
  handelRegNavItems = () => {
    this.setState({ showRegDropdown: !this.state.showRegDropdown })
  }
  handleRegDropdownClose = () => {
    this.setState({ showRegDropdown: false })
  }
  handleRegNavigation = (id) => {
    switch (id) {
      case 1: this.setState({
        isWelcome: false,
        wlcVisited: true,
        islocation: true,
        locVisited: false,
        isfunction: false,
        functionVisited: false,
        isProduct: false,
        productVisited: false,
        isDocument: false,
        documentVisited: false,
        isReview: false,
        isReviewVisited: false,
        isGDPRrequired: false,
        selectedTab: 1
      });
        break;
      case 2: this.setState({
        isWelcome: false,
        wlcVisited: true,
        islocation: false,
        locVisited: true,
        isfunction: true,
        functionVisited: false,
        isProduct: false,
        productVisited: false,
        isDocument: false,
        documentVisited: false,
        isReview: false,
        isReviewVisited: false,
        isGDPRrequired: false,
        selectedTab: 2
      });
        break;
      case 3: this.setState({
        isWelcome: false,
        wlcVisited: true,
        islocation: false,
        locVisited: true,
        isfunction: false,
        functionVisited: true,
        isProduct: true,
        productVisited: false,
        isDocument: false,
        documentVisited: false,
        isReview: false,
        isReviewVisited: false,
        isGDPRrequired: false,
        selectedTab: 3
      });
        break;
      case 4: this.setState({
        isWelcome: false,
        wlcVisited: true,
        islocation: false,
        locVisited: true,
        isfunction: false,
        functionVisited: true,
        isProduct: false,
        productVisited: true,
        isDocument: true,
        documentVisited: false,
        isReview: false,
        isReviewVisited: false,
        isGDPRrequired: false,
        selectedTab: 4
      });
        break;
      case 5: this.setState({
        showModal: true,
        isWelcome: false,
        wlcVisited: true,
        islocation: false,
        locVisited: true,
        isfunction: false,
        functionVisited: true,
        isProduct: false,
        productVisited: true,
        isDocument: false,
        documentVisited: true,
        isReview: true,
        isReviewVisited: false,
        isGDPRrequired: false,
        selectedTab: 5
      });
        break;
      default: this.setState({
        isWelcome: false,
        islocation: false,
        isfunction: false,
        isProduct: false,
        isDocument: false,
        isReview: false
      });
        break;
    }
  }
  render() {
    const { alertMessageModelColor, alertMessageContent, alertMessageShowModal } = this.state;
    if (this.state.width < 600) {
      return (
        <>
          <div className="registrationOuterWrapper">
            <ErrorBoundary>
              {this.state.userExists ? <>
                <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                <div className="registrationWrapper">

                  {/* <span style={{float:"left",padding:"2% 2%",width:"13%"}}>
                  <img className="registrationLogo" src={modLogo} alt="iMedical Search"/> 
              </span> */}
                  {/* <Tabs
                  defaultTab={this.state.selectedTab.toString()}
                  selectedindex={this.state.selectedTab}
                  onClick={this.handleOnClick}>
                  <TabList> */}
                  <div className="tabs tabwrap" onMouseLeave={this.handleRegDropdownClose}>
                    <div className="tab-list nav-tabs1">
                      <div onClick={this.handelRegNavItems} className="regNavbar" >
                        <span>{this.state.isWelcome ? "1. Welcome" : this.state.islocation || this.state.isGDPRrequired ? "2. Location" : this.state.isfunction ? "3. Function" : this.state.isProduct ? "4. Products" : this.state.isDocument ? "5. Documents" : "6. Confirm"}</span>
                        <img src={ActionsArrowIcon} alt="ActionsArrowIcon" className='quickActions-dropdownIcon-mobile' style={{ transform: this.state.showRegDropdown ? "matrix(-1, 0, 0, -1, 0, 0)" : null, top: "7px" }} onClick={this.handelRegNavItems} />
                        {
                          this.state.showRegDropdown &&
                          <ul className='regNav-dropdown'>
                            <li className={this.state.isWelcome || this.state.selectedTab === "0" ? 'regDropdown-text-active' : 'regDropdown-text'}>1. Welcome</li>
                            <hr className='reg-item-sepration' />
                            <li className={this.state.islocation || this.state.selectedTab === "1" ? 'regDropdown-text-active' : 'regDropdown-text'} onClick={() => this.handleRegNavigation(1)}>2. Location</li>
                            <hr className='reg-item-sepration' />
                            <li className={this.state.isfunction || this.state.selectedTab === "2" ? 'regDropdown-text-active' : 'regDropdown-text'} onClick={() => this.handleRegNavigation(2)}>3. Function</li>
                            <hr className='reg-item-sepration' />
                            <li className={this.state.isProduct || this.state.selectedTab === "3" ? 'regDropdown-text-active' : 'regDropdown-text'} onClick={() => this.handleRegNavigation(3)}>4. Products</li>
                            <hr className='reg-item-sepration' />
                            <li className={this.state.isDocument || this.state.selectedTab === "4" ? 'regDropdown-text-active' : 'regDropdown-text'} onClick={() => this.handleRegNavigation(4)}>5. Documents</li>
                            <hr className='reg-item-sepration' />
                            <li className={this.state.isReview || this.state.selectedTab === "5" ? 'regDropdown-text-active' : 'regDropdown-text'} onClick={() => this.handleRegNavigation(5)}>6. Confirm</li>
                          </ul>
                        }
                      </div>
                      {/* <div className="tab-wrap-inner">
                            <Tab className={!this.state.wlcVisited ? "tab-list-item tab1 Welcome" : "tab-list-item tab1 Welcome tab-list-visited"} tabFor="0" disabled>01</Tab>
                            {this.state.wlcVisited ?
                              <span className="tab-line-wrapper"><img src={lineBetweenVisitedTabs} alt="" className="tab-line-image-wrapper" /></span>
                              : <span className="tab-line-wrapper"><img src={lineBetweenUnvisitedTabs} alt="" className="tab-line-image-wrapper" /></span>
                            }
                            <Tab className={!this.state.locVisited ? "tab-list-item tab2 Location" : "tab-list-item tab2 Location tab-list-visited"} tabFor="1">02</Tab>
                            {this.state.locVisited && !this.state.isGDPRrequired ?
                              <span className="tab-line-wrapper"><img src={lineBetweenVisitedTabs} alt="" className="tab-line-image-wrapper" /></span>
                              : <span className="tab-line-wrapper"><img src={lineBetweenUnvisitedTabs} alt="" className="tab-line-image-wrapper" /></span>
                            }
                            <Tab className={!this.state.functionVisited ? "tab-list-item tab3 Function" : "tab-list-item tab3 Function tab-list-visited"} tabFor="2" >03</Tab>
                            {this.state.functionVisited ?
                              <span className="tab-line-wrapper"><img src={lineBetweenVisitedTabs} alt="" className="tab-line-image-wrapper" /></span>
                              : <span className="tab-line-wrapper"><img src={lineBetweenUnvisitedTabs} alt="" className="tab-line-image-wrapper" /></span>
                            }
                            <Tab className={!this.state.productVisited ? "tab-list-item tab4 Products" : "tab-list-item tab4 Products tab-list-visited"} tabFor="3">04</Tab>
                            {this.state.productVisited ?
                              <span className="tab-line-wrapper"><img src={lineBetweenVisitedTabs} alt="" className="tab-line-image-wrapper" /></span>
                              : <span className="tab-line-wrapper"><img src={lineBetweenUnvisitedTabs} alt="" className="tab-line-image-wrapper" /></span>
                            }
                            <Tab className={!this.state.documentVisited ? "tab-list-item tab5 Documents" : "tab-list-item tab5 Documents tab-list-visited"} tabFor="4">05</Tab>
                            {this.state.documentVisited ?
                              <span className="tab-line-wrapper"><img src={lineBetweenVisitedTabs} alt="" className="tab-line-image-wrapper" /></span>
                              : <span className="tab-line-wrapper"><img src={lineBetweenUnvisitedTabs} alt="" className="tab-line-image-wrapper" /></span>
                            }
                            <Tab className={!this.state.isReviewVisited ? "tab-list-item tab6 Review" : "tab-list-item tab6 Review tab-list-visited"} tabFor="5">06</Tab>
                          </div>
                        <div className="tab-text-wrap-inner">
                          <div className={this.state.wlcVisited || this.state.selectedTab == "0" ? "tab-list-item-text-visited" : "tab-list-item-text"} >Welcome</div>
                          <span className="tab-line-wrapper-between-text"></span>
                          <div className={this.state.locVisited || this.state.selectedTab == "1" ? "tab-list-item-text-visited" : "tab-list-item-text"} style={{ paddingLeft: "3px" }} >Location</div>
                          <span className="tab-line-wrapper-between-text"></span>
                          <div className={this.state.functionVisited || this.state.selectedTab == "2" ? "tab-list-item-text-visited" : "tab-list-item-text"} style={{ paddingLeft: "14px" }}>Function</div>
                          <span className="tab-line-wrapper-between-text"></span>
                          <div className={this.state.productVisited || this.state.selectedTab == "3" ? "tab-list-item-text-visited" : "tab-list-item-text"} style={{ paddingLeft: "11px" }} >Products</div>
                          <span className="tab-line-wrapper-between-text"></span>
                          <div className={this.state.documentVisited || this.state.selectedTab == "4" ? "tab-list-item-text-visited" : "tab-list-item-text"} style={{ paddingLeft: "2px" }}>Documents</div>
                          <span className="tab-line-wrapper-between-text"></span>
                          <div className={this.state.isReviewVisited || this.state.selectedTab == "5" ? "tab-list-item-text-visited" : "tab-list-item-text"}  >  Confirm</div>
                        </div> */}
                    </div>

                  </div>
                  {/* </TabList> */}
                  {/* <TabPanel tabId="0"> */}
                  {this.state.isWelcome ?
                    <Welcome onClickContinueToLocation={this.handleWlcContinue}
                      selectedRegion={this.state.selectedRegion} selectedCountry={this.state.selectedCountry}
                      gdprAcceptance={this.state.gdprAcceptance} profession={this.state.profession}
                      products={this.state.products} contentPreference={this.state.contentPreference}
                      documentPreference={this.state.documentPreference}
                      selectedAreas={this.state.selectedAreas}
                      selectedClasses={this.state.selectedClasses}
                      history={this.state.history}
                      areaId={this.state.areaId}
                      classId={this.state.classId}
                      productId={this.state.productId} customWidth={this.state.width} customHeight={this.state.height} /> : null}
                  {/* </TabPanel> */}
                  {/* <TabPanel tabId="1"> */}
                  {this.state.islocation ?
                    <LocationSelection onClickPreviousToWlc={this.handleLocPrevious}
                      onClickNextToFunc={this.handleLocNxt} onSelectRegionEmea={this.handleRegionSelect} selectedRegion={this.state.selectedRegion} selectedCountry={this.state.selectedCountry}
                      gdprAcceptance={this.state.gdprAcceptance} profession={this.state.profession}
                      products={this.state.products} contentPreference={this.state.contentPreference}
                      documentPreference={this.state.documentPreference}
                      selectedAreas={this.state.selectedAreas}
                      selectedClasses={this.state.selectedClasses}
                      history={this.state.history}
                      areaId={this.state.areaId}
                      classId={this.state.classId}
                      productId={this.state.productId} onChangeinRegion={this.handleChangeInRegion} customWidth={this.state.width} customHeight={this.state.height} /> :
                    <>
                      {this.state.isGDPRrequired &&
                        <>{/*<GDPRAcceptance onClickGDPRNext={this.handleGDPRNxt} onClickGDPRPrev={this.handleGDPRPrev} selectedRegion={this.state.selectedRegion} selectedCountry={this.state.selectedCountry} gdprAcceptance={this.state.gdprAcceptance} profession={this.state.profession}
                          products={this.state.products}
                          contentPreference={this.state.contentPreference}
                          documentPreference={this.state.documentPreference}
                          selectedAreas={this.state.selectedAreas}
                          selectedClasses={this.state.selectedClasses}
                          history={this.state.history}
                          areaId={this.state.areaId}
                          classId={this.state.classId}
                          productId={this.state.productId} onChangeGdpr={this.handleChangeGdpr} customWidth={this.state.width} customHeight={this.state.height} />
                        */}</>}</>
                  }
                  {/* </TabPanel>
                  <TabPanel tabId="2"> */}
                  {this.state.isfunction ?
                    <Functions onClickPreviousToLoc={this.handleFuncPrevious}
                      onClickNextOfFunc={this.handleFuncNxt} selectedRegion={this.state.selectedRegion} selectedCountry={this.state.selectedCountry}
                      gdprAcceptance={this.state.gdprAcceptance}
                      profession={this.state.profession}
                      products={this.state.products}
                      contentPreference={this.state.contentPreference}
                      documentPreference={this.state.documentPreference}
                      selectedAreas={this.state.selectedAreas}
                      selectedClasses={this.state.selectedClasses}
                      history={this.state.history}
                      areaId={this.state.areaId}
                      classId={this.state.classId}
                      productId={this.state.productId} onChangeInFunc={this.handleChangeInFunc} customWidth={this.state.width} customHeight={this.state.height} /> : null}

                  {/* </TabPanel>
                  <TabPanel tabId="3"> */}
                  {this.state.isProduct ?
                    <ProductPreference onClickPreviousToFunc={this.handleProductPrevious}
                      onClickNextOfDocument={this.handleProductNxt} selectedRegion={this.state.selectedRegion} selectedCountry={this.state.selectedCountry}
                      gdprAcceptance={this.state.gdprAcceptance}
                      profession={this.state.profession}
                      products={this.state.products}
                      contentPreference={this.state.contentPreference}
                      documentPreference={this.state.documentPreference}
                      selectedAreas={this.state.selectedAreas}
                      selectedClasses={this.state.selectedClasses}
                      history={this.state.history}
                      areaId={this.state.areaId}
                      classId={this.state.classId}
                      productId={this.state.productId} onChangeInProducts={this.handleChangeInProduct} customWidth={this.state.width} customHeight={this.state.height} /> : null}
                  {/* </TabPanel>
                  <TabPanel tabId="4"> */}
                  {this.state.isDocument ?
                    <DocumentTypes onClickPreviousToProducts={this.handleDocPrevious}
                      onClickNextToReview={this.handleDocNxt} selectedRegion={this.state.selectedRegion} selectedCountry={this.state.selectedCountry}
                      gdprAcceptance={this.state.gdprAcceptance}
                      profession={this.state.profession}
                      products={this.state.products}
                      contentPreference={this.state.contentPreference}
                      documentPreference={this.state.documentPreference}
                      selectedAreas={this.state.selectedAreas}
                      selectedClasses={this.state.selectedClasses}
                      showModal={this.state.showModal}
                      history={this.state.history}
                      areaId={this.state.areaId}
                      classId={this.state.classId}
                      productId={this.state.productId} onChangeInDocPref={this.handleChangeInDocs} customWidth={this.state.width} customHeight={this.state.height} /> : null
                  }
                  {/* </TabPanel>
                  <TabPanel tabId="5"> */}
                  {this.state.isReview ?
                    <ReviewModal
                      onBackFrmReview={this.handleBckFromReview}
                      selectedRegion={this.state.selectedRegion} selectedCountry={this.state.selectedCountry}
                      gdprAcceptance={this.state.gdprAcceptance}
                      profession={this.state.profession}
                      products={this.state.products}
                      contentPreference={this.state.contentPreference}
                      documentPreference={this.state.documentPreference}
                      selectedAreas={this.state.selectedAreas}
                      selectedClasses={this.state.selectedClasses}
                      showModal={this.state.showModal}
                      history={this.state.history}
                      areaId={this.state.areaId}
                      classId={this.state.classId}
                      productId={this.state.productId}
                      reviewStatus={this.state.reviewStatus}
                      wwid={this.state.wwid}
                      customWidth={this.state.width}
                      customHeight={this.state.height}
                    /> : null}
                  {/* </TabPanel>
                </Tabs> */}

                </div>
                <div style={{ margin: "0% 1%" }}>
                  <Footer customWidth={this.state.width} customHeight={this.state.height} />
                </div>
              </> :
                <div style={{ textAlign: "center", padding: "10%" }}>
                  <img src={imsLoader} alt="Loading Data" style={{ width: "20%" }} />
                </div>
              }
            </ErrorBoundary>
          </div>
          {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
        </>
      );
    }
    else {
      return (
        <>
          <div className="registrationOuterWrapper">
            <ErrorBoundary>
              {this.state.userExists ? <>
                <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                <div>
                  <Header />
                </div>
                <div className="registrationWrapper">

                  {/* <span style={{float:"left",padding:"2% 2%",width:"13%"}}>
                <img className="registrationLogo" src={modLogo} alt="iMedical Search"/> 
            </span> */}
                  <Tabs
                    defaultTab={this.state.selectedTab.toString()}
                    selectedindex={this.state.selectedTab}
                    onClick={this.handleOnClick}>
                    <TabList>
                      <div className="tabs tabwrap">
                        <div className="tab-list nav-tabs1">
                          <div className="tab-wrap-inner">
                            <Tab className={!this.state.wlcVisited ? "tab-list-item tab1 Welcome" : "tab-list-item tab1 Welcome tab-list-visited"} tabFor="0" disabled>01</Tab>
                            {this.state.wlcVisited ?
                              <span className="tab-line-wrapper"><img src={lineBetweenVisitedTabs} alt="" className="tab-line-image-wrapper" /></span>
                              : <span className="tab-line-wrapper"><img src={lineBetweenUnvisitedTabs} alt="" className="tab-line-image-wrapper" /></span>
                            }
                            <Tab className={!this.state.locVisited ? "tab-list-item tab2 Location" : "tab-list-item tab2 Location tab-list-visited"} tabFor="1">02</Tab>
                            {this.state.locVisited && !this.state.isGDPRrequired ?
                              <span className="tab-line-wrapper"><img src={lineBetweenVisitedTabs} alt="" className="tab-line-image-wrapper" /></span>
                              : <span className="tab-line-wrapper"><img src={lineBetweenUnvisitedTabs} alt="" className="tab-line-image-wrapper" /></span>
                            }
                            <Tab className={!this.state.functionVisited ? "tab-list-item tab3 Function" : "tab-list-item tab3 Function tab-list-visited"} tabFor="2" >03</Tab>
                            {this.state.functionVisited ?
                              <span className="tab-line-wrapper"><img src={lineBetweenVisitedTabs} alt="" className="tab-line-image-wrapper" /></span>
                              : <span className="tab-line-wrapper"><img src={lineBetweenUnvisitedTabs} alt="" className="tab-line-image-wrapper" /></span>
                            }
                            <Tab className={!this.state.productVisited ? "tab-list-item tab4 Products" : "tab-list-item tab4 Products tab-list-visited"} tabFor="3">04</Tab>
                            {this.state.productVisited ?
                              <span className="tab-line-wrapper"><img src={lineBetweenVisitedTabs} alt="" className="tab-line-image-wrapper" /></span>
                              : <span className="tab-line-wrapper"><img src={lineBetweenUnvisitedTabs} alt="" className="tab-line-image-wrapper" /></span>
                            }
                            <Tab className={!this.state.documentVisited ? "tab-list-item tab5 Documents" : "tab-list-item tab5 Documents tab-list-visited"} tabFor="4">05</Tab>
                            {this.state.documentVisited ?
                              <span className="tab-line-wrapper"><img src={lineBetweenVisitedTabs} alt="" className="tab-line-image-wrapper" /></span>
                              : <span className="tab-line-wrapper"><img src={lineBetweenUnvisitedTabs} alt="" className="tab-line-image-wrapper" /></span>
                            }
                            <Tab className={!this.state.isReviewVisited ? "tab-list-item tab6 Review" : "tab-list-item tab6 Review tab-list-visited"} tabFor="5">06</Tab>
                          </div>
                          <div className="tab-text-wrap-inner">
                            <div className={this.state.wlcVisited || this.state.selectedTab === "0" ? "tab-list-item-text-visited" : "tab-list-item-text"} >Welcome</div>
                            <span className="tab-line-wrapper-between-text"></span>
                            <div className={this.state.locVisited || this.state.selectedTab === "1" ? "tab-list-item-text-visited" : "tab-list-item-text"} style={{ paddingLeft: "3px" }} >Location</div>
                            <span className="tab-line-wrapper-between-text"></span>
                            <div className={this.state.functionVisited || this.state.selectedTab === "2" ? "tab-list-item-text-visited" : "tab-list-item-text"} style={{ paddingLeft: "14px" }}>Function</div>
                            <span className="tab-line-wrapper-between-text"></span>
                            <div className={this.state.productVisited || this.state.selectedTab === "3" ? "tab-list-item-text-visited" : "tab-list-item-text"} style={{ paddingLeft: "11px" }} >Products</div>
                            <span className="tab-line-wrapper-between-text"></span>
                            <div className={this.state.documentVisited || this.state.selectedTab === "4" ? "tab-list-item-text-visited" : "tab-list-item-text"} style={{ paddingLeft: "2px" }}>Documents</div>
                            <span className="tab-line-wrapper-between-text"></span>
                            <div className={this.state.isReviewVisited || this.state.selectedTab === "5" ? "tab-list-item-text-visited" : "tab-list-item-text"}  >  Confirm</div>
                          </div>
                        </div>
                      </div>
                    </TabList>
                    <TabPanel tabId="0">
                      {this.state.isWelcome ?
                        <Welcome onClickContinueToLocation={this.handleWlcContinue}
                          selectedRegion={this.state.selectedRegion} selectedCountry={this.state.selectedCountry}
                          gdprAcceptance={this.state.gdprAcceptance} profession={this.state.profession}
                          products={this.state.products} contentPreference={this.state.contentPreference}
                          documentPreference={this.state.documentPreference}
                          selectedAreas={this.state.selectedAreas}
                          selectedClasses={this.state.selectedClasses}
                          history={this.state.history}
                          areaId={this.state.areaId}
                          classId={this.state.classId}
                          productId={this.state.productId} customWidth={this.state.width} customHeight={this.state.height} /> : null}
                    </TabPanel>
                    <TabPanel tabId="1">
                      {this.state.islocation ?
                        <LocationSelection onClickPreviousToWlc={this.handleLocPrevious}
                          onClickNextToFunc={this.handleLocNxt} onSelectRegionEmea={this.handleRegionSelect} selectedRegion={this.state.selectedRegion} selectedCountry={this.state.selectedCountry}
                          gdprAcceptance={this.state.gdprAcceptance} profession={this.state.profession}
                          products={this.state.products} contentPreference={this.state.contentPreference}
                          documentPreference={this.state.documentPreference}
                          selectedAreas={this.state.selectedAreas}
                          selectedClasses={this.state.selectedClasses}
                          history={this.state.history}
                          areaId={this.state.areaId}
                          classId={this.state.classId}
                          productId={this.state.productId} onChangeinRegion={this.handleChangeInRegion} customWidth={this.state.width} customHeight={this.state.height} /> :
                        <>
                          {this.state.isGDPRrequired &&
                            <>{/*<GDPRAcceptance onClickGDPRNext={this.handleGDPRNxt} onClickGDPRPrev={this.handleGDPRPrev} selectedRegion={this.state.selectedRegion} selectedCountry={this.state.selectedCountry} gdprAcceptance={this.state.gdprAcceptance} profession={this.state.profession}
                              products={this.state.products}
                              contentPreference={this.state.contentPreference}
                              documentPreference={this.state.documentPreference}
                              selectedAreas={this.state.selectedAreas}
                              selectedClasses={this.state.selectedClasses}
                              history={this.state.history}
                              areaId={this.state.areaId}
                              classId={this.state.classId}
                              productId={this.state.productId} onChangeGdpr={this.handleChangeGdpr} customWidth={this.state.width} customHeight={this.state.height} />
                          */}</>}</>
                      }
                    </TabPanel>
                    <TabPanel tabId="2">
                      {this.state.isfunction ?
                        <Functions onClickPreviousToLoc={this.handleFuncPrevious}
                          onClickNextOfFunc={this.handleFuncNxt} selectedRegion={this.state.selectedRegion} selectedCountry={this.state.selectedCountry}
                          gdprAcceptance={this.state.gdprAcceptance}
                          profession={this.state.profession}
                          products={this.state.products}
                          contentPreference={this.state.contentPreference}
                          documentPreference={this.state.documentPreference}
                          selectedAreas={this.state.selectedAreas}
                          selectedClasses={this.state.selectedClasses}
                          history={this.state.history}
                          areaId={this.state.areaId}
                          classId={this.state.classId}
                          productId={this.state.productId} onChangeInFunc={this.handleChangeInFunc} customWidth={this.state.width} customHeight={this.state.height} /> : null}

                    </TabPanel>
                    <TabPanel tabId="3">
                      {this.state.isProduct ?
                        <ProductPreference onClickPreviousToFunc={this.handleProductPrevious}
                          onClickNextOfDocument={this.handleProductNxt} selectedRegion={this.state.selectedRegion} selectedCountry={this.state.selectedCountry}
                          gdprAcceptance={this.state.gdprAcceptance}
                          profession={this.state.profession}
                          products={this.state.products}
                          contentPreference={this.state.contentPreference}
                          documentPreference={this.state.documentPreference}
                          selectedAreas={this.state.selectedAreas}
                          selectedClasses={this.state.selectedClasses}
                          history={this.state.history}
                          areaId={this.state.areaId}
                          classId={this.state.classId}
                          productId={this.state.productId} onChangeInProducts={this.handleChangeInProduct} customWidth={this.state.width} customHeight={this.state.height} /> : null}
                    </TabPanel>
                    <TabPanel tabId="4">
                      {this.state.isDocument ?
                        <DocumentTypes onClickPreviousToProducts={this.handleDocPrevious}
                          onClickNextToReview={this.handleDocNxt} selectedRegion={this.state.selectedRegion} selectedCountry={this.state.selectedCountry}
                          gdprAcceptance={this.state.gdprAcceptance}
                          profession={this.state.profession}
                          products={this.state.products}
                          contentPreference={this.state.contentPreference}
                          documentPreference={this.state.documentPreference}
                          selectedAreas={this.state.selectedAreas}
                          selectedClasses={this.state.selectedClasses}
                          showModal={this.state.showModal}
                          history={this.state.history}
                          areaId={this.state.areaId}
                          classId={this.state.classId}
                          productId={this.state.productId} onChangeInDocPref={this.handleChangeInDocs} customWidth={this.state.width} customHeight={this.state.height} /> : null
                      }
                    </TabPanel>
                    <TabPanel tabId="5">
                      {this.state.isReview ?
                        <ReviewModal
                          onBackFrmReview={this.handleBckFromReview}
                          selectedRegion={this.state.selectedRegion} selectedCountry={this.state.selectedCountry}
                          gdprAcceptance={this.state.gdprAcceptance}
                          profession={this.state.profession}
                          products={this.state.products}
                          contentPreference={this.state.contentPreference}
                          documentPreference={this.state.documentPreference}
                          selectedAreas={this.state.selectedAreas}
                          selectedClasses={this.state.selectedClasses}
                          showModal={this.state.showModal}
                          history={this.state.history}
                          areaId={this.state.areaId}
                          classId={this.state.classId}
                          productId={this.state.productId}
                          reviewStatus={this.state.reviewStatus}
                          wwid={this.state.wwid}
                          customWidth={this.state.width}
                          customHeight={this.state.height}
                        /> : null}
                    </TabPanel>
                  </Tabs>

                </div>
                <div style={{ margin: "0% 1%" }}>
                  <Footer customWidth={this.state.width} customHeight={this.state.height} />
                </div>
              </> :
                <div style={{ textAlign: "center", padding: "10%" }}>
                  <img src={imsLoader} alt="Loading Data" style={{ width: "20%" }} />
                </div>
              }
            </ErrorBoundary>
          </div>
          {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
        </>

      );
    }
  }
}

export default Registration;