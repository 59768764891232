import { Component } from "react";
import { chatbotHeaderIcon } from "../../assets";
import "./Training.css";
class Training extends Component {
    constructor(props) {
        super(props);
        this.state = {
            innerHeight: window.innerHeight,
            innerWidth: window.innerWidth,
            value: [{ title: "iMedical GenAI OverView", description: "Overview of using main features of GenAI Answers" ,link:"https://jnj.sharepoint.com/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/GenAI_Answers_Overview.pdf"}, 
                { title: "Document Upload", description: "Upload Document and generate summaries of Key information ", link:"https://jnj.sharepoint.com/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/GenAI_Training/Training_Document_Upload.pdf?CT=1742540349891&OR=ItemsView"},
                { title: "Image Upload", description: "Provides examples of upoading images and GenAI Prompting", link:"https://jnj.sharepoint.com/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/GenAI_Training/Training_Images.pdf?CT=1742541120741&OR=ItemsView"},
                 { title: "Notebooks.AI", description: "Overview of the iMedical Notebooks.AI panel", link:"https://jnj.sharepoint.com/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/GenAI_Training/Training_Notebooks.pdf?CT=1742541203165&OR=ItemsView" }, 
                 { title: "Prompt Templates" , description: "Save prompt collections and execute mutiple questions at once. Share Prompt templates with colleagues" ,link:"https://jnj.sharepoint.com/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/GenAI_Training/Training_Prompt_Templates.pdf?CT=1742541251213&OR=ItemsView"}
            ]
        }
    }
    render() {
        return (<>
            <div className="gen-ai-answering-main">
                <div className="">
                    <div className="gen-ai-answering-main-img" style={{ marginTop: "3%" }}>
                        <img className="bm-agent-image" src={chatbotHeaderIcon} alt="iMS profile image" style={{ backgroundColor: '#ffffff' }} />
                    </div>
                </div>
                <div className="training-header">
                    Training Materials
                </div>
                <div className="training-content">
                    <table className="training-table">
                        <tr>
                            <th className="training-table-header-section" style={{ paddingLeft:"8%"}}><span className="table-message-text" style={{color:"#FFFF",fontSize:"16px"}}>Title</span></th>
                            <th className="training-table-header-section" style={{width: "70%", paddingLeft:"20%"}}><span className="table-message-text" style={{color:"#FFFF",fontSize:"16px"}}>Description</span></th>
                        </tr>
                        <tbody>
                            {(this.state.value.map((val)=>{
                                return (
                                    <tr>
                                        <td className="training-table-body-tab"><span className="table-message-text"><a className="training-atag" href={val.link} target="_blank">{val.title}</a></span></td>
                                        <td className="training-table-body-tab"><span className="table-message-text">{val.description}</span></td>
                                    </tr>
                                )
                            }))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
}
export default Training;