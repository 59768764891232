import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./PubMedSearchIndex.css";


class PubMedLeftSearchBoxIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchKeyword: props.searchKeyword ? props.searchKeyword : '',
            customWidth: props.customWidth,
            customHeight: props.customHeight,
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.searchKeyword != nextProps.searchKeyword) {
            this.setState({ searchKeyword: nextProps.searchKeyword })
        }
    }

    handleChangeInSearch = (event) => {
        this.setState({
            searchKeyword: event.target.value,
        })
    }

    handleKeyDown = async (event) => {
        if (['Enter'].includes(event.key) && !event.shiftKey && this.state.searchKeyword && this.state.searchKeyword.trim()) {
            event.preventDefault();
            event.returnValue = false;
            await this.handleOnClickSearchIcon();
        }
    }
    buildUrl = (searchKeyword) => {
        searchKeyword = encodeURIComponent(searchKeyword);
        let url = `/PubMedSearch?query=${searchKeyword}&filters=&q=`;
        return url;
    };
    handleOnClickSearchIcon = () => {
        const newUrl = this.buildUrl(this.state.searchKeyword);
        this.props.handleChangeInSearch(this.state.searchKeyword, newUrl);
        this.props.history.push(newUrl);
    }
    render() {
        if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
            return (<></>)
        }
        else if (this.props.customWidth > 1000) {
            return (<div className="pubmed-main-left-searchbox box-white">

                <div className="pubmed-main-left-textarea-section">
                    <div className="pubmed-main-left-textarea-label-section">
                        <span>Search query</span>
                        {this.state.searchKeyword && <svg onClick={() => { this.setState({ searchKeyword: "" }) }} className="pubmed-main-left-textarea-label-clear" aria-hidden="true" focusable="false" height="1em" role="presentation" viewBox="0 0 24 24" width="1em">
                            <path d="M12 2a10 10 0 1010 10A10.016 10.016 0 0012 2zm3.36 12.3a.754.754 0 010 1.06.748.748 0 01-1.06 0l-2.3-2.3-2.3 2.3a.748.748 0 01-1.06 0 .754.754 0 010-1.06l2.3-2.3-2.3-2.3A.75.75 0 019.7 8.64l2.3 2.3 2.3-2.3a.75.75 0 011.06 1.06l-2.3 2.3z" fill="currentColor"></path>
                        </svg>}

                    </div>
                    <textarea className="pubmed-main-left-textarea-box"
                        name="searchBar" placeholder="Ask your question..."
                        value={this.state.searchKeyword}
                        onChange={this.handleChangeInSearch} />
                </div>
                <div className="pubmed-main-left-search-button-section">
                    <button className="pubmed-main-left-search-button" onClick={this.handleOnClickSearchIcon}>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="magnifying-glass" className="svg-inline--fa fa-magnifying-glass" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ fontSize: "1 rem" }}>
                            <path fill="currentColor" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path>
                        </svg>
                        Search
                    </button>
                </div>
            </div>)
        }
    }

}
export default withRouter(PubMedLeftSearchBoxIndex);