import InputRange from 'react-input-range'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

export default function RangeSlider(props) {
  const {
    handleYearSelection,
    rangeMax,
    rangeMin,
    gotoPage,
    offSet,
    yearSelection,
  } = props

  const handleSelection = (vals) => {
    handleYearSelection(vals)
  }

  const modifiedStyles = {
    "marginLeft": offSet + "%",
    "marginRight": offSet + "%"
  }
  const [updatedFromYear, setUpdatedFromYear] = useState(yearSelection.min);
  const [updatedToYear, setUpdatedToYear] = useState(yearSelection.max);
  const [isValidYear, setisValidYear] = useState(true);
  const [isSubmited, setIsSubmited] = useState(false);

  useEffect(() => {
    setUpdatedFromYear(yearSelection.min);
    setUpdatedToYear(yearSelection.max);
  }, [yearSelection?.min, yearSelection?.max]);

  const handleOnClickReset = () => {
    setUpdatedFromYear(yearSelection.min);
    setUpdatedToYear(yearSelection.max);
    setIsSubmited(false);
    setisValidYear(true)
  }
  const checkValidation = () => {
    if (isSubmited) {
      let _updatedFromYear = parseInt(updatedFromYear);
      let _updatedToYear = parseInt(updatedToYear);
      if (_updatedFromYear && _updatedToYear && _updatedFromYear <= _updatedToYear) {
        setisValidYear(true)
      }
      else {
        setisValidYear(false)
      }
    }

  }
  const handleOnClickUpdate = async () => {
    setIsSubmited(true);
    let _updatedFromYear = parseInt(updatedFromYear);
    let _updatedToYear = parseInt(updatedToYear);
    if (_updatedFromYear && _updatedToYear && _updatedFromYear <= _updatedToYear) {
      setisValidYear(true)
      await handleYearSelection({
        "min": parseInt(updatedFromYear),
        "max": parseInt(updatedToYear)
      })
      gotoPage('1')
    } else {
      setisValidYear(false)
    }
  }
  return (
    <>
      <div className="input-range__track background-track" />
      <div className="input-range-container" style={modifiedStyles}>
        <InputRange
          allowSameValues={true}
          formatLabel={value => `${value}`}
          maxValue={rangeMax}
          minValue={rangeMin}
          onChange={value => handleSelection(value)}
          onChangeComplete={_e => gotoPage('1')}
          step={1}
          value={yearSelection}
        />
      </div>
      {window.location.pathname === "/PubMedSearch" ? <div className="year-range-display-container">
        <div className="selected-year-range-display">
          <span>From </span>
          <span><input type="number" className='txtRangeYear' id="yearSelectionMin" value={updatedFromYear} onChange={event => { setUpdatedFromYear(event.target.value); checkValidation(); }} name="quantity" min={rangeMin} max={rangeMax} /></span>
          <span> to </span>
          <span><input type="number" className='txtRangeYear' id="yearSelectionMax" value={updatedToYear} onChange={event => { setUpdatedToYear(event.target.value); checkValidation(); }} name="quantity" min={rangeMin} max={rangeMax} /></span>
          <button className='year-range-display-update-btn' onClick={x => handleOnClickUpdate()}>
            <svg fill="#ffffff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="-80.58 -80.58 496.92 496.92" stroke="#ffffff">
              <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(0,0), scale(1)">
                <rect x="-80.58" y="-80.58" width="496.92" height="496.92" rx="248.46" fill="#000000" strokewidth="0"></rect>
              </g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier"> <g> <g> <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795 "></polygon> </g> </g>
              </g>
            </svg>
          </button>
          <button className='year-range-display-reset-btn' onClick={x => handleOnClickReset()}>
            <svg fill="#ffffff" width="16px" height="16px" viewBox="-460.8 -460.8 2841.60 2841.60" xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0">
                <rect x="-460.8" y="-460.8" width="2841.60" height="2841.60" rx="1420.8" fill="#000000" strokewidth="0"></rect>
              </g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path d="M960 0v213.333c411.627 0 746.667 334.934 746.667 746.667S1371.627 1706.667 960 1706.667 213.333 1371.733 213.333 960c0-197.013 78.4-382.507 213.334-520.747v254.08H640V106.667H53.333V320h191.04C88.64 494.08 0 720.96 0 960c0 529.28 430.613 960 960 960s960-430.72 960-960S1489.387 0 960 0" fill-rule="evenodd">
                </path>
              </g>
            </svg>
          </button>
        </div>
        {!isValidYear && <div className="selected-year-range-display selected-year-range-display-validation-message"><span className='yearValidationMessage'>Enter valid to year and from year.</span> </div>}
      </div>
        :
        <div className="year-range-display-container">
          <div className="selected-year-range-display">
            <span>From </span>
            <span>{yearSelection.min}</span>
            <span> to </span>
            <span>{yearSelection.max}</span>
          </div>
        </div>}
    </>
  )
}

RangeSlider.propTypes = {
  handleYearSelection: PropTypes.func.isRequired,
  rangeMax: PropTypes.number.isRequired,
  rangeMin: PropTypes.number.isRequired,
  gotoPage: PropTypes.func.isRequired,
  offSet: PropTypes.number.isRequired,
  yearSelection: PropTypes.object.isRequired,
}
