import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { disclaimerIcn } from '../../assets';
import { LocationOrigin, multiFilesAccept, DropdownGraphTypes } from '../../utils/utilityServices';
import { Modal } from "react-bootstrap";
import axios from "axios";
import { imsLoader, GenAiDOCIcon, GenAiPDFIcon, GenAiPPTIcon, BarGraph, BubbleGraph, PieGraph, LinePlot, LineScatterGraph, arrowIconBlue, DonutChart } from '../../assets/index';
import AlertMessagePopUpModal from "../PopUp/AlertMessagePopUpModal";
import PromptTemplateListPopUp from "../PopUp/PromptTemplateListPopUp"
import ApiServices from "../../utils/apiServices";
import threadingService from "../../services/threadingService";
import GenAIDocumentTypesFilters from './GenAIDocumentTypesFilters';
import ToggleSwitch from '../ToggleSwitch/index';
import { sanitize } from 'dompurify';
import Button from "../Button";
import { LimitedUser } from '../../utils/utilityServices'



let _locationOrigin = LocationOrigin;
//_locationOrigin = 'https://predev-ims.jnj.com';
let ts = new threadingService();
class GenAIAskQuestion extends Component {
    constructor(props) {
        super(props);
        var guid = uuidv4();
        var threadGuid = uuidv4();
        this.state = {
            sessionID: props.location.state ? props.location.state.sessionID : guid,
            threadID: props.location.state ? props.location.state.threadID : threadGuid,
            newThreadID: threadGuid,
            newQuestion: '',
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            attachedFiles: props.location.state ? props.location.state.attachedFiles : [],
            uploadFilePlaceHolder: "",
            uploadFilePlaceHolderTitle: "",
            isAttachedFile: false,
            customeKey: props.customeKey,
            isProcessing: false,
            updateAnyFile: false,
            alertMessageModelColor: "",
            alertMessageContent: "",
            alertMessageShowModal: false,
            newAttachedFiles: [],
            removedAttachedFileIndex: -1,
            spinnerMessage: '',
            isRedirectOnGenAISearch: false,
            selectedFilters: [],
            renderfilters: [],
            isComprehensive: false,
            isDisclaimerAuthenticate: false,
            fileUploadDisclaimer: '',
            fileUploadDisclaimerId: '',
            fileUploadDisclaimerVersion: '',
            isShowFileUploadDisclaimer: false,
            systemPromptTemplates: [],
            isUserPromptTemplate: false,
            isSystemPromptTemplate: true,
            showPromptTemplateList: false,
            isFromGlobalFeaturesMenu: false,
            attachedFilePopupCollapsed: false,
            isActiveGraphSection: false,
            isOpenGraph: false,
            selectedGraphType: '',
            isFromOtherPage: false,
            // isUserPromptTemplate: false,
            // isSystemPromptTemplate: true,
            // showPromptTemplateList: true,
            // isFromGlobalFeaturesMenu: true,
            // attachedFilePopupCollapsed: false,
        }
    }
    componentDidMount = async () => {
        if (this.props.location.state?.attachedFiles) {
            this.setState({ isFromOtherPage: true })
            await this.multipleFilesUpload();
        }
        await this.manageDisclaimer();
    }

    async componentWillUnmount() {
        let { sessionID, isRedirectOnGenAISearch, attachedFiles } = this.state;
        if (!isRedirectOnGenAISearch && attachedFiles.length > 0) {
            let response = await ts.deleteIndexBySessionId(sessionID);
        }
    }
    manageDisclaimer = async () => {
        this.setState({ isProcessing: true, spinnerMessage: "Please wait" });
        let wwid = JSON.parse(localStorage.getItem('wwid'));
        const service = new ApiServices();
        let responseADUA = await service.getAnswerDisclaimerUserAuthenticate(wwid);
        if (responseADUA && responseADUA.length > 0 && responseADUA[0].is_authenticate) {
            this.setState({ isDisclaimerAuthenticate: true }, async () => {
                await this.getSystemPromptTemplates()
            });
        } else {
            let responseAD = await service.getLatestAnswerDisclaimer(wwid);
            if (responseAD && responseAD.length > 0) {
                this.setState({
                    isDisclaimerAuthenticate: false,
                    fileUploadDisclaimer: responseAD[0].disclaimer,
                    fileUploadDisclaimerId: responseAD[0].id,
                    fileUploadDisclaimerVersion: responseAD[0].disclaimer_version,
                }, async () => {
                    await this.getSystemPromptTemplates()
                });
            } else {
                this.setState({ isDisclaimerAuthenticate: true }, async () => {
                    await this.getSystemPromptTemplates()
                });
            }
        }
    }
    getSystemPromptTemplates = async () => {
        this.setState({ isProcessing: true, spinnerMessage: "Please wait" });
        const service = new ApiServices();
        let _systemPromptTemplates = await service.getSystemPromptTemplatesForTextArea();
        if (_systemPromptTemplates && _systemPromptTemplates.length > 0) {
            this.setState({ isProcessing: false, systemPromptTemplates: _systemPromptTemplates, spinnerMessage: "" });
        } else {
            this.setState({ isProcessing: false, systemPromptTemplates: [], spinnerMessage: "" });
        }

    }
    successAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#00c12c",
            alertMessageShowModal: true
        })
    }
    errorAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#de1327",
            alertMessageShowModal: true
        })
    }
    closeAlertMessage = () => {
        this.setState({
            alertMessageContent: "",
            alertMessageModelColor: "",
            alertMessageShowModal: false,
            showPreview: false
        })
    }
    handleOnClickFileUpload = () => {
        this.setState({
            isShowFileUploadDisclaimer: true,
        })
    }
    handleOnClickActiveGraph = () => {
        let { isActiveGraphSection } = this.state;
        this.setState({
            isActiveGraphSection: !isActiveGraphSection,
            isOpenGraph: false,
            selectedGraphType: '',
        });
    }

    handleOnClickAttachedFile = () => {
        const { attachedFilePopupCollapsed } = this.state;
        this.setState({ attachedFilePopupCollapsed: !attachedFilePopupCollapsed })
    }

    snippetLongStringForKeyword = (snippet, strLength = 25) => {
        if (snippet.length > strLength) {
            let text = snippet.substring(0, strLength)//cuts to 70
            text = text + `...`//adds (...) at the end to show that it's cut
            return text
        }
        return snippet;
    }
    async fileInputChange(event) {
        const files = event.target.files;
        let { attachedFiles } = this.state;

        let isImageUpload = (Object.keys(files).some(x =>
            files[x].name.toLowerCase().endsWith('.gif')
            || files[x].name.toLowerCase().endsWith('.jpeg')
            || files[x].name.toLowerCase().endsWith('.jpg')
            || files[x].name.toLowerCase().endsWith('.png')))
            || (attachedFiles.some(x =>
                x.name.toLowerCase().endsWith('.gif')
                || x.name.toLowerCase().endsWith('.jpeg')
                || x.name.toLowerCase().endsWith('.jpg')
                || x.name.toLowerCase().endsWith('.png')));

        let isDocUpload = (Object.keys(files).some(x =>
            files[x].name.toLowerCase().endsWith('.pdf')
            || files[x].name.toLowerCase().endsWith('.doc')
            || files[x].name.toLowerCase().endsWith('.docx')
            || files[x].name.toLowerCase().endsWith('.ppt')
            || files[x].name.toLowerCase().endsWith('.pptx')))
            || (attachedFiles.some(x =>
                x.name.toLowerCase().endsWith('.pdf')
                || x.name.toLowerCase().endsWith('.doc')
                || x.name.toLowerCase().endsWith('.docx')
                || x.name.toLowerCase().endsWith('.ppt')
                || x.name.toLowerCase().endsWith('.pptx')))

        if ((attachedFiles.length + Object.keys(files).length) > 5) {
            this.errorAlertMessage("You can only upload a maximum of 5 files");
        } else if (isImageUpload && isDocUpload) {
            this.errorAlertMessage("You can upload either image file or document file");
        } else if (isImageUpload && (attachedFiles.length + Object.keys(files).length) > 1) {
            this.errorAlertMessage("You can only upload a maximum of 1 image file");
        }
        else {
            let _attachedFiles = [];
            let isUploadSuccessfully = true;
            for (var i = 0; i < Object.keys(files).length; i++) {
                let file = files[i];
                if (file.name.toLowerCase().endsWith('.pdf')
                    || file.name.toLowerCase().endsWith('.jpg')
                    || file.name.toLowerCase().endsWith('.jpeg')
                    || file.name.toLowerCase().endsWith('.png')
                    || file.name.toLowerCase().endsWith('.gif')) {
                    file.guid = uuidv4();
                    file.newFileName = file.name;
                    _attachedFiles.push(file);
                } else {
                    this.setState({ isProcessing: true, attachedFilePopupCollapsed: false });
                    let API_ENDPOINT = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}PreviewService/PDFMaker`;
                    if (file.name.toLowerCase().endsWith('.doc') || file.name.toLowerCase().endsWith('.docx')) {
                        API_ENDPOINT += "?fileType=Document"
                    } else {
                        API_ENDPOINT += "?fileType=Presentation"
                    }
                    const formData = new FormData();
                    formData.append('form_filed_name', file)
                    await axios.post(API_ENDPOINT, formData, {
                        headers: {
                            'content-type': 'multipart/form-data'
                        },
                        responseType: "blob",
                    }).then((res) => {
                        let newFileName = file.name.split('.').slice(0, -1).join('.');
                        const fileName = `${newFileName}.pdf`;
                        const data = new Blob([res.data], { type: 'application/pdf' });
                        var newFile = new File([data], file.name, { type: 'application/pdf' });
                        newFile.guid = uuidv4();
                        newFile.newFileName = fileName;
                        _attachedFiles.push(newFile);
                    }).catch((error) => {
                        console.log(error);
                        isUploadSuccessfully = false;
                    });
                }
                if (!isUploadSuccessfully) {
                    break;
                }
            }
            if (!isUploadSuccessfully) {
                this.setState({ newAttachedFiles: [], removedAttachedFileIndex: -1, isProcessing: false }, () => {
                    this.errorAlertMessage("Upload failed due to some reason. Kindly try after some time.");
                });
            } else {
                var threadGuid = uuidv4();
                this.setState({ newAttachedFiles: _attachedFiles, newThreadID: threadGuid }, async () => {
                    await this.multipleFilesUpload();
                });
            }
        }
    }
    manageFilePlaceHolder() {
        let { attachedFiles } = this.state;
        let uploadFilePlaceHolder = '', uploadFilePlaceHolderTitle = '';
        if (attachedFiles.length > 0) {
            let singleFile = attachedFiles[0];
            uploadFilePlaceHolder = this.getAttachedFileName(singleFile);
            uploadFilePlaceHolderTitle = singleFile.name;
        }
        this.setState({ uploadFilePlaceHolder: uploadFilePlaceHolder, uploadFilePlaceHolderTitle: uploadFilePlaceHolderTitle, isAttachedFile: attachedFiles.length > 0 });
    }
    getAttachedFileName(singleFile) {
        let filename = singleFile.name.split('.').slice(0, -1).join('.');
        let fileExtention = singleFile.name.substring(singleFile.name.lastIndexOf('.') + 1, singleFile.name.length) || singleFile.name;
        filename = this.snippetLongStringForKeyword(filename);
        return `${filename} .${fileExtention}`;;
    }
    handleRemoveAllAttachedFile = async () => {
        this.setState({
            uploadFilePlaceHolder: "",
            uploadFilePlaceHolderTitle: "",
            attachedFiles: [],
            newAttachedFiles: [],
            isAttachedFile: false,
            attachedFilePopupCollapsed: false,
            newAttachedFiles: [],
            removedAttachedFileIndex: -1,
            updateAnyFile: false,
        }, async () => {
            let { sessionID } = this.state;
            let response = await ts.deleteIndexBySessionId(sessionID);
            this.successAlertMessage("Documents removed successfully.");
        })

    }
    handleRemoveAttachedFile = async (index) => {
        let { attachedFiles } = this.state;
        attachedFiles = attachedFiles.filter((file, fileIndex) => fileIndex !== index);
        if (attachedFiles.length > 0) {
            var threadGuid = uuidv4();
            this.setState({ removedAttachedFileIndex: index, newThreadID: threadGuid }, async () => {
                await this.multipleFilesUpload();
            });
        } else {
            this.setState({
                uploadFilePlaceHolder: "",
                uploadFilePlaceHolderTitle: "",
                attachedFiles: [],
                newAttachedFiles: [],
                isAttachedFile: false,
                attachedFilePopupCollapsed: false,
                newAttachedFiles: [],
                removedAttachedFileIndex: -1,
                updateAnyFile: false,
            }, async () => {
                let { sessionID } = this.state;
                let response = await ts.deleteIndexBySessionId(sessionID);
                this.successAlertMessage("Document removed successfully.");
            })
        }
    }
    handleNewQuestion = (e) => {
        this.setState({ newQuestion: e.target.value })
    }
    multipleFilesUpload = async (e) => {
        this.setState({ isProcessing: true, attachedFilePopupCollapsed: false });
        let { attachedFiles, sessionID, updateAnyFile, newAttachedFiles, removedAttachedFileIndex, newThreadID } = this.state;
        var url = `${_locationOrigin}${process.env.REACT_APP_IMS_API_MULTI_ANSWERS}multi_upload`
        if (window.location.origin === 'https://predev-ims.jnj.com') {
            url = `${window.location.origin}${process.env.REACT_APP_IMS_API_MULTI_ANSWERS}multi_upload`
        }
        const formData = new FormData();
        attachedFiles.concat(newAttachedFiles).filter((file, fileIndex) => fileIndex !== removedAttachedFileIndex).map((item, index) => {
            formData.append(`file${index + 1}`, item);
            formData.append(`fileGuid${index + 1}`, item.guid);
            formData.append(`newFileName${index + 1}`, item.newFileName);
        })
        formData.append(`fileCount`, attachedFiles.concat(newAttachedFiles).filter((file, fileIndex) => fileIndex !== removedAttachedFileIndex).length);
        formData.append(`sessionID`, sessionID);
        formData.append(`threadID`, newThreadID);
        formData.append(`username`, localStorage.getItem('username').toLowerCase().replace(/"/g, ''));
        formData.append(`updateAnyFile`, updateAnyFile);

        let config = {
            method: 'POST',
            url: url,
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
        }
        await axios(config).then((resp) => {
            let response = resp.data;
            if (response.FileUpload) {
                let isRemoveFile = this.state.removedAttachedFileIndex !== -1;
                let isSingleFile = newAttachedFiles.length === 1;
                this.setState({ updateAnyFile: true, attachedFiles: attachedFiles.concat(newAttachedFiles).filter((file, fileIndex) => fileIndex !== removedAttachedFileIndex), newAttachedFiles: [], removedAttachedFileIndex: -1, threadID: newThreadID, selectedFilters: [], renderfilters: [], isComprehensive: false, }, () => {
                    this.manageFilePlaceHolder();
                    if (isRemoveFile) {
                        this.successAlertMessage("Document removed successfully.");
                    } else if (this.state.isFromOtherPage) {
                        //this.successAlertMessage('Document restored successfully');
                        this.setState({ isFromOtherPage: false })
                    }
                    else {
                        this.successAlertMessage(isSingleFile ? `Document uploaded successfully.` : `Documents uploaded successfully.`);
                    }
                });
            } else if (!response.FileUpload && response.Files && response.Files.length === 0) {
                this.errorAlertMessage(`Upload failed due to some reason. Kindly try after some time.<br /><br /><p class='alertmessage-file-label'>Documents name</p> - ${newAttachedFiles.map(x => x.name).join("<br /> - ")}`)
                this.setState({ updateAnyFile: true, newAttachedFiles: [], removedAttachedFileIndex: -1, selectedFilters: [], renderfilters: [], isComprehensive: false, }, () => {
                    this.manageFilePlaceHolder();
                });
            }
            else if (!response.FileUpload && response.Files && response.Files.length > 0) {
                let failedGuid = response.Files.map(x => x.Guid)
                let finalFaileAttached = attachedFiles.concat(newAttachedFiles).filter((file, fileIndex) => fileIndex !== removedAttachedFileIndex).filter(x => failedGuid.includes(x.guid));
                this.errorAlertMessage(`Upload failed due to some reason. Kindly try after some time.<br /><br /><p class='alertmessage-file-label'>Documents name</p> - ${finalFaileAttached.map(x => x.name).join("<br /> - ")}`)
                this.setState({
                    updateAnyFile: true, attachedFiles: attachedFiles.concat(newAttachedFiles).filter((file, fileIndex) => fileIndex !== removedAttachedFileIndex).filter(x => !failedGuid.includes(x.guid)),
                    newAttachedFiles: [], removedAttachedFileIndex: -1, selectedFilters: [], renderfilters: [], isComprehensive: false,
                }, () => {
                    this.manageFilePlaceHolder();
                });
            }
        }).catch((error) => {
            let isRemoveFile = this.state.removedAttachedFileIndex !== -1;
            this.setState({ newAttachedFiles: [], removedAttachedFileIndex: -1 }, () => {
                if (isRemoveFile) {
                    this.errorAlertMessage(`Remove document failed to some reason. Kindly try after some time.`)
                } else {
                    this.errorAlertMessage(`Upload failed due to some reason. Kindly try after some time.<br /><br /><p class='alertmessage-file-label'>Documents name</p> - ${newAttachedFiles.map(x => x.name).join("<br /> - ")}`)
                }
            });

            console.log(error)
        }).finally(() => {
            this.setState({ isProcessing: false });
        });
    }
    handleKeyDown = async (event) => {
        if (['Enter'].includes(event.key) && !event.shiftKey && this.state.newQuestion && this.state.newQuestion.trim()) {
            event.preventDefault();
            event.returnValue = false;
            await this.handleOnClickSend();
        }
    }
    handleOnClickAuthoring = async () => {
        let { sessionID, newQuestion, attachedFiles, threadID } = this.state;
        let _newQuestion = newQuestion.trim().replace(/\r\n/g, '\n').replace(/\n\n/g, '\n');
        if (attachedFiles.length > 0 && _newQuestion.trim().split(/\n\*\|\*\n/g).filter(x => x).length > 10) {
            this.errorAlertMessage("You have exceeded 10 questions. Please remove questions before continuing.");
        } else {
            this.setState({ isRedirectOnGenAISearch: true }, () => {
                let _path = window.location.pathname;  //`/genAIAnswering`;
                let data = {
                    sessionID: sessionID,
                    newQuestion: newQuestion,
                    attachedFiles: attachedFiles,
                    threadID: threadID,
                    path: _path
                }
                this.props.history.push({
                    pathname: '/GenAIAuthoring',
                    state: data // your data array of objects
                })
            })
        }
    }
    handleOnClickSend = async () => {
        let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'))

        let { sessionID, newQuestion, attachedFiles, threadID, selectedFilters, renderfilters, isComprehensive, selectedGraphType } = this.state;
        if (userAccessGroup.includes(LimitedUser) && attachedFiles.length === 0) {
            this.errorAlertMessage("Document not found. Kindly upload document.");
        } else {
            let _newQuestion = newQuestion.trim().replace(/\r\n/g, '\n').replace(/\n\n/g, '\n');
            if (attachedFiles.length > 0 && _newQuestion.trim().split(/\n\*\|\*\n/g).filter(x => x).length > 10) {
                this.errorAlertMessage("You have exceeded 10 questions. Please remove questions before continuing.");
            } else {
                let isImageUpload = attachedFiles.some(x =>
                    x.name.toLowerCase().endsWith('.gif')
                    || x.name.toLowerCase().endsWith('.jpeg')
                    || x.name.toLowerCase().endsWith('.jpg')
                    || x.name.toLowerCase().endsWith('.png'));
                let data = {
                    sessionID: sessionID,
                    newQuestion: newQuestion,
                    attachedFiles: attachedFiles,
                    threadID: threadID,
                    selectedFilters: selectedFilters,
                    renderfilters: renderfilters,
                    isComprehensive: isComprehensive,
                    isGraphThread: false,
                    selectedGraphType: selectedGraphType,
                    isImageThread: isImageUpload,
                }
                let { isOpenInModelPopup, collectionDetails } = this.props;
                if (!isOpenInModelPopup && collectionDetails) {
                    this.setState({ spinnerMessage: "Please wait while finding answers.", isProcessing: true, isRedirectOnGenAISearch: true })
                    let service = new ApiServices();
                    let wwid = JSON.parse(localStorage.getItem("wwid"));
                    await service.createCollection(
                        wwid,
                        collectionDetails.collection_id,
                        collectionDetails.collection_name,
                        sessionID
                    );
                    if (window.location.pathname.startsWith('/genAIAnsweringPOC') ||
                        window.location.pathname.startsWith('/genAICollectionPOC')) {
                        this.props.history.push({
                            pathname: '/genAISearchPOC',
                            state: data // your data array of objects
                        })
                    }
                    else {
                        this.props.history.push({
                            pathname: '/genAISearch',
                            state: data // your data array of objects
                        })
                    }
                }
                else {
                    this.setState({ isRedirectOnGenAISearch: true }, () => {
                        if (window.location.pathname.startsWith('/genAIAnsweringPOC') ||
                            window.location.pathname.startsWith('/genAICollectionPOC')) {
                            this.props.history.push({
                                pathname: '/genAISearchPOC',
                                state: data // your data array of objects
                            })
                        }
                        else {
                            this.props.history.push({
                                pathname: '/genAISearch',
                                state: data // your data array of objects
                            })
                        }
                        if (this.props.handelCloseNewThreadModal) {
                            this.props.handelCloseNewThreadModal();
                        }
                    })
                }
            }
        }
    }
    handleOnClickSendToGraph = async () => {
        let { sessionID, newQuestion, threadID, isComprehensive, selectedGraphType } = this.state;
        let data = {
            sessionID: sessionID,
            newQuestion: newQuestion,
            attachedFiles: [],
            threadID: threadID,
            selectedFilters: [],
            renderfilters: [],
            isComprehensive: isComprehensive,
            isGraphThread: true,
            selectedGraphType: selectedGraphType

        }
        this.props.history.push({
            pathname: '/genAISearch',
            state: data // your data array of objects
        })
    }
    closeAttachedFilePopupCollapsed = () => {
        this.setState({ attachedFilePopupCollapsed: false })

    }
    closeGraphPopupCollapsed = () => {
        this.setState({ isOpenGraph: false })
    }

    handleSelectedFilters = (selectedFilters) => {
        this.setState({
            selectedFilters: selectedFilters
        })
    }
    handleRenderfilters = (renderfilters) => {
        this.setState({
            renderfilters: renderfilters
        })
    }
    handleOnChangeToggleSwitch = (checked) => {
        this.setState({
            isComprehensive: checked
        })
    }
    handleOnClickDisclaimerOk = () => {
        this.setState({ isShowFileUploadDisclaimer: false, isProcessing: true, spinnerMessage: "Please wait" }, async () => {
            let { fileUploadDisclaimerId } = this.state;
            let service = new ApiServices();
            let wwid = JSON.parse(localStorage.getItem("wwid"));
            await service.insertAnswerDisclaimerUserAuthenticate(
                wwid,
                fileUploadDisclaimerId).then(async () => {
                    await this.manageDisclaimer();
                    this.fileUploadcn.click();
                    this.setState({ isProcessing: false, spinnerMessage: "" })
                }).catch((error) => {
                    console.log(error);
                    this.errorAlertMessage("Something Went Wrong. Kindly try after some time.");
                });
        })
    }
    handelClickOnSystemPromptTemplateShortcut = (prompt) => {
        this.setState({ newQuestion: prompt })

    }
    handleClosePromptTemplateList = () => {
        this.setState({
            showPromptTemplateList: false,
            isUserPromptTemplate: false,
            isSystemPromptTemplate: true,
            isFromGlobalFeaturesMenu: false,
        });
    }
    handleClickOnPromptTemplate = () => {
        this.setState({
            isUserPromptTemplate: false,
            isSystemPromptTemplate: true,
            showPromptTemplateList: true,
            isFromGlobalFeaturesMenu: true,
            attachedFilePopupCollapsed: false,
        })
    }
    handleAddToPromptTemplateText = (prompt) => {
        this.setState({ newQuestion: prompt, showPromptTemplateList: false })
    }
    renderFileIcon = (name) => {
        if (name.toLowerCase().endsWith('.pdf')) {
            return (<><img src={GenAiPDFIcon} /> &nbsp;</>)
        }
        else if (name.toLowerCase().endsWith('.doc') || name.toLowerCase().endsWith('.docx')) {
            return (<><img src={GenAiDOCIcon} /> &nbsp;</>)
        }
        else if (name.toLowerCase().endsWith('.ppt') || name.toLowerCase().endsWith('.pptx')) {
            return (<><img src={GenAiPPTIcon} /> &nbsp;</>)
        }
        else if (name.toLowerCase().endsWith('.jpg')
            || name.toLowerCase().endsWith('.jpeg')
            || name.toLowerCase().endsWith('.png')
            || name.toLowerCase().endsWith('.gif')) {
            return (<><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="miter">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <polygon points="22 15.5 17 10 10.59 16.99 6.5 13 2 17.5 2 22 22 22 22 15.5" stroke-width="0" fill="#059cf7" opacity="0.1"></polygon>
                    <polygon points="22 15.5 17 10 10.59 16.99 6.5 13 2 17.5 2 22 22 22 22 15.5"></polygon>
                    <rect x="2" y="2" width="20" height="20" rx="0"></rect>
                    <line x1="6.99" y1="7" x2="7" y2="7" stroke-linecap="round" stroke-width="2"></line>
                </g>
            </svg> &nbsp;</>)
        }
        else {
            return (<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="file" class="svg-inline--fa fa-file fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"></path></svg>)
        }
    }

    renderAttachedFile = () => {
        let { attachedFilePopupCollapsed, attachedFiles, customeKey } = this.state;
        if (attachedFilePopupCollapsed && attachedFiles.length > 0) {
            return (<div className={attachedFilePopupCollapsed ? "gen-ai-answering-main-attachedfile-wrapper gen-ai-answering-main-attachedfile-open" : "gen-ai-answering-main-attachedfile-wrapper gen-ai-answering-main-attachedfile-close"}>
                <div className="gen-ai-answering-main-attachedfile-header">
                    <div className="gen-ai-answering-main-attachedfile-header-title">
                        Attached Files
                    </div>
                    <div className="gen-ai-answering-main-attachedfile-header-controle">
                        {attachedFiles.length < 5 &&
                            <>
                                <label for={`${customeKey}-answeringAttachedfile`} style={{ cursor: "pointer" }} className="gen-ai-answering-main-attachedfile-header-controle-add">
                                    <div className="flex items-center leading-none justify-center gap-xs">
                                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" className="svg-inline--fa fa-plus fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path fill="currentColor" d="M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24V232H40c-13.3 0-24 10.7-24 24s10.7 24 24 24H200V440c0 13.3 10.7 24 24 24s24-10.7 24-24V280H408c13.3 0 24-10.7 24-24s-10.7-24-24-24H248V72z"></path>
                                        </svg>
                                        <div class="text-align-center relative">Add</div>
                                    </div>
                                </label>
                                <input type="file" key={`${customeKey}${Math.random().toString()}`} name={`${customeKey}-answeringAttachedfile`} id={`${customeKey}-answeringAttachedfile`} multiple accept={multiFilesAccept} onChange={e => { this.fileInputChange(e) }} style={{ display: "none" }} />
                                &nbsp;
                            </>}
                        <button type="button" class="gen-ai-answering-main-attachedfile-header-controle-delete" onClick={e => this.handleRemoveAllAttachedFile()}>
                            <div class="flex items-center leading-none justify-center gap-xs">
                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="trash" className="svg-inline--fa fa-trash fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="currentColor" d="M177.1 48h93.7c2.7 0 5.2 1.3 6.7 3.6l19 28.4h-145l19-28.4c1.5-2.2 4-3.6 6.7-3.6zM354.2 80L317.5 24.9C307.1 9.4 289.6 0 270.9 0H177.1c-18.7 0-36.2 9.4-46.6 24.9L93.8 80H80.1 32 24C10.7 80 0 90.7 0 104s10.7 24 24 24H35.6L59.6 452.7c2.5 33.4 30.3 59.3 63.8 59.3H324.6c33.5 0 61.3-25.9 63.8-59.3L412.4 128H424c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8H367.9 354.2zm10.1 48L340.5 449.2c-.6 8.4-7.6 14.8-16 14.8H123.4c-8.4 0-15.3-6.5-16-14.8L83.7 128H364.3z"></path>
                                </svg>
                                <div class="text-align-center relative">Clear</div>
                            </div>
                        </button>
                    </div>
                </div>
                <div className="gen-ai-answering-main-attachedfile-items">
                    <div className="gen-ai-answering-main-attachedfile-item-wrapper">
                        {
                            attachedFiles.map((fileItem, fileIndex) => {
                                return (<div className="gen-ai-answering-main-attachedfile-item" key={`gen-ai-answering-main-attachedfile-${customeKey}${Math.random().toString()}-${fileIndex}`} title={fileItem.name}>
                                    <span className="gen-ai-answering-main-attachedfile-item-span">
                                        {this.renderFileIcon(fileItem.name)}
                                        {this.getAttachedFileName(fileItem)}
                                    </span>
                                    <button style={{ background: "none" }} title="Clear Attached" onClick={e => this.handleRemoveAttachedFile(fileIndex)}>
                                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="xmark" class="svg-inline--fa fa-xmark fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                            <path fill="currentColor" d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"></path></svg>
                                    </button>
                                </div>)
                            })
                        }
                    </div>
                </div>
            </div>)
        }
        else {
            return (<></>);
        }
    }
    renderGraphDropdown = () => {
        let { isOpenGraph, attachedFiles, customeKey } = this.state;
        if (isOpenGraph) {
            return (<div className={isOpenGraph ? "gen-ai-answering-main-graph-wrapper gen-ai-answering-main-graph-open" : "gen-ai-answering-main-graph-wrapper gen-ai-answering-main-graph-close"}>
                <div className="gen-ai-answering-main-graph-items">
                    <div className="gen-ai-answering-main-graph-item-wrapper">
                        <ul className="gen-ai-answering-main-graph-item-ul">
                            <li onClick={() => {
                                this.setState({ isOpenGraph: false, selectedGraphType: 'scatterPlot' })
                            }}><img className="gen-ai-answering-main-graph-item-image" src={LineScatterGraph} />{DropdownGraphTypes['scatterPlot']}</li>
                            <li onClick={() => {
                                this.setState({ isOpenGraph: false, selectedGraphType: 'lineCharts' })
                            }}><img className="gen-ai-answering-main-graph-item-image" src={LinePlot} />{DropdownGraphTypes['lineCharts']}</li>
                            <li onClick={() => {
                                this.setState({ isOpenGraph: false, selectedGraphType: 'barChart' })
                            }}><img className="gen-ai-answering-main-graph-item-image" src={BarGraph} />{DropdownGraphTypes['barChart']}</li>
                            <li onClick={() => {
                                this.setState({ isOpenGraph: false, selectedGraphType: 'pieChart' })
                            }}><img className="gen-ai-answering-main-graph-item-image" src={PieGraph} />{DropdownGraphTypes['pieChart']}</li>
                            <li onClick={() => {
                                this.setState({ isOpenGraph: false, selectedGraphType: 'bubbleChart' })
                            }}><img className="gen-ai-answering-main-graph-item-image" src={BubbleGraph} />{DropdownGraphTypes['bubbleChart']}</li>
                            <li onClick={() => {
                                this.setState({ isOpenGraph: false, selectedGraphType: 'donutChart' })
                            }}><img className="gen-ai-answering-main-graph-item-image" src={DonutChart} />{DropdownGraphTypes['donutChart']}</li>
                        </ul>
                    </div>
                </div>
            </div>)
        }
        else {
            return (<></>);
        }
    }
    renderSystemPromptTemplateShortcut = () => {
        let { systemPromptTemplates } = this.state;
        if (systemPromptTemplates && systemPromptTemplates.length > 0) {
            return (<div className="gen-ai-answering-prompt-template-shortcut">
                <span className="gen-ai-answering-prompt-template-shortcut-label">Prompt Templates:</span>
                {systemPromptTemplates.map((item, index) => {
                    return (<button type="button" title={item.template_name} onClick={(e) => { this.handelClickOnSystemPromptTemplateShortcut(item.prompt) }} key={`gen-ai-answering-prompt-template-shortcut-item-${index}`} className="gen-ai-answering-prompt-template-shortcut-item">{item.template_short_name}</button>)
                })}
                <a onClick={() => { this.handleClickOnPromptTemplate() }} className="gen-ai-answering-prompt-template-shortcut-label">More…</a>
            </div>)
        } else {
            return (<>
            </>)
        }

    }
    render() {
        let { newQuestion, uploadFilePlaceHolder, spinnerMessage, uploadFilePlaceHolderTitle, customeKey, attachedFiles } = this.state;
        const { alertMessageModelColor, alertMessageContent, alertMessageShowModal } = this.state;
        let { selectedFilters, renderfilters, isComprehensive, showPromptTemplateList } = this.state;
        let { isDisclaimerAuthenticate, fileUploadDisclaimer, isActiveGraphSection, selectedGraphType, isOpenGraph } = this.state;
        let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'))
        if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
            return (<></>)
        }
        else if (this.props.customWidth > 1000) {
            return (<div className="gen-ai-answering-main-ask-question-wrapper">
                <div className="gen-ai-answering-main-ask-question">
                    <textarea name="citationText" value={newQuestion} onKeyDown={this.handleKeyDown} onClick={(e) => { this.closeAttachedFilePopupCollapsed(); this.closeGraphPopupCollapsed(); }} placeholder={isActiveGraphSection ? "Enter text to create graph…" : "Ask a question…"} onChange={(e) => this.handleNewQuestion(e)} />
                    <div className="gen-ai-answering-main-action-section">
                        <div class="gen-ai-answering-main-action-section-dropdown-wrapper">
                            <React.Fragment> {(!uploadFilePlaceHolder) ? <React.Fragment>
                                <div className="gen-ai-answering-main-action-section-file" style={!isActiveGraphSection ? { visibility: "visible" } : { visibility: "hidden" }}>
                                    <input type="file" ref={(el) => { this.fileUploadcn = el; }} name={`${customeKey}-gen-ai-answering-main-action-section-file`} id={`${customeKey}-gen-ai-answering-main-action-section-file`} multiple accept={multiFilesAccept} onChange={e => { this.fileInputChange(e) }} style={{ display: "none" }} />
                                    {(!isDisclaimerAuthenticate && fileUploadDisclaimer) ?
                                        <label style={{ cursor: "pointer" }} onClick={e => { this.handleOnClickFileUpload() }}>
                                            <svg width="30px" height="30px" viewBox="0 0 64.00 64.00" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#ffffff" stroke="#ffffff" stroke-width="0.00064">
                                                <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(0,0), scale(1)">
                                                    <rect x="0" y="0" width="64.00" height="64.00" rx="32" fill="#2a498a" strokewidth="0"></rect>
                                                </g>
                                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.384"></g>
                                                <g id="SVGRepo_iconCarrier">
                                                    <path d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2zm5.143 28.305V49H26.857V30.305H16L32 15l16 15.305H37.143z" fill="#ffffff"></path>
                                                </g>
                                            </svg> <b>Upload Documents</b>
                                        </label>
                                        : <label for={`${customeKey}-gen-ai-answering-main-action-section-file`} style={{ cursor: "pointer" }}>
                                            <svg width="30px" height="30px" viewBox="0 0 64.00 64.00" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#ffffff" stroke="#ffffff" stroke-width="0.00064">
                                                <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(0,0), scale(1)">
                                                    <rect x="0" y="0" width="64.00" height="64.00" rx="32" fill="#2a498a" strokewidth="0"></rect>
                                                </g>
                                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.384"></g>
                                                <g id="SVGRepo_iconCarrier">
                                                    <path d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2zm5.143 28.305V49H26.857V30.305H16L32 15l16 15.305H37.143z" fill="#ffffff"></path>
                                                </g>
                                            </svg> <b>Upload Documents</b>
                                        </label>
                                    }
                                </div></React.Fragment>
                                : <React.Fragment>
                                    <div className="gen-ai-answering-main-action-section-listfile" style={!isActiveGraphSection ? { visibility: "visible" } : { visibility: "hidden" }}>
                                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="file" class="svg-inline--fa fa-file fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"></path></svg>
                                        <label className="gen-ai-answering-main-action-section-upload" title={uploadFilePlaceHolderTitle} style={{ cursor: "pointer" }} onClick={() => this.handleOnClickAttachedFile()}>
                                            {uploadFilePlaceHolder}</label>
                                    </div>
                                </React.Fragment>}</React.Fragment>
                            {((attachedFiles && attachedFiles.length === 0) && !userAccessGroup.includes(LimitedUser)) && (window.location.origin === 'https://predev-ims.jnj.com' || window.location.origin === 'http://localhost:3000') && <div className={isActiveGraphSection ? "gen-ai-answering-main-action-section-graph-active" : "gen-ai-answering-main-action-section-graph"} >
                                <label style={{ cursor: "pointer" }} onClick={e => { this.handleOnClickActiveGraph() }}>
                                    <svg className="gen-ai-answering-main-action-section-graph-svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="-204.8 -204.8 921.60 921.60" width="30px" height="30px" fill="#2a498a" stroke="#2a498a" stroke-width="0.005120040000000001">
                                        <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(0,0), scale(1)">
                                            <rect x="-204.8" y="-204.8" width="921.60" height="921.60" rx="460.8" fill="#ffffff" strokewidth="0"></rect>
                                        </g>
                                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="3.072024"></g><g id="SVGRepo_iconCarrier">
                                            <rect x="76.802" y="247.47" style={{ fill: "#2a498a" }} width="51.2" height="187.733"></rect>
                                            <rect x="178.861" y="170.329" style={{ fill: "#2a498a" }} width="51.883" height="265.216"></rect>
                                            <rect x="281.602" y="213.337" style={{ fill: "#2a498a" }} width="51.2" height="221.867"></rect>
                                            <rect x="384.002" y="128.003" style={{ fill: "#2a498a" }} width="51.2" height="307.2"></rect>
                                            <path d="M136.535,230.403H68.269c-4.693,0-8.533,3.84-8.533,8.533v204.8c0,4.693,3.84,8.533,8.533,8.533h68.267 c4.693,0,8.533-3.84,8.533-8.533v-204.8C145.069,234.243,141.229,230.403,136.535,230.403z M128.002,435.203h-51.2V247.47h51.2 V435.203z"></path>
                                            <path d="M238.935,153.945h-68.267c-4.523,0-8.192,3.669-8.192,8.192v281.6c0,4.523,3.669,8.192,8.192,8.192h68.267 c4.523,0,8.192-3.669,8.192-8.192v-281.6C247.127,157.614,243.458,153.945,238.935,153.945z M230.743,435.545h-51.883V170.329 h51.883V435.545z"></path>
                                            <path d="M341.335,196.27h-68.267c-4.693,0-8.533,3.84-8.533,8.533v238.933c0,4.693,3.84,8.533,8.533,8.533h68.267 c4.693,0,8.533-3.84,8.533-8.533V204.803C349.869,200.11,346.029,196.27,341.335,196.27z M332.802,435.203h-51.2V213.337h51.2 V435.203z"></path>
                                            <path d="M443.736,110.937h-68.267c-4.693,0-8.533,3.84-8.533,8.533v324.267c0,4.693,3.84,8.533,8.533,8.533h68.267 c4.693,0,8.533-3.84,8.533-8.533V119.47C452.269,114.777,448.429,110.937,443.736,110.937z M435.202,435.203h-51.2v-307.2h51.2 V435.203z"></path>
                                            <path d="M102.411,162.137c1.775,0,3.575-0.555,5.111-1.707l98.5-73.873l97.894,40.789c2.918,1.229,6.255,0.725,8.687-1.263 l88.465-72.38v23.1c0,4.719,3.823,8.533,8.533,8.533c4.71,0,8.533-3.814,8.533-8.533V34.137c0-4.719-3.823-8.533-8.533-8.533h-51.2 c-4.71,0-8.533,3.814-8.533,8.533s3.823,8.533,8.533,8.533h29.184l-81.826,66.961l-97.673-40.704c-2.79-1.161-5.99-0.759-8.405,1.05 l-102.4,76.8c-3.772,2.825-4.531,8.175-1.707,11.938C97.248,160.959,99.817,162.137,102.411,162.137z"></path>
                                            <path d="M509.502,471.837l-25.6-25.6c-3.337-3.337-8.73-3.337-12.066,0s-3.337,8.73,0,12.066l11.034,11.034H42.669V29.136 L53.702,40.17c1.664,1.664,3.849,2.5,6.033,2.5c2.185,0,4.369-0.836,6.033-2.5c3.337-3.337,3.337-8.73,0-12.066l-25.6-25.6 c-3.337-3.337-8.73-3.337-12.066,0l-25.6,25.6c-3.337,3.337-3.337,8.73,0,12.066s8.73,3.337,12.066,0l11.034-11.034V477.87 c0,4.719,3.823,8.533,8.533,8.533h448.734l-11.034,11.034c-3.337,3.337-3.337,8.73,0,12.066c1.664,1.664,3.849,2.5,6.033,2.5 s4.369-0.836,6.033-2.5l25.6-25.6C512.838,480.566,512.838,475.173,509.502,471.837z"></path>
                                            <polygon style={{ fill: "#2a498a" }} points="110.935,247.47 110.935,418.137 76.802,418.137 76.802,435.203 128.002,435.203 128.002,247.47 "></polygon>
                                            <polygon style={{ fill: "#2a498a" }} points="213.677,170.329 213.677,418.478 178.861,418.478 178.861,435.545 230.743,435.545 230.743,170.329 "></polygon>
                                            <polygon style={{ fill: "#2a498a" }} points="315.735,213.337 315.735,418.137 281.602,418.137 281.602,435.203 332.802,435.203 332.802,213.337 "></polygon>
                                            <polygon style={{ fill: "#2a498a" }} points="418.135,128.003 418.135,418.137 384.002,418.137 384.002,435.203 435.202,435.203 435.202,128.003 "></polygon>
                                        </g>
                                    </svg>
                                    <b> Graph&nbsp;</b>
                                </label>
                            </div>}
                            {/*attachedFiles.length === 0 && <div className="gen-ai-answering-main-action-section-comprehensive"><b>Comprehensive Answer</b>&nbsp;<ToggleSwitch customKey={`gen-ai-ask-toggle-${this.props.conversationIndex}`} isComprehensive={isComprehensive} handleOnChangeToggleSwitch={this.handleOnChangeToggleSwitch} /></div>*/}
                        </div>
                        <div className="gen-ai-answering-main-action-section-send-wrrap">
                            {(attachedFiles && attachedFiles.length > 0) && <>
                                <a onClick={() => { this.handleClickOnPromptTemplate() }} className="gen-ai-answering-prompt-template-shortcut-label">Prompt Templates</a>
                                <button type="button" title="Authoring" className="gen-ai-answering-main-action-section-send" onClick={(e) =>
                                    this.handleOnClickAuthoring()}>
                                    <svg fill="#ffffff" width="32px" height="32px" viewBox="-11.2 -11.2 54.40 54.40" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff" stroke-width="0.00032">
                                        <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(0,0), scale(1)">
                                            <rect x="-11.2" y="-11.2" width="54.40" height="54.40" rx="27.2" fill="#2a498a" strokewidth="0"></rect>
                                        </g>
                                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="1.216"></g>
                                        <g id="SVGRepo_iconCarrier"> <title>Authoring</title>
                                            <path d="M5.582 20.054l14.886-14.886 6.369 6.369-14.886 14.886-6.369-6.369zM21.153 8.758l-0.698-0.697-11.981 11.98 0.698 0.698 11.981-11.981zM22.549 10.154l-0.698-0.698-11.981 11.982 0.697 0.697 11.982-11.981zM23.945 11.55l-0.698-0.698-11.981 11.981 0.698 0.698 11.981-11.981zM23.319 2.356c0.781-0.783 2.045-0.788 2.82-0.013l3.512 3.512c0.775 0.775 0.77 2.038-0.012 2.82l-2.17 2.17-6.32-6.32 2.17-2.169zM5.092 20.883l6.030 6.030-5.284 1.877-2.623-2.623 1.877-5.284zM4.837 29.117l-3.066 1.117 1.117-3.066 1.949 1.949z"></path>
                                        </g>
                                    </svg>
                                </button>

                            </>
                            }
                            {isActiveGraphSection ? <>
                                <div className='gen-ai-answering-main-graph-select'>
                                    <div className='gen-ai-answering-main-graph-custome-select' onClick={() => this.setState({ isOpenGraph: !(isOpenGraph) })}>
                                        <span>{selectedGraphType ? DropdownGraphTypes[selectedGraphType] : "Select Graph Type"}</span>
                                        <span ><svg width="16px" height="14px"
                                            viewBox="0 0 1024.00 1024.00" class="icon" version="1.1"
                                            xmlns="http://www.w3.org/2000/svg" fill="black">
                                            <g id="SVGRepo_bgCarrier" stroke-width="0">
                                            </g><g id="SVGRepo_tracerCarrier" stroke-linecap="round"
                                                stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier">
                                                <path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="black"></path></g>
                                        </svg>
                                        </span>
                                    </div>
                                    {this.renderGraphDropdown()}
                                </div>
                                <button type="button" disabled={!newQuestion || !selectedGraphType} className="gen-ai-answering-main-action-section-send" onClick={(e) =>
                                    this.handleOnClickSendToGraph()}>
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="-153.6 -153.6 819.20 819.20" width="30px" height="30px" fill="#ffffff" stroke="#ffffff" stroke-width="0.005120040000000001">
                                        <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(0,0), scale(1)">
                                            <rect x="-153.6" y="-153.6" width="819.20" height="819.20" rx="409.6" fill={(!newQuestion || !selectedGraphType) ? "#cecece" : "#2a498a"} strokewidth="0"></rect>
                                        </g>
                                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="3.072024"></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <rect x="76.802" y="247.47" style={{ fill: "#ffffff" }} width="51.2" height="187.733"></rect>
                                            <rect x="178.861" y="170.329" style={{ fill: "#ffffff" }} width="51.883" height="265.216"></rect>
                                            <rect x="281.602" y="213.337" style={{ fill: "#ffffff" }} width="51.2" height="221.867"></rect>
                                            <rect x="384.002" y="128.003" style={{ fill: "#ffffff" }} width="51.2" height="307.2"></rect>
                                            <path d="M136.535,230.403H68.269c-4.693,0-8.533,3.84-8.533,8.533v204.8c0,4.693,3.84,8.533,8.533,8.533h68.267 c4.693,0,8.533-3.84,8.533-8.533v-204.8C145.069,234.243,141.229,230.403,136.535,230.403z M128.002,435.203h-51.2V247.47h51.2 V435.203z"></path>
                                            <path d="M238.935,153.945h-68.267c-4.523,0-8.192,3.669-8.192,8.192v281.6c0,4.523,3.669,8.192,8.192,8.192h68.267 c4.523,0,8.192-3.669,8.192-8.192v-281.6C247.127,157.614,243.458,153.945,238.935,153.945z M230.743,435.545h-51.883V170.329 h51.883V435.545z"></path>
                                            <path d="M341.335,196.27h-68.267c-4.693,0-8.533,3.84-8.533,8.533v238.933c0,4.693,3.84,8.533,8.533,8.533h68.267 c4.693,0,8.533-3.84,8.533-8.533V204.803C349.869,200.11,346.029,196.27,341.335,196.27z M332.802,435.203h-51.2V213.337h51.2 V435.203z"></path>
                                            <path d="M443.736,110.937h-68.267c-4.693,0-8.533,3.84-8.533,8.533v324.267c0,4.693,3.84,8.533,8.533,8.533h68.267 c4.693,0,8.533-3.84,8.533-8.533V119.47C452.269,114.777,448.429,110.937,443.736,110.937z M435.202,435.203h-51.2v-307.2h51.2 V435.203z"></path>
                                            <path d="M102.411,162.137c1.775,0,3.575-0.555,5.111-1.707l98.5-73.873l97.894,40.789c2.918,1.229,6.255,0.725,8.687-1.263 l88.465-72.38v23.1c0,4.719,3.823,8.533,8.533,8.533c4.71,0,8.533-3.814,8.533-8.533V34.137c0-4.719-3.823-8.533-8.533-8.533h-51.2 c-4.71,0-8.533,3.814-8.533,8.533s3.823,8.533,8.533,8.533h29.184l-81.826,66.961l-97.673-40.704c-2.79-1.161-5.99-0.759-8.405,1.05 l-102.4,76.8c-3.772,2.825-4.531,8.175-1.707,11.938C97.248,160.959,99.817,162.137,102.411,162.137z"></path>
                                            <path d="M509.502,471.837l-25.6-25.6c-3.337-3.337-8.73-3.337-12.066,0s-3.337,8.73,0,12.066l11.034,11.034H42.669V29.136 L53.702,40.17c1.664,1.664,3.849,2.5,6.033,2.5c2.185,0,4.369-0.836,6.033-2.5c3.337-3.337,3.337-8.73,0-12.066l-25.6-25.6 c-3.337-3.337-8.73-3.337-12.066,0l-25.6,25.6c-3.337,3.337-3.337,8.73,0,12.066s8.73,3.337,12.066,0l11.034-11.034V477.87 c0,4.719,3.823,8.533,8.533,8.533h448.734l-11.034,11.034c-3.337,3.337-3.337,8.73,0,12.066c1.664,1.664,3.849,2.5,6.033,2.5 s4.369-0.836,6.033-2.5l25.6-25.6C512.838,480.566,512.838,475.173,509.502,471.837z"></path>
                                            <polygon style={{ fill: "#ffffff" }} points="110.935,247.47 110.935,418.137 76.802,418.137 76.802,435.203 128.002,435.203 128.002,247.47 "></polygon>
                                            <polygon style={{ fill: "#ffffff" }} points="213.677,170.329 213.677,418.478 178.861,418.478 178.861,435.545 230.743,435.545 230.743,170.329 "></polygon>
                                            <polygon style={{ fill: "#ffffff" }} points="315.735,213.337 315.735,418.137 281.602,418.137 281.602,435.203 332.802,435.203 332.802,213.337 "></polygon>
                                            <polygon style={{ fill: "#ffffff" }} points="418.135,128.003 418.135,418.137 384.002,418.137 384.002,435.203 435.202,435.203 435.202,128.003 "></polygon>
                                        </g>
                                    </svg>
                                </button>
                            </> : <button type="button" disabled={!newQuestion} className="gen-ai-answering-main-action-section-send" onClick={(e) =>
                                this.handleOnClickSend()}>
                                <svg fill="#ffffff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="-11.19 -11.19 52.62 52.62" stroke="#ffffff">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0">
                                        <rect x="-11.19" y="-11.19" width="52.62" height="52.62" rx="26.31" fill={!newQuestion ? "#cecece" : "#2a498a"} strokewidth="0"></rect>
                                    </g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <g>
                                            <path d="M20.194,3.46c-4.613-4.613-12.121-4.613-16.734,0c-4.612,4.614-4.612,12.121,0,16.735 c4.108,4.107,10.506,4.547,15.116,1.34c0.097,0.459,0.319,0.897,0.676,1.254l6.718,6.718c0.979,0.977,2.561,0.977,3.535,0 c0.978-0.978,0.978-2.56,0-3.535l-6.718-6.72c-0.355-0.354-0.794-0.577-1.253-0.674C24.743,13.967,24.303,7.57,20.194,3.46z M18.073,18.074c-3.444,3.444-9.049,3.444-12.492,0c-3.442-3.444-3.442-9.048,0-12.492c3.443-3.443,9.048-3.443,12.492,0 C21.517,9.026,21.517,14.63,18.073,18.074z"></path>
                                        </g>
                                    </g>
                                </svg>
                            </button>}
                        </div>
                    </div>
                </div>
                {this.renderAttachedFile()}
                {
                    (!isActiveGraphSection) && <> {attachedFiles.length === 0 ? <>{!userAccessGroup.includes(LimitedUser) && <GenAIDocumentTypesFilters
                        handleSelectedFilters={this.handleSelectedFilters}
                        handleRenderfilters={this.handleRenderfilters}
                        selectedFilters={selectedFilters}
                        renderfilters={renderfilters}
                        customHeight={this.state.customHeight} customWidth={this.state.customWidth}
                        isOpenInPopup={false} />}</> : <>{this.renderSystemPromptTemplateShortcut()}</>
                    }
                    </>
                }
                {this.state.isProcessing &&
                    <Modal
                        show={this.state.isProcessing}
                        size="lg md sm xs"
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        dialogClassName="savePopUp"
                        className="document-processing-newthread"
                        backdropClassName="document-processing-newthread-backdrop"
                        scrollable={true}>
                        <div style={{ padding: "0 5%" }}>
                            <div style={{ fontSize: "14px", textAlign: "center" }}>
                                <Modal.Body>
                                    <img src={imsLoader} alt="" width={100} height={100} />
                                    <div className="saveModal-heading" style={{ color: "#0074B0" }}>{spinnerMessage ? spinnerMessage : `Please wait while uploading the document.`}</div>
                                </Modal.Body>
                            </div>
                        </div>
                    </Modal>}
                {this.state.isShowFileUploadDisclaimer &&
                    <Modal
                        show={this.state.isShowFileUploadDisclaimer}
                        size="lg md sm xs"
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        dialogClassName="popup-transition"
                        className="z-index-popup-transition"
                        backdropClassName="z-index-popup-transition-backdrop"
                        scrollable={true}>
                        <div style={{ borderLeft: `5px solid #F0AB00` }}>
                            <div style={{ padding: "0 5%" }}>
                                <Modal.Header style={{ justifyContent: "center", borderBottomColor: "#F0AB00", padding: "5px" }}>
                                    <div style={{ textAlign: "center" }}>
                                        <Modal.Title>
                                            <div>
                                                <img src={disclaimerIcn} alt="" className="popup-header-icon-wrapper" />
                                            </div>
                                            <span style={{ color: "#F0AB00" }}>Disclaimer</span>
                                        </Modal.Title>
                                    </div>
                                </Modal.Header>
                                <Modal.Body className="authoring-model-body-disclaimer">
                                    <div className="authoring-model-body-content" dangerouslySetInnerHTML={{ __html: fileUploadDisclaimer }} />
                                </Modal.Body>
                                <div style={{ textAlign: "right", padding: "2% 0" }}>
                                    <Button text="Ok" customColor={"#2eb000"} action={() => this.handleOnClickDisclaimerOk()} />
                                    &nbsp;&nbsp;&nbsp;
                                    <Button text="Cancel" customColor={"#1DA1E5"} action={() => { this.setState({ isShowFileUploadDisclaimer: false }) }} />
                                </div>
                            </div>
                        </div>
                    </Modal>}
                {showPromptTemplateList && <PromptTemplateListPopUp showModal={showPromptTemplateList} handleClose={this.handleClosePromptTemplateList}
                    width={this.props.customWidth} commandText={this.state.newQuestion} handleAddToPromptTemplateText={this.handleAddToPromptTemplateText}
                    isUserPromptTemplate={this.state.isUserPromptTemplate} isSystemPromptTemplate={this.state.isSystemPromptTemplate} isFromGlobalFeaturesMenu={this.state.isFromGlobalFeaturesMenu}
                />}
                {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
            </div>)
        }
    }

}
export default withRouter(GenAIAskQuestion);