/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Popover } from 'react-tiny-popover';
import FilterService from '../../services/FilterService';
import { resetIcon, popCancelBttn, cancelRedIcon, subfilterExpandButtonMobile, imsLoader } from '../../assets';
import './FilterComponent.css';
import RecentlyAddedUpdatedFilterHistogram from './RecentlyAddedUpdatedFilterHistogram';
import axios from "axios";
import { useLocation } from 'react-router-dom';
import { LocationOrigin } from '../../utils/utilityServices';

export default function FilterSelectModal(props) {
  const {
    appliedFilters,
    filterCategory,
    filters,
    handleApply,
    handleCloseFilterSelect,
    handleSelectFilter,
    handleToggleFilter,
    isOpen,
    openFilters,
    selectedFilters,
    handleResetFilters,
    customWidth,
    recentlyUsedFilters,
    fromRecentlyAddedUpdated,
    toRecentlyAddedUpdated,
    gotoPageRecentlyAddedUpdated,
    renderedFiltered
  } = props
  const parsedAppliedFilters = appliedFilters.filter(value => value && value.startsWith(filterCategory))
  const parsedSelectedFilters = selectedFilters.filter(value => value && value.startsWith(filterCategory))
  //console.log(parsedSelectedFilters)
  //console.log(appliedFilters)
  const location = useLocation();
  // const filterService = new FilterService();
  const [checkedState, setCheckedState] = useState([]);
  const [showRecentlyUsed, setShowRecentlyUsed] = useState(true);
  const [isShowRecentlyUsed, setIsShowRecentlyUsed] = useState(false);
  // const [renderedFilters, setRenderedFilters] = useState([])
  // const [selectedFiltered, setselectedFiltered] = useState([])
  // let isShowRecentlyUsed = false
  const [isProcessing, setIsProcessing] = useState(false)
  const [yearSelection, setYearSelection] = useState({ min: fromRecentlyAddedUpdated, max: toRecentlyAddedUpdated });
  const [recentlyAddedUpdatedFilter, setRecentlyAddedUpdatedFilter] = useState([]);
  useEffect(() => {
    let tempRecentlyAddedUpdated = {
      min: fromRecentlyAddedUpdated ? fromRecentlyAddedUpdated : 0,
      max: toRecentlyAddedUpdated ? toRecentlyAddedUpdated : 6
    }
    setYearSelection(tempRecentlyAddedUpdated);
  }, [fromRecentlyAddedUpdated, toRecentlyAddedUpdated])

  useEffect(() => {
    if (filterCategory === 'Recently Added_Updated') {
      runSearch()
    }
  }, [filterCategory])
  /**
   * Handle the click event of the apply-filters button.
   *
   * @param  {Object} event
   */
  const applyFilters = event => {
    event.preventDefault()
    if (filterCategory === 'Recently Added_Updated') {
      gotoPageRecentlyAddedUpdated(yearSelection);
    } else {
      handleApply()
    }
  }
  const resetFilters = event => {
    event.preventDefault()
    if (filterCategory === 'Recently Added_Updated') {
      //handleRecentlyAddedUpdated({min:0,max:6});
      setYearSelection({ min: 0, max: 6 });
      gotoPageRecentlyAddedUpdated({ min: 0, max: 6 });
    } else {
      handleResetFilters()
    }

  }
  /**
   * Determine if the apply-changes button should be disabled.
   *
   * @return {Boolean}
   */
  const isApplyChangesButtonDisabled = () => {
    if (filterCategory === 'Recently Added_Updated') {
      if (yearSelection.min !== fromRecentlyAddedUpdated || yearSelection.max !== toRecentlyAddedUpdated)
        return false;
      else
        return true;
    }
    if (parsedSelectedFilters.length > 0 || parsedAppliedFilters.length > 0 || window.location.pathname !== '/' || window.location.pathname !== '/recommended')
      return false
    else
      return true
  }
  /**
   * Render the filter tree content.
   *
   * @return {Object}
   */
  const renderContent = () => {
    return (
      <>
        {renderFilters(filters[filterCategory].children)}
      </>
    )
  }

  /**
   * Render a single filter.
   *
   * @param  {Object} filter
   * @return {Object}
   */
  const renderFilter = (filter) => {
    const filterService = new FilterService();
    let ischecked = filterService.doesFilterSetHaveAnyChildLeaves(filters, filter.key, selectedFilters);
    return (
      <div key={filter.key} className="filter-popup-item">
        <div className='filter-popup-item-button'>
          <button className={`filter-popup-item-caret clickable ${Object.keys(filter.children).length ? '' : 'hide-caret'}`}
            onClick={e => { handleToggleFilter(filter.key) }}>
            {
              filterService.isFilterInFilterSet(filter.key, openFilters) ?
                <FontAwesomeIcon fixedWidth icon="caret-down" color="#646464" /> :
                <FontAwesomeIcon fixedWidth icon="caret-right" color="#646464" />
            }
          </button>
          <label className='filter-popup-item-label clickable' for={filter.key} htmlFor={filter.key} >
            <input key={filter.key + Math.random()}
              checked={ischecked}
              // checked={filterService.doesFilterSetHaveAnyChildLeaves(filters, filter.key, selectedFilters)}
              onChange={(e) => { handleSelectFilter(filter.key, e.target.checked) }}
              type="checkbox"
              id={filter.key}
            />
            <div style={{ marginTop: "2px" }} className="filter-name-text" for={filter.key} htmlFor={filter.key} onClick={(e) => {
              handleSelectFilter(filter.key, !ischecked)
              // handelRecentlyUsedFilter(filter.key, !ischecked, filter)
            }}>{filter.label} ({filter.count})</div>
          </label>
        </div>
        <div className="filter-popup-subitem">
          {filterService.isFilterInFilterSet(filter.key, openFilters) ? renderFilters(filter.children) : null}
        </div>
      </div>
    )
  }

  const handleRecentlyAddedUpdated = (newYears) => {
    if (newYears.min < newYears.max) {
      setYearSelection(newYears);
    }
  }

  const gotoPage = (pageNumber) => {
    setTimeout(() => {
      gotoPageRecentlyAddedUpdated(pageNumber);
    }, 2000)
  }
  /**
   * Render a single filter.
   *
   * @param  {Object} filter
   * @return {Object}
   */

  const generateArrayString = (array) => {
    let arrStr = ''
    array.map((item, index) => {
      if (index === array.length - 1) {
        arrStr += item
      }
      else {
        arrStr += item + ";"
      }
      return null
    })
    return arrStr
  }
  const runSearch = () => {
    setIsProcessing(true);
    var url = LocationOrigin + process.env.REACT_APP_IMS_API_PREFIX + 'qp/recentlyAddedUpdated'
    var config;
    let isBoolean = false
    const queryParams = new URLSearchParams(location.search)
    let query = queryParams.get('query')
    const fcount = queryParams.get('fcount')
    let _appliedFilters = queryParams.get('filters');
    let fieldedSearch = queryParams.get("FS");
    const andFields = queryParams.get("andFields");
    const orFields = queryParams.get("orFields");
    const docTitle = queryParams.get("docTitle");
    const authors = queryParams.get("authors");
    let queryType = queryParams.get("queryType");
    const majorTopicsSynonyms = queryParams.get("majorTopicsSynonyms");
    let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'));
    let filterStr = "";
    if (_appliedFilters) {
      let filterSelected = _appliedFilters.split(';')
      filterSelected = filterSelected.map(z => z.trim()).filter(x => !x.startsWith('Recently Added_Updated'))
      filterStr = generateArrayString(filterSelected);
    }
    if (!query) {
      query = "";
    }
    const sbQuery = queryParams.get('sbQuery');

    /*Check for sbQuery*/
    if (sbQuery !== null) {
      query = sbQuery
    }
    /*Check for boolean operator in Query String Start*/
    isBoolean = queryParams.get('isBoolean');
    let booleanCounter = (query.match(/ OR | AND | NOT | NEAR /g) || []).length;
    if (isBoolean !== null || booleanCounter >= 4) {
      isBoolean = true
    }
    else if (booleanCounter >= 1 && query.split(' ').length <= 10) {
      isBoolean = true
    }
    else {
      isBoolean = false
    }
    if (fieldedSearch) {

      /*Check for boolean operator in Query String End*/
      let val,
        fromRange,
        toRange,
        //rangeField,
        checkFieldVal = false;
      if (fcount > 0) {
        let arr = {
          query: query,
          function: localStorage.getItem("function").replace(/"/g, ""),
          doctypepref: localStorage.getItem("doctypepref"),
          userproducts: localStorage.getItem("userproducts"),
          region: localStorage.getItem("region"),
          username: localStorage
            .getItem("username")
            .toLowerCase()
            .replace(/"/g, ""),
          contentpreference: localStorage
            .getItem("contentpreference")
            .replace(/"/g, ""),
          fcount: parseInt(fcount),
          boolean: isBoolean,
          filters: filterStr,
          fieldedSearch: true,
          accessGroup: (userAccessGroup.includes("MISEHCP")) ? "MISE" : "",
        };
        let isAnyAndCOndition = false;
        for (let i = 1; i <= fcount; i++) {
          let key = queryParams.get(`f${i}`);
          let value = queryParams.get(`fval${i}`);
          value = value.replace(/;/g, " OR ");
          if (value.includes(" AND ") || value.includes(" NOT ")) {
            isAnyAndCOndition = true;
          }
          if (
            value.includes(" AND ") ||
            value.includes(" NOT ") ||
            value.includes(" OR ")
          ) {
            value = value.replace(/ AND /g, ") AND (");
            value = value.replace(/ OR /g, ") OR (");
            value = value.replace(/ NOT /g, ") NOT (");
            value = `(${value})`;
          }
          arr["f" + i] = key;
          arr["fval" + i] = value;

          if (
            key === "congressName" ||
            key === "protocolIDs" ||
            key === "harmonizedProtocolIDs" ||
            key === "docTypeSynonyms" ||
            key === "fullCnSf" ||
            key === "docType" ||
            key === "journalName" ||
            key === "genericName"
          ) {
            checkFieldVal = true;
          }
        }
        if (checkFieldVal && !isAnyAndCOndition) {
          arr["matchPhrase"] = true;
        }
        if (queryType) {
          arr["queryType"] = "alerts";
        }
        val = arr;
        config = {
          method: "POST",
          url: url,
          headers: {
            "Content-Type": "application/json",
          },
          data: val,
        };
      } else {
        config = {
          method: "POST",
          url: url,
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            query: query,
            function: localStorage.getItem("function").replace(/"/g, ""),
            doctypepref: localStorage.getItem("doctypepref"),
            userproducts: localStorage.getItem("userproducts"),
            region: localStorage.getItem("region"),
            username: localStorage
              .getItem("username")
              .toLowerCase()
              .replace(/"/g, ""),
            contentpreference: localStorage
              .getItem("contentpreference")
              .replace(/"/g, ""),
            boolean: isBoolean,
            filters: filterStr,
            fieldedSearch: true,
            accessGroup: (userAccessGroup.includes("MISEHCP")) ? "MISE" : "",
          },
        };
      }
      /* if (isDateRange !== null) {
                     let rangeField = queryParams.get('rangeField'); */
      toRange = queryParams.get("toYear");
      fromRange = queryParams.get("fromYear");
      //config.data["rangeField"] = rangeField;
      //config.data["isDateRange"] = true
      if (toRange !== null) {
        config.data["toYear"] = toRange;
      }
      if (fromRange !== null) {
        config.data["fromYear"] = fromRange;
      }
      if (andFields !== null) {
        config.data["andFields"] = andFields;
      }
      if (orFields !== null) {
        config.data["orFields"] = orFields;
      }
      if (docTitle !== null) {
        config.data["docTitle"] = docTitle;
      }
      if (authors !== null) {
        config.data["authors"] = authors;
      }
      if (majorTopicsSynonyms !== null) {
        config.data["majorTopicsSynonyms"] = majorTopicsSynonyms;
      }
      if (queryType) {
        config.data["queryType"] = "alerts";
      }

    }
    else {
      let val, checkFieldVal = false
      if (fcount > 0) {
        let arr = {
          "query": query,
          "filters": filterStr,
          "function": localStorage.getItem('function').replace(/"/g, ''),
          "doctypepref": localStorage.getItem('doctypepref'),
          "userproducts": localStorage.getItem('userproducts'),
          "region": localStorage.getItem('region'),
          "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
          "contentpreference": localStorage.getItem('contentpreference').replace(/"/g, ''),
          "fcount": parseInt(fcount),
          "boolean": isBoolean,
          fieldedSearch: false,
          accessGroup: (userAccessGroup.includes("MISEHCP")) ? "MISE" : "",
        }
        for (let i = 1; i <= fcount; i++) {
          let key = queryParams.get(`f${i}`)
          let value = queryParams.get(`fval${i}`)
          arr["f" + i] = key
          arr["fval" + i] = value
          // if (key === 'congressName' || key === 'protocolIDs' || key === 'docTypeSynonyms') {
          checkFieldVal = true
          // }
        }
        if (checkFieldVal) {
          arr["matchPhrase"] = true
        }
        if (queryType) {
          arr["queryType"] = "alerts";
        }
        val = arr
        config = {
          method: 'POST',
          url: url,
          headers: {
            'Content-Type': 'application/json'
          },
          data: val
        }
      }
      else {
        config = {
          method: 'POST',
          url: url,
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            "query": query,
            "filters": filterStr,
            "function": localStorage.getItem('function').replace(/"/g, ''),
            "doctypepref": localStorage.getItem('doctypepref'),
            "userproducts": localStorage.getItem('userproducts'),
            "region": localStorage.getItem('region'),
            "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
            "contentpreference": localStorage.getItem('contentpreference').replace(/"/g, ''),
            "boolean": isBoolean,
            fieldedSearch: false,
            accessGroup: (userAccessGroup.includes("MISEHCP")) ? "MISE" : "",
          }
        }
        if (queryType) {
          config.data["queryType"] = "alerts";
        }
      }


    }

    axios(config)
      .then((response) => {
        var processed = ProcessFilter(response.data.body)
        setRecentlyAddedUpdatedFilter(processed);
      })
      .catch(function (error) {
        //console.log(error);
      })
      .finally(async () => {
        setIsProcessing(false);
      })
  }

  const ProcessFilter = (filters) => {
    if (filters && filters.aggregations
      && filters.aggregations.categories
      && filters.aggregations.categories.buckets
      && filters.aggregations.categories.buckets.length > 0) {
      return filters.aggregations.categories.buckets.filter(c => c.key !== 'Recently Added_Updated').map(x => { return { "key": x.key, "doc_count": x.doc_count } });
    } else {
      return [];
    }
  }


  const renderFilterRecentlyAddedUpdated = (filter) => {
    if (isProcessing) {
      return (<div style={{ display: "flex" }}><span className="wnfy-content-loader">
        <img src={imsLoader} width="50" height="50" alt="" />
      </span></div>)
    } else if (recentlyAddedUpdatedFilter) {
      if (recentlyAddedUpdatedFilter && recentlyAddedUpdatedFilter.length > 0) {
        let maxYear = recentlyAddedUpdatedFilter[recentlyAddedUpdatedFilter.length - 1].key;
        let minYear = recentlyAddedUpdatedFilter[0].key;
        let fullDocRange = { maxYear: maxYear, minYear: minYear };
        return (
          <div key={filter.key} className="filter-popup-item">
            <RecentlyAddedUpdatedFilterHistogram customWidth={customWidth} fullDocRange={fullDocRange} years={recentlyAddedUpdatedFilter} yearSelection={yearSelection} handleRecentlyAddedUpdated={handleRecentlyAddedUpdated} gotoPage={gotoPage} />
          </div>
        )
      } else {
        return (<></>);
      }
    } else {
      return (<></>);
    }


  }
  /**
   * Render a collection of filters.
   *
   * @param  {Array}  filters
   * @return {Object}
   */
  const renderFilters = (filters) => {
    if (filterCategory === 'Publication Year') {
      return Object.entries(filters).reverse().map(([path, filter]) => {
        return renderFilter(filter)
      })
    }
    else if (filterCategory === 'Recently Added_Updated') {
      //runSearch();
      return renderFilterRecentlyAddedUpdated(filters);
    }
    else {
      return Object.entries(filters).map(([path, filter]) => {
        return renderFilter(filter)
      })
    }
  }

  /**
   * Render a message when no filters are available to show.
   *
   * @return {Object}
   */
  const renderNoContent = () => {
    return (
      <div>
        No filters available.
      </div>
    )
  }
  const renderHeaderContent = () => {
    return (
      <div className='filter-category'>
        {filterCategory === 'Recently Added_Updated' ? <>{'Recently Added/Updated'}</> :
          filterCategory === 'Document Subtypes' ?
            <div className="filter-category-text">{filterCategory} (Submission-Related Documents Only)</div> :
            <div className="filter-category-text">{filterCategory} </div>}
        <span style={{ display: "flex" }}>
          {customWidth < 800 ?
            <div className="reset-select-filter-image-wrapper" title="Reset Selections">
              <img src={resetIcon} alt="" width="12" height="12" onClick={(e) => {
                checkedState.map((data, i) => {
                  if (data) {
                    checkedState[i] = false
                  }
                })
                setCheckedState(checkedState);
                resetFilters(e)
              }} />
            </div>
            :
            <button className="reset-select-filter-wrapper" title="Reset Selections" onClick={(e) => {
              checkedState.map((data, i) => {
                if (data) {
                  checkedState[i] = false
                }
              })
              setCheckedState(checkedState);
              resetFilters(e)
            }
            }>
              Reset Selections <img src={resetIcon} alt="" style={{ paddingBottom: "3%" }} />
            </button>
          }
          {/* <button className="reset-select-filter-wrapper" title="Reset Selections" onClick={resetFilters}>
        Reset Selections <img src={resetIcon} alt="" style={{paddingBottom:"3%"}}/>
        </button> */}
          <div className='filter-times'>
            {customWidth < 800 ?
              <div onClick={handleCloseFilterSelect} style={{ padding: "0 5px", cursor: "pointer" }}>
                <img src={customWidth <= 600 ? subfilterExpandButtonMobile : cancelRedIcon} alt="" width="22" height="22" />
              </div> :
              <button onClick={handleCloseFilterSelect}>
                <img src={popCancelBttn} width="22" height="22" alt="" />
              </button>
            }
            {/* <button onClick={handleCloseFilterSelect}>
            <img src={popCancelBttn} width="22" height="22" alt=""/>
          </button> */}
          </div>
        </span>
      </div>
    )
  }
  useEffect(() => {
    setIsShowRecentlyUsed(false)
    if (window.location.pathname !== '/rawvalues' && window.location.pathname !== '/PubMedSearch' && window.location.pathname !== '/pubMedResults' && recentlyUsedFilters !== null) {
      recentlyUsedFilters.map((filter, index) => {
        if (filter[filterCategory] !== undefined) {
          let arry = filter[filterCategory].split('/');
          let lastElement = arry[arry.length - 1];
          if (keyExists(filters[filterCategory].children, lastElement)) {
            // isShowRecentlyUsed = true;
            setIsShowRecentlyUsed(true)
          }
        }
      })
    }
  })
  useEffect(() => {
    if (window.location.pathname !== '/rawvalues' && window.location.pathname !== '/PubMedSearch' && window.location.pathname !== '/pubMedResults' && recentlyUsedFilters !== null) {
      [...recentlyUsedFilters].reverse().map((filter, index) => {
        if (filter[filterCategory] !== undefined) {
          let arry = filter[filterCategory].split('/');
          let lastElement = arry[arry.length - 1];
          if (keyExists(filters[filterCategory].children, lastElement)) {
            if (appliedFilters.length > 0) {
              checkedState[index] = appliedFilters.includes(filter[filterCategory]) ? true : false;
              setCheckedState(checkedState)
            }
          }
        }
      })
    }
  }, [])
  useEffect(() => {
    // setRenderedFilters(renderedFiltered)
    //setselectedFiltered(selectedFilters)
    if (window.location.pathname !== '/rawvalues' && window.location.pathname !== '/PubMedSearch' && window.location.pathname !== '/pubMedResults' && recentlyUsedFilters !== null) {
      [...recentlyUsedFilters].reverse().map((filter, index) => {
        if (filter[filterCategory] !== undefined) {
          // let arry = filter[filterCategory].split('/');
          // let lastElement = arry[arry.length - 1];
          // let parentId;
          // parentId = filterService.findLevelOfSelectedFilter(filter[filterCategory])
          // let id = [...recentlyUsedFilters].reverse().findIndex(x => x[filterCategory] === filter[filterCategory]);
          if (renderedFiltered.includes(filter[filterCategory]) || selectedFilters.includes(filter[filterCategory])) {
            checkedState[index] = true;
          }
          else {
            checkedState[index] = false;
          }
          setCheckedState(checkedState)
        }
      })
    }
  }, [renderedFiltered, selectedFilters])
  const keyExists = (obj, key) => {
    if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
      return false;
    }
    else if (obj.hasOwnProperty(key)) {
      return true;
    }
    else if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        const result = keyExists(obj[i], key);
        if (result) {
          return result;
        }
      }
    }
    else {
      for (const k in obj) {
        const result = keyExists(obj[k], key);
        if (result) {
          return result;
        }
      }
    }

    return false;
  };
  // const handelRecentlyUsedFilter = (key, ischeckednot, fir) => {
  //   if (window.location.pathname !== '/rawvalues' && window.location.pathname !== '/pubMedResults' && recentlyUsedFilters !== null) {
  //     [...recentlyUsedFilters].reverse().map((filter, index) => {
  //       if (filter[filterCategory] !== undefined) {
  //         let arry = filter[filterCategory].split('/');
  //         let lastElement = arry[arry.length - 1];
  //         let parentId;
  //         parentId = filterService.findLevelOfSelectedFilter(key)
  //         let id = [...recentlyUsedFilters].reverse().findIndex(x => x[filterCategory] === key);
  //         if (filter[filterCategory] === key) {
  //           checkedState[index] = parentId <= 2 ? !checkedState[index] : ischeckednot //!checkedState[index]
  //           if (parentId <= 2) {
  //             checkedState[id] = ischeckednot ? true : checkedState[id];
  //           }
  //           setCheckedState(checkedState)
  //         }

  //         let xx = filterService.isFilterInFilterSet(key, openFilters)
  //         // console.log(checkedState[id - 1], checkedState[id])
  //         if (keyExists(fir.children, lastElement) && ischeckednot === false && xx) {
  //           checkedState[index] = (parentId <= 2 && checkedState[id] === false || (checkedState[id - 1] === true && checkedState[id] === true)) ? false : (checkedState[index] || checkedState[id - 1] === true && checkedState[id] === false) ? true : null;
  //           setCheckedState(checkedState)
  //         }
  //       }
  //     })
  //   }
  // }
  // function hasDuplicates(array) {
  //   var valuesSoFar = Object.create(null);
  //   for (var i = 0; i < array.length; ++i) {
  //     var value = array[i];
  //     if (value in valuesSoFar) {
  //       return true;
  //     }
  //     valuesSoFar[value] = true;
  //   }
  //   return false;
  // }
  // function removeDuplicates(arr) {
  //   return arr.filter((item,
  //     index) => arr.indexOf(item) === index);
  // }
  if (!isOpen) {
    // Popover has issues hiding itself
    // https://github.com/alexkatz/react-tiny-popover/issues/69
    return ""
  }
  if (customWidth < 1000) {
    return (
      <div className="filter-modal-wrapper-tablet" style={{ border: customWidth < 600 && "none" }}>
        {renderHeaderContent()}
        <div className='filter-modal-body'>
          <button
            className='apply-filter'
            disabled={isApplyChangesButtonDisabled()}
            onClick={applyFilters}
            title="Apply Changes">
            Apply Changes
          </button>
          {
            isShowRecentlyUsed && (window.location.pathname !== '/rawvalues' && window.location.pathname !== '/PubMedSearch' && window.location.pathname !== '/pubMedResults' && recentlyUsedFilters !== null) &&
            <div className='filter-modal-body-inner-wrapper' style={{ minHeight: "auto", maxHeight: "initial" }}>
              <div className={filterCategory === 'Publication Year' ? 'filter-sub-category-wrapper filter-sub-category-wrapper-publication-year' : 'filter-sub-category-wrapper'}>
                <hr style={{ margin: 0 }} />
                <div className='recentluUsed-container' style={{ display: !showRecentlyUsed && "flex" }}>
                  <button className={"filter-popup-item-caret clickable"}
                    onClick={() => setShowRecentlyUsed(!showRecentlyUsed)}>
                    {
                      showRecentlyUsed ?
                        <FontAwesomeIcon fixedWidth icon="caret-down" color="#646464" />
                        :
                        <FontAwesomeIcon fixedWidth icon="caret-right" color="#646464" />
                    }
                  </button>
                  <span>Recently used (Applicable to current search)</span>
                </div>
                {
                  [...recentlyUsedFilters].reverse().map((filter, index) => {
                    if (filter[filterCategory] !== undefined && showRecentlyUsed) {
                      let arry = filter[filterCategory].split('/');
                      let lastElement = arry[arry.length - 1];
                      //let selectedFiltersLastElement = appliedFilters.length > 0 ? appliedFilters.includes(filter[filterCategory]) : null /* (filter[filterCategory] === selectedFilters[selectedFilters.length - 1]) */;
                      if (filter[filterCategory].split('/')[0] === filterCategory && keyExists(filters[filterCategory].children, lastElement)) {
                        let checkStatus = checkedState[index]
                        return (
                          <div key={filter[filterCategory] + Math.random()} className="filter-popup-item">
                            <div className='filter-popup-item-button'>
                              <label key={filter[filterCategory] + Math.random() + checkStatus} for={filter[filterCategory]} htmlFor={filter[filterCategory]} className='filter-popup-item-label clickable' onClick={(e) => {
                                // checkedState[index] = !checkedState[index]
                                checkedState[index] = !checkStatus
                                setCheckedState(checkedState)
                                handleSelectFilter(filter[filterCategory], checkedState[index])
                              }}>
                                <input key={filter[filterCategory] + Math.random() + checkStatus}
                                  defaultChecked={checkStatus}
                                  // checked={checkedState[index] /* || selectedFiltersLastElement */}
                                  // onChange={(e) => {
                                  //   handleSelectFilter(filter[filterCategory], e.target.checked)
                                  //   checkedState[index] = !checkedState[index]
                                  //   setCheckedState(checkedState)
                                  // }}
                                  type="checkbox"
                                  id={filter[filterCategory]}
                                />
                                <div style={{ marginTop: "2px" }} className="filter-name-text" /* for={filter[filterCategory]} htmlFor={filter[filterCategory]} onClick={(e) => {
                                  handleSelectFilter(filter[filterCategory], checkStatus)
                                  checkedState[index] = !checkedState[index]
                                  setCheckedState(checkedState)
                                }} */>{lastElement}</div>
                              </label>
                            </div>
                          </div>
                        )
                      }
                    }
                  })
                }
                <hr style={{ marginBottom: 0 }} />
              </div>
            </div>
          }
          <div className='filter-modal-body-inner-wrapper'>
            <div className={filterCategory === 'Publication Year' ? 'filter-sub-category-wrapper filter-sub-category-wrapper-publication-year' : 'filter-sub-category-wrapper'}>
              {filterCategory && filters[filterCategory] !== undefined ? renderContent() : renderNoContent()}
            </div>
          </div>
        </div>
      </div>
    )
  }
  else {
    return (
      <div className='filterSubPopUp'>
        <Popover
          isOpen={true}
          // isOpen={isOpen}
          positions={['right', 'top']} // preferred position
          align={'center'}
          reposition={false}
          containerStyle={{ top: "20%", verticalAlign: "middle", zIndex: "2" }}
          content={(positions, align) => (
            <div className={filterCategory === 'Publication Year' ? 'filter-modal-wrapper filter-modal-wrapper-publication-filter' : 'filter-modal-wrapper'} style={{ marginTop: customWidth <= 1220 && "100px", width: filterCategory === 'Recently Added_Updated' && "400px" }}>
              {renderHeaderContent()}
              <div className='filter-modal-body'>
                <button
                  className='apply-filter'
                  disabled={isApplyChangesButtonDisabled()}
                  onClick={applyFilters}
                  title="Apply Changes">
                  Apply Changes
                </button>
                {
                  isShowRecentlyUsed && (window.location.pathname !== '/rawvalues'&& window.location.pathname !== '/PubMedSearch' && window.location.pathname !== '/pubMedResults' && recentlyUsedFilters !== null) &&
                  <div className='filter-modal-body-inner-wrapper' style={{ minHeight: "auto", maxHeight: "initial" }}>
                    <div className={filterCategory === 'Publication Year' ? 'filter-sub-category-wrapper filter-sub-category-wrapper-publication-year' : 'filter-sub-category-wrapper'}>
                      <hr style={{ margin: 0 }} />
                      <div className='recentluUsed-container' style={{ display: !showRecentlyUsed && "flex" }}>
                        <button className={"filter-popup-item-caret clickable"}
                          onClick={() => setShowRecentlyUsed(!showRecentlyUsed)}>
                          {
                            showRecentlyUsed ?
                              <FontAwesomeIcon fixedWidth icon="caret-down" color="#646464" />
                              :
                              <FontAwesomeIcon fixedWidth icon="caret-right" color="#646464" />
                          }
                        </button>
                        <span>Recently used (Applicable to current search)</span>
                      </div>
                      {
                        [...recentlyUsedFilters].reverse().map((filter, index) => {
                          if (filter[filterCategory] !== undefined && showRecentlyUsed) {
                            let arry = filter[filterCategory].split('/');
                            let lastElement = arry[arry.length - 1];
                            // let selectedFiltersLastElement = selectedFilters[selectedFilters.length - 1];
                            // let selectedFiltersLastElement = appliedFilters[appliedFilters.length - 1];
                            // console.log(appliedFilters, realFilter, realFilter.length, hasDuplicates(realFilter))
                            //let selectedFiltersLastElement = appliedFilters.length > 0 ? appliedFilters.includes(filter[filterCategory]) : null /* ||(filterService.doesFilterSetHaveAnyChildLeaves(filters, filter[filterCategory], selectedFilters) ? (filter[filterCategory] === selectedFilters[selectedFilters.length - 1] || filter[filterCategory] === selectedFilters[0]) : null) */;

                            // let newArr;// = removeDuplicates(realFilter)
                            // // if (hasDuplicates(realFilter)) { newArr = removeDuplicates(realFilter) }
                            // // else { newArr = realFilter }

                            // if (filterService.doesFilterSetHaveAnyChildLeaves(filters, filter[filterCategory], selectedFilters)) {
                            //   newArr = removeDuplicates(realFilter)//realFilter
                            // }
                            // else {
                            //   newArr = [] //removeDuplicates(realFilter)
                            // }
                            // console.log(newArr, newArr.includes(filter[filterCategory]))
                            // let selectedFiltersLastElement = appliedFilters.length > 0 ? appliedFilters.includes(filter[filterCategory]) : (newArr.length > 0 /* && !hasDuplicates(realFilter) */) ? newArr.includes(filter[filterCategory]) : null;
                            // console.log(selectedFiltersLastElement)
                            if (filter[filterCategory].split('/')[0] === filterCategory && keyExists(filters[filterCategory].children, lastElement)) {
                              let checkStatus = checkedState[index];
                              // console.log(checkStatus, "checkStatus")
                              return (
                                <div key={filter[filterCategory] + Math.random()} className="filter-popup-item">
                                  <div className='filter-popup-item-button'>
                                    <label key={filter[filterCategory] + Math.random() + checkStatus} for={filter[filterCategory]} htmlFor={filter[filterCategory]} className='filter-popup-item-label clickable' onClick={(e) => {
                                      // checkedState[index] = !checkedState[index]
                                      // setCheckedState(checkedState)
                                      checkedState[index] = !checkStatus
                                      setCheckedState(checkedState)
                                      handleSelectFilter(filter[filterCategory], checkedState[index])
                                    }}>
                                      <input key={filter[filterCategory] + Math.random() + checkStatus}
                                        defaultChecked={checkStatus}
                                        // checked={checkedState[index] /* || selectedFiltersLastElement */}
                                        // onChange={(e) => {
                                        //   handleSelectFilter(filter[filterCategory], e.target.checked)
                                        //   checkedState[index] = !checkedState[index]
                                        //   setCheckedState(checkedState)
                                        // }}
                                        type="checkbox"
                                        id={filter[filterCategory]}
                                      />
                                      <div style={{ marginTop: "2px" }} className="filter-name-text" /* for={filter[filterCategory]} htmlFor={filter[filterCategory]} onClick={(e) => {
                                        handleSelectFilter(filter[filterCategory], checkStatus)
                                        checkedState[index] = !checkedState[index]
                                        setCheckedState(checkedState)
                                      }} */>{lastElement}</div>
                                    </label>
                                  </div>
                                </div>
                              )
                            }
                          }
                        })
                      }
                      <hr style={{ marginBottom: 0 }} />
                    </div>
                  </div>
                }

                <div className='filter-modal-body-inner-wrapper'>
                  <div className={filterCategory === 'Publication Year' ? 'filter-sub-category-wrapper filter-sub-category-wrapper-publication-year' : 'filter-sub-category-wrapper'}>
                    {filterCategory && filters[filterCategory] !== undefined ? renderContent() : renderNoContent()}
                  </div>
                </div>
              </div>
            </div>
          )}>
          <div />
        </Popover>
      </div>



    )
  }
}

FilterSelectModal.propTypes = {
  appliedFilters: PropTypes.arrayOf(
    PropTypes.string
  ).isRequired,
  filterCategory: PropTypes.string,
  filters: PropTypes.object.isRequired,
  handleApply: PropTypes.func.isRequired,
  handleCloseFilterSelect: PropTypes.func.isRequired,
  handleSelectFilter: PropTypes.func.isRequired,
  handleToggleFilter: PropTypes.func.isRequired,
  handleResetFilters: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  openFilters: PropTypes.arrayOf(
    PropTypes.string
  ).isRequired,
  selectedFilters: PropTypes.arrayOf(
    PropTypes.string
  ).isRequired
}
